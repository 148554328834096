<template>
   <table :id="props.text" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
      <thead>
         <tr class="text-center" style="border:1px solid #c5c6cc !important; font-weight:600;">
            <th style="width: 5% !important;">No</th>
            <th style="width: 20% !important;">Status Akreditasi</th>
            <th style="width: 20% !important;">Nomor</th>
            <th>Lembaga</th>
            <th class="px-1" style="width:10% !important;">Status</th>
            <template v-if="props.prodiID !== undefined && !props.disabled">
               <th class="px-1" style="width:10% !important;">Aksi</th>
            </template>
         </tr>
      </thead>
      <tbody style="border:1px solid #c5c6cc !important;">
         <template v-if="props[props.text].length > 0">
            <tr v-for="(row, rowIndex) in props[props.text]" :key="rowIndex">
               <td class="text-center">{{ rowIndex + 1 }}</td>
               <td>{{ row.newLevelName }}</td>
               <td>{{ row.newCode }}</td>
               <td>{{ row.newInstitutionName }}</td>
               <td class="text-center">
                  <span :class="`badge badge-${ row.newStatus == '1' ? 'primary' : 'danger' }`">{{ row.newStatusName }}</span>
               </td>
               <template v-if="props.prodiID !== undefined && !props.disabled">
                  <td class="text-center">
                     <span :data-id="row.id" class="fa fa-edit" style="cursor:pointer; font-size:15px; color:#7239ea;" @click="editAccreditation"></span>
                     &nbsp;
                     <span :data-id="row.id" class="fa fa-trash" style="cursor:pointer; font-size:15px; color:#f1416c;" @click="removeAccreditation"></span>
                  </td>
               </template>
            </tr>
         </template>
         <template v-else>
            <tr><td class="text-center fw-bold" :colspan="((props.prodiID !== undefined) ? (6) : (5))">Belum ada data</td></tr>
         </template>
      </tbody>
   </table>
</template>

<script setup>
   import { defineProps, defineEmits } from 'vue';
   import request from '@/utils/request';
   import swal from 'sweetalert2';

   const props = defineProps({ nationalAccreditationData: Array, internationalAccreditationData: Array, text: String, prodiID: Number, disabled: Boolean });
   const emits = defineEmits(['editAccreditation', 'removeAccreditation']);
   const removeAccreditation = ((event) => {
      const id = event.target.dataset.id;
      swal.fire({
			title: `Apakah Anda yakin untuk menghapus akreditasi ini?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Hapus',
			cancelButtonText: 'Batal'
		}).then(async (result) => {
         if (result.isConfirmed) {
            const res = await request({ url: `/akreditasi/${id}`, method: 'DELETE' });

            if (res.status == 'SUCCESS') {
               swal.fire({
                  title: 'Dihapus!',
                  text: 'Data akreditasi telah berhasil dihapus',
                  icon: 'success'
               }).then(() => {
                  emits('removeAccreditation', { id, type: ((props.text == 'nationalAccreditationData') ? ('nationalAccreditation') : ('internationalAccreditation')) });
               });
            }
         }
      });
   });
   const editAccreditation = (async (event) => {
      const id = event.target.dataset.id;
      emits('editAccreditation', { data: await request(`/akreditasi/${id}`), type: ((props.text == 'nationalAccreditationData') ? ('National') : ('International')), dynamic: ((props.text == 'nationalAccreditationData') ? ('dynamicComponentNational') : ('dynamicComponentInternational')) });
   });
</script>