<template>
	<LoadingOverlay :loading="loading" />
   <breadcrumb-content menu="Pembuatan BRP" subMenu="Informasi Umum" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="padding-bottom:11px; border-bottom:3px solid #a1a5c3;">
               <li v-for="(row, index) in NavPills" :key="index" class="nav-item" role="presentation">
                  <button :class="`nav-link ${ row.class } ${ ((urlParams.target == row.class) ? ('active') : ('')) }`" type="button" role="tab" :aria-selected="urlParams.target == row.class">{{ row.name }}</button>
               </li>
            </ul>
				<div class="p-5">
               <div v-if="GeneralInfo">
						<component :is="GeneralInfo" form="index" next="CPL-program-study" :CourseInCurriculum="CourseInCurriculum" @form="recieveForm" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import request from '@/utils/request';
	import LoadingOverlay from '@/components/LoadingOverlay';

   const loading = ref(true);
   const router = useRouter();
   const urlParams = router.currentRoute.value.params;
   const NavPills = ref([{ class: 'index', name: 'Informasi Umum' }, { class: 'CPL-program-study', name: 'CPL Prodi' }, { class: 'CPMK', name: 'CPMK' }, { class: 'sub-CPMK', name: 'Sub CPMK' }, { class: 'correlation', name: 'Korelasi' }]);
	const CourseInCurriculum = ref({});
   let GeneralInfo = shallowRef(null);
   const urlPage = ((target) => ((target == 'index') ? ('GeneralInfo') : ((target.split('-')).map((word) => word.charAt(0).toUpperCase() + word.slice(1) ).join(''))) );
   const recieveForm = (({ pathTo }) => router.push(pathTo) );

	onMounted(async () => {
		try {
			CourseInCurriculum.value = (await request({ url: `/mk-kurikulum/${ urlParams.id }?loads=mk_cpl,mk_prasyarat,mediatek_mk,dosen_pengampu,bentuk_evaluasi_mkk,indikator_kurikulum` })).data;

			if (urlParams.target != 'index') router.push({ name: 'CreateViewInformasiUmum', params: { id: urlParams.id, target: 'index' } });
			urlParams.target = 'index';
			import(`./${ urlPage(urlParams.target) }.vue`).then(module => {
				eval(urlPage(urlParams.target)).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#${ urlParams.target }`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>