<template>
    <breadcrumb-content menu="Laporan BRP" subMenu="Kriteria Penilaian"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />
            <button type="button" class="btn btn-light-success btn-sm border border-success border-1 mb-5" @click="ExportExcel">
                <span class="fas fa-file-export"></span>
                Export ke Excel
            </button>
            
            <div class="table-responsive">
                <DataTable ref="tableRef" id="table" class="table table-row-bordered gy-5" :options="options">
                    <thead class="text-center fw-bold text-muted px-3" style="vertical-align: middle;">
                            <tr>
                                <th class="text-center px-3" rowspan="2">No</th>
                                <th class="text-center px-3" rowspan="2">Fakultas</th>
                                <th class="text-center px-3" rowspan="2">Program Studi</th>
                                <th class="text-center px-3" rowspan="2">Matakuliah</th>
                                <th class="text-center px-3" rowspan="2">Bentuk Evaluasi</th>
                                <th class="text-center px-3" rowspan="2">Sub-CPMK</th>
                                <th class="text-center px-3" colspan="2">Instrumen Penilaian Frekuensi</th>
                                <th class="text-center px-3" rowspan="2">Tagihan (Bukti)</th>
                                <th class="text-center px-3" rowspan="2">Bobot Penilaian <br /> (%)</th>
                            </tr>
                            <tr>
                                <th class="text-center px-3">Formatif</th>
                                <th class="text-center px-3">Sumatif</th>
                            </tr>
                    </thead>
                    <tbody style="text-align: justify;"></tbody>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, toRaw } from 'vue'
    import LoadingOverlay from '@/components/LoadingOverlay'
    import request from '@/utils/request'
    import DataTable from 'datatables.net-vue3'
    import DataTablesLib from 'datatables.net'
    import axios from "axios";

    const loading = ref(false)

    // === Datatable ===
    DataTable.use(DataTablesLib)
    let dtKriteriaPenilaian = ref([]);
    const fetchAdditionalDataForBRP = async (brp_id) => {
        try { const response = (await request({ url: `/brp/${brp_id}?loads=mk_kurikulum` })).data.mk_kurikulum; return response; } 
        catch (error) { console.error(`Failed to fetch additional data for brp_id: ${brp_id}`, error); return null; }
    };
    const fetchDataKriteriaPenilaianWithPagination = async (params) => {
        if (dtKriteriaPenilaian.value.length === 0) {
            try { 
                dtKriteriaPenilaian.value = (await request({ url:`/kriteria-penilaian-datatable?draw=1&start=0&length=-1&loads=kriteriapenilaian_subcpmk` })).data.data 

                // // Fetch additional data for each brp_id
                // for (let kriteria of dtKriteriaPenilaian.value) {
                //     const additionalData = await fetchAdditionalDataForBRP(kriteria.brp_id_fk);
                //     if (additionalData) {
                //         kriteria.fakultas_nama_ind = additionalData.fakultas_nama_ind;
                //         kriteria.prodi_nama_ind = additionalData.prodi_nama_ind;
                //         kriteria.matakuliah_nama_ind = additionalData.matakuliah_nama_ind;
                //     }
                // }

                // get all brp_id_fk in kriteria penilaian
                const brp_ids = [...new Set(dtKriteriaPenilaian.value.map(item => item.brp_id_fk))];
                
                // call api for get details name of fakultas, prodi, matakuliah by brp id
                const brpDetailsPromises = brp_ids.map(brp_id => fetchAdditionalDataForBRP(brp_id));
                const brpDetails = await Promise.all(brpDetailsPromises);

                // mapping brp_id to detail
                const brpDetailsMap = brp_ids.reduce((acc, brp_id, index) => {
                    acc[brp_id] = brpDetails[index] || {}; // Menyimpan hasil dalam object
                    return acc;
                }, {});
                
                // combine data kriteria penilaian with brp detail
                dtKriteriaPenilaian.value = dtKriteriaPenilaian.value.map(item => {
                    const details = brpDetailsMap[item.brp_id_fk] || {};
                    return {
                        ...item,
                        fakultas: details.fakultas_nama_ind || '-',
                        prodi: details.prodi_nama_ind || '-',
                        matakuliah: details.matakuliah_nama_ind || '-'
                    };
                });
            } 
            catch (error) { return error }
        }

        let resultDatas = dtKriteriaPenilaian.value
        let countFilter = resultDatas.length

        if (params?.search.value) {
            resultDatas = dtKriteriaPenilaian.value.filter(kriteria_penilaian => {
                let str = kriteria_penilaian?.formatif_ind + kriteria_penilaian?.formatif_eng 
                        + kriteria_penilaian?.sumatif_ind + kriteria_penilaian?.sumatif_eng 
                        + kriteria_penilaian?.tagihan_ind + kriteria_penilaian?.tagihan_eng 
                        + kriteria_penilaian?.no_urut 
                        + kriteria_penilaian?.bobot_penilaian
                        + kriteria_penilaian.bentuk_evaluasi_detail.bentuk_evaluasi_nama_ind
                        + kriteria_penilaian.fakultas_nama_ind + kriteria_penilaian.prodi_nama_ind + kriteria_penilaian.matakuliah_nama_ind;

                if (kriteria_penilaian.kriteriapenilaian_subcpmk && Array.isArray(kriteria_penilaian.kriteriapenilaian_subcpmk)) { kriteria_penilaian.kriteriapenilaian_subcpmk.forEach(sub => { str += sub.sub_cpmk.keterangan_ind; }); }
                return str.toLowerCase().includes(params?.search.value.toLowerCase())
            })

            countFilter = resultDatas.length
        }

        if (params?.order.length > 0) {
            resultDatas = resultDatas.sort((a, b) => {
                return params?.order[0].dir === 'asc' ? (a['no_urut'] - b['no_urut']) : (b['no_urut'] - a['no_urut']);
            })
        }
        resultDatas = resultDatas.slice(params?.start, params?.start + params?.length)

        return { draw: params?.draw, recordsTotal: dtKriteriaPenilaian.value.length, recordsFiltered: countFilter, data: resultDatas }
    };
    const fetchDataKriteriaPenilaian = async (data, callback) => {
        try {
            const response = await fetchDataKriteriaPenilaianWithPagination(data)
            const plainResponse = {
                ...toRaw(response),
                data: response.data.map(item => toRaw(item))
            }

            callback(plainResponse)
        } catch (error) {
            console.error('Error fetching data kriteria penilaian:', error)
        } finally {
            loading.value = false
        }
    }
    const columns = ref([
        { data: null, title: 'No', width: '8%', className: 'text-center', searchable: false, orderable: false },
        { data: 'fakultas', title: 'Fakultas', width: '10%' },
        { data: 'prodi', title: 'Program Studi', width: '10%' },
        { data: 'matakuliah', title: 'Matakuliah', width: '10%' },
        { data: 'bentuk_evaluasi_detail', title: 'Bentuk Evaluasi', width: '23%', className: 'px-3',
            render: function (data, type, row) {
                if (type === 'display' && row) {
                    const bentukEvaluasi = data.bentuk_evaluasi_nama_ind +' - '+ data.deskripsi_ind;
                    return `<td> <span>${bentukEvaluasi}</span> </td>`;
                } else {
                    return `<td> <span>-</span> </td>`;
                }
            }
        },
        { data: 'kriteriapenilaian_subcpmk', title: 'Sub-CPMK', width: '12%',
            render: function (data, type, row) {
                if (type === 'display' && row && Array.isArray(data) && data.length > 0) {
                    const subCpmks = [...new Set(data.map(item => item.sub_cpmk.keterangan_ind))].join(', ');
                    return `<td> <span>${subCpmks}</span> </td>`;
                } else {
                    return `<td> <span>-</span> </td>`;
                }
            }
        },
        { data: 'formatif_ind', title: 'Formatif', width: '17%' },
        { data: 'sumatif_ind', title: 'Sumatif', width: '17%' },
        { data: 'tagihan_ind', title: 'Tagihan (Bukti)', width: '17%' },
        { data: 'bobot_penilaian', title: 'Bobot Penilaian (%)', width: '7%', className: 'text-center px-3',
            render: function (data, type, row) {
                if (type === 'display' && row) return row.bobot_penilaian+`%`;
                else return '-';
            }
        },
    ]);
    const options = ref({ 
        serverSide: true, 
        ajax: fetchDataKriteriaPenilaian, 
        columns: columns.value,
        rowCallback: function(row, data, index) {
            const pageInfo = this.api().page.info();
            const rowNum = pageInfo.start + index + 1;

            // eslint-disable-next-line no-undef
            $('td:eq(0)', row).html(rowNum); 
        }
    })
   // === End Datatable ===

    const ExportExcel = async () => {
        loading.value = true

        try {
            const response = await axios({
                url: `${process.env.VUE_APP_BASE_API}/api/v1/laporan-kriteria-penilaian?length=-1`,
                method: 'GET',
                responseType: 'blob'
            });

            if (response) {
                // Buat objek URL dari blob
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);

                // Buat elemen link untuk mendownload file
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'laporan_bentuk_evaluasi_brp.xlsx'); // Nama file yang diinginkan

                // Tambahkan link ke DOM, trigger klik untuk download, dan kemudian hapus link
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Optional: Release URL Object setelah digunakan
                window.URL.revokeObjectURL(url);

                loading.value = false
            }
        } catch (error) {
            console.error('Error downloading Excel file:', error);
            loading.value = false
        }
    };
</script>