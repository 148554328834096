<template>
	<LoadingOverlay :loading="loading" />
	<breadcrumb-content menu="Laporan BRP" subMenu="Monitoring BRP" />
	
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="padding-bottom:11px; border-bottom:3px solid #a1a5c3;">
				<li v-for="(row, index) in NavPills" :key="index" class="nav-item" role="presentation">
					<button :class="`nav-link ${ ((index == 0) ? ('active') : ('')) }`" type="button" role="tab" aria-selected="false" :data-page="row.class" @click="onHandlePage">{{ row.name }}</button>
				</li>
            </ul>
				<div class="p-5">
					<div v-if="GeneralInfo">
						<component :is="GeneralInfo" form="index" :BRP="data.BRP" :CourseInCurriculum="data.CourseInCurriculum" />
					</div>
					<div v-else-if="CPLProgramStudy">
						<component :is="CPLProgramStudy" form="CPL-program-study" :CPL="data.CPL" />
					</div>
					<div v-else-if="CPMK">
						<component :is="CPMK" form="CPMK" :CPMK="data.CPMK" />
					</div>
					<div v-else-if="SubCPMK">
						<component :is="SubCPMK" form="sub-CPMK" :SubCPMK="data.SubCPMK" />
					</div>
					<div v-else-if="Correlation">
						<component :is="Correlation" form="correlation" :CPMK="data.CPMK" :SubCPMK="data.SubCPMK" :Correlation="data.Correlation" />
					</div>
					<div v-else-if="Literature">
						<component :is="Literature" form="literature" :CPL="data.CPL" :Literature="data.Literature" />
					</div>
					<div v-else-if="LessonPlan">
						<component :is="LessonPlan" form="lesson-plan" :CourseInCurriculum="data.CourseInCurriculum" :LessonPlan="data.LessonPlan" />
					</div>
					<div v-else-if="TaskPlan">
						<component :is="TaskPlan" form="task-plan" :CourseInCurriculum="data.CourseInCurriculum" :TaskPlan="data.TaskPlan" />
					</div>
					<div v-else-if="AssesmentCriteria">
						<component :is="AssesmentCriteria" form="assesment-criteria" :CourseInCurriculum="data.CourseInCurriculum" :AssesmentCriteria="data.AssesmentCriteria" />
					</div>
					<div v-else-if="AssesmentRubric">
						<component :is="AssesmentRubric" form="assesment-rubric" :CourseInCurriculum="data.CourseInCurriculum" :AssesmentRubric="data.AssesmentRubric" @openModal="onOpenModal" />
					</div>
					<div v-else-if="Attachment">
						<component :is="Attachment" form="attachment" :CourseInCurriculum="data.CourseInCurriculum" :Attachment="data.Attachment" @openModal="onOpenModal" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="Modal">
			<component :is="Modal" :data="ModalData" @removeModal="onRemoveModal" />
		</div>
		<AssesmentSlider :CourseInCurriculum="data.CourseInCurriculum" :brp="data.BRP.brp_id" @submit="handleSubmit" />
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import request from '@/utils/request';
	import AssesmentSlider from './AssesmentSlider.vue';
	import LoadingOverlay from '@/components/LoadingOverlay';

	const loading = ref(true);
	const router = useRouter();
	const urlParams = router.currentRoute.value.params;
	const NavPills = ref([{ class: 'index', name: 'Informasi Umum' }, { class: 'CPL-program-study', name: 'CPL Prodi' }, { class: 'CPMK', name: 'CPMK' }, { class: 'sub-CPMK', name: 'Sub CPMK' }, { class: 'correlation', name: 'Korelasi' }, { class: 'literature', name: 'Bahan Kajian' }, { class: 'lesson-plan', name: 'Rencana Pembelajaran' }, { class: 'task-plan', name: 'Rancangan Tugas' }, { class: 'assesment-criteria', name: 'Kriteria Penilaian' }, { class: 'assesment-rubric', name: 'Rubrik Penilaian' }, { class: 'attachment', name: 'Lampiran' }]);
	const data = ref({ BRP: {}, CourseInCurriculum: {}, CPL: [], CPMK: [], SubCPMK: [], Correlation: [], Literature: [], LessonPlan: [], TaskPlan: [], AssesmentCriteria: [], AssesmentRubric: [], Attachment: [] });
	const ModalData = ref({});
	let GeneralInfo = shallowRef(null);
	let CPLProgramStudy = shallowRef(null);
	let CPMK = shallowRef(null);
	let SubCPMK = shallowRef(null);
	let Correlation = shallowRef(null);
	let Literature = shallowRef(null);
	let LessonPlan = shallowRef(null);
	let TaskPlan = shallowRef(null);
	let AssesmentCriteria = shallowRef(null);
	let AssesmentRubric = shallowRef(null);
	let Attachment = shallowRef(null);
	let Modal = shallowRef(null);
	const urlPage = ((target) => ((target == 'index') ? ('GeneralInfo') : ((target.split('-')).map((word) => word.charAt(0).toUpperCase() + word.slice(1) ).join(''))) );
	const handleSubmit = (({target}) => {
		document.getElementById('kt_engage_demos_close').click();
		router.push(target);
	});
	const onHandlePage = ((event) => {
		const prevPage = document.querySelector('button.nav-link.active').dataset.page;

		// eslint-disable-next-line no-undef
		$(`#${ prevPage }`).slideUp(1000);
		setTimeout(() => {
			(Array.from(document.querySelectorAll('button.nav-link'))).map((v) => v.classList.remove('active') );
			const el = event.target;
			el.classList.add('active');
			
			eval(urlPage(prevPage)).value = null;
			import(`./${ urlPage(el.dataset.page) }.vue`).then(module => {
				eval(urlPage(el.dataset.page)).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#${ el.dataset.page }`).slideDown(1000);
			});
		}, 1000);
	});
	const onOpenModal = ((data) => {
		Modal.value = null;
		ModalData.value = data;
		import(`./Modal.vue`).then(module => {
			Modal.value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#Modal-${ data.body }`).fadeIn(1000);
		});
	});
	const onRemoveModal = (({ dismiss }) => { eval(dismiss).value = null; });

	onMounted(async () => {
		try {
			data.value.BRP = (await request({ url: `/brp/${ urlParams.brp_id }?loads=mk_kurikulum,status_brp,dosen_pengembang,metode_pembelajaran_brp,tinjauan_pustaka_brp,aktivitas_pembelajaran_brp,rencana_belajar,rancangan_tugas,kriteria_penilaian,korelasi,cpmk,sub_cpmk,rubrik,lampiran` })).data;
			data.value.CourseInCurriculum = data.value.BRP.mk_kurikulum;
			data.value.CPL = data.value.CourseInCurriculum.mk_cpl;
			data.value.CPMK = data.value.BRP.cpmk;
			data.value.SubCPMK = data.value.BRP.sub_cpmk;
			data.value.Correlation = data.value.BRP.korelasi;
			data.value.Literature = data.value.BRP.tinjauan_pustaka_brp;
			data.value.LessonPlan = data.value.BRP.rencana_belajar;
			data.value.TaskPlan = (await request({ url: `/rancangan-tugas-datatable?draw=1&start=0&length=-1&brp_id=${ urlParams.brp_id }&loads=sub_cpmk` })).data.data;
			data.value.AssesmentCriteria = data.value.BRP.kriteria_penilaian;
			data.value.AssesmentRubric = data.value.BRP.rubrik;
			data.value.Attachment = data.value.BRP.lampiran;

			import(`./${ urlPage('index') }.vue`).then(module => {
				eval(urlPage('index')).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#index`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>

<style>
	table#correlation-table thead, table#correlation-table tbody, table#study-material thead, table#study-material tbody { border:1px solid #bcc1c5; }
	table#correlation-table tbody tr:last-child, table#correlation-table tfoot tr:last-child, table#study-material tbody tr:last-child, table#study-material tfoot tr:last-child { border-bottom:1px solid #bcc1c5; }
</style>