<template>
    <breadcrumb-content menu="Pembuatan Kurikulum" subMenu="Matriks CPL dan TPP"/>

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body pt-9 pb-0">
            <LoadingOverlay :loading="loading" />

            <div class="row" style="display:flex; justify-content:center;">
                <div class="col-6">
                    <div class="row mb-5">
                        <label class="form-label" for="ri_id">
                            Rumpun Ilmu
                        </label>
                        <div class="col-12">
                            <select id="ri_id" name="ri_id" class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                                <option></option>
                                <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="fakultas_id">
                            Fakultas
                        </label>
                        <div class="col-12">
                            <select id="fakultas_id" name="fakultas_id" class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --">
                                <option></option>
                                <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="departemen_id">
                            Departemen
                        </label>
                        <div class="col-12">
                            <select id="departemen_id" name="departemen_id" class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --">
                                <option></option>
                                <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="prodi_id">
                            Program Studi
                        </label>
                        <div class="col-12">
                            <select id="prodi_id" name="prodi_id" class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --">
                                <option></option>
                                <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <label class="form-label" for="kurikulum_id">
                            Kurikulum
                        </label>
                        <div class="col-12">
                            <select id="kurikulum_id" name="kurikulum_id" class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --">
                                <option></option>
                                <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="slide-down" v-if="kurikulumKD">
                <hr />
                <router-link :to="{ name: 'EditViewMatriksCPLdanTPP', params: { kurikulum_id: kurikulumID } }" class="border border-1 border-primary btn btn-primary mb-5">
                    <span class="fa fa-edit"></span>
                    Matriks CPL dan TPP
                </router-link>

                <router-link :to="{ name: 'ShowViewMatriksCPLdanTPP', params: { kurikulum_id: kurikulumID } }" class="border border-1 border-primary btn btn-light-primary mb-5 mx-5">
                    <span class="fa fa-search"></span>
                    Detail Matriks CPL dan TPP
                </router-link>

                <SSDatatable
                    ref="datatableRef"
                    url="/cpl-tpp-datatable"
                    primaryKey="cpl_id"
                    :columns="columns"
                    filterName="kurikulum_kd"
                    :filterData="kurikulumKD"
                />
            </div>
            <div v-else>
                <hr class="mt-2 mb-7">
                <div class="alert alert-primary alert-dismissible fade show fs-4 text-center mb-7" role="alert">
                    <i class="fas fa-info-circle fs-3 text-primary mx-1"></i>
                    <strong>Pilih kurikulum</strong> terlebih dahulu untuk menampilkan data <strong>Matriks CPL dan TPP</strong>.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import LoadingOverlay from '@/components/LoadingOverlay'
import SSDatatable from '@/components/SSDatatable.vue'
import request from '@/utils/request'

const datatableRef = ref(null)
const loading = ref(false)

const columns = [
	{ title: 'No', data: 'rownum', searchable: false, width: '8%', className: 'px-1' },
	{ title: 'Deskripsi CPL Prodi', data: 'tpp', render: (data, type, row) => renderColumn(data, type, row) },
]

function renderColumn(data, type, row) {
    if (type === 'display' && row) {
        let deskripsiCPL = `<p class="m-0" style="text-align: justify;">${row.cpl_kd} | ${row.deskripsi_ind}</p>`;
        let dataTPP = data
            .map((item) => {
                return `<span class="badge badge-primary">${item.tpp_nama_ind}</span>`;
            })
            .join(' ');

        return `
            <div>
                <span class="fw-bold">${deskripsiCPL}</span>
                <hr class="my-3">
                <span class="text-muted">Tujuan Pendidikan Program Studi: ${dataTPP}</span>
            </div>
        `;
    }

    return '';
}

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])

const kurikulumKD = ref('')
const kurikulumID = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}

onMounted( async () => {
    // eslint-disable-next-line no-undef
    $("select").select2();

    const response = await request('/ri')
    ri_list.value = response.data.data
    
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);

        loading.value = true
        fakultas_list.value = data.fakultas
        departemen_list.value = null
        prodi_list.value = null
        kurikulum_list.value = null
        kurikulumID.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);

        loading.value = true
        departemen_list.value = data.departemen
        prodi_list.value = null
        kurikulum_list.value = null
        kurikulumID.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);

        loading.value = true
        prodi_list.value = data.prodi
        kurikulum_list.value = null
        kurikulumID.value = null
        kurikulumKD.value = null
        loading.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);

        loading.value = true
        kurikulum_list.value = data.kurikulum
        kurikulumID.value = null
        kurikulumKD.value = null
        loading.value = false

        renderCustomOption();
    }).on('change', 'select.kurikulum', async (event) => {
        kurikulumKD.value = null
        
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);

        kurikulumID.value = id
        kurikulumKD.value = data.kurikulum_kd
    }); 
})
</script>

<style scoped>
.slide-down { animation: slideDown 5s ease; }
@keyframes slideDown {
    0% { transform: translateY(-5%); opacity: 0; }
    5% { transform: translateY(0); opacity: 1; }
}
</style>