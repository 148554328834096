<template>
	<LoadingOverlay :loading="loading" />
   <breadcrumb-content menu="Review Kurikulum" subMenu="Review 2" />
	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="padding-bottom:11px; border-bottom:3px solid #a1a5c3;">
               <li v-for="(row, index) in NavPills" :key="index" class="nav-item" role="presentation">
                  <button :class="`nav-link ${ ((index == 0) ? ('active') : ('')) }`" type="button" role="tab" aria-selected="false" :data-page="row.class" @click="onHandlePage">{{ row.name }}</button>
               </li>
            </ul>
				<div class="p-5">
					<div v-if="CurriculumIdentity">
						<component :is="CurriculumIdentity" form="index" :Curriculum="data.Curriculum" />
					</div>
               <div v-else-if="CurriculumEvaluation">
						<component :is="CurriculumEvaluation" form="curriculum-evaluation" :Curriculum="data.Curriculum" />
					</div>
					<div v-else-if="GraduateProfile">
						<component :is="GraduateProfile" form="graduate-profile" :Curriculum="data.Curriculum" />
					</div>
					<div v-else-if="CPL">
						<component :is="CPL" form="CPL" :Curriculum="data.Curriculum" />
					</div>
					<div v-else-if="EducationalProgramObjectives">
						<component :is="EducationalProgramObjectives" form="educational-program-objectives" :Curriculum="data.Curriculum" />
					</div>
					<div v-else-if="StudyMaterial">
						<component :is="StudyMaterial" form="study-material" :Curriculum="data.Curriculum" />
					</div>
					<div v-else-if="CourseInCurriculum">
						<component :is="CourseInCurriculum" form="course-in-curriculum" :Curriculum="data.Curriculum" />
					</div>
					<div v-else-if="MatrixCourseInCurriculumAndStudyMaterial">
						<component :is="MatrixCourseInCurriculumAndStudyMaterial" form="matrix-course-in-curriculum-and-study-material" :Curriculum="data.Curriculum" />
					</div>
				</div>
			</div>
		</div>
		<div v-if="Modal">
			<component :is="Modal" :id="urlParams.id" :data="ModalData" :Curriculum="data.Curriculum" @removeModal="onRemoveModal" />
		</div>
		<AssesmentSlider :id="urlParams.id" :Curriculum="data.Curriculum" @openModal="handleSubmit" />
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
   import { useRouter } from 'vue-router';
   import request from '@/utils/request';
   import AssesmentSlider from './AssesmentSlider.vue';
	import LoadingOverlay from '@/components/LoadingOverlay';

   const loading = ref(true);
   const router = useRouter();
   const urlParams = router.currentRoute.value.params;
   const NavPills = ref([{ class: 'index', name: 'Identitas Kurikulum' }, { class: 'curriculum-evaluation', name: 'Evaluasi Kurikulum' }, { class: 'graduate-profile', name: 'Profil Lulusan' }, { class: 'CPL', name: 'CPL' }, { class: 'educational-program-objectives', name: 'Matriks CPL & TPP' }, { class: 'study-material', name: 'Matriks CPL & BK' }, { class: 'course-in-curriculum', name: 'Matakuliah' }, { class: 'matrix-course-in-curriculum-and-study-material', name: 'Matriks Matakuliah & BK' }]);
	const data = ref({ Curriculum: {}, ReviewOfficer: {} });
	const ModalData = ref({});
   let CurriculumIdentity = shallowRef(null);
   let CurriculumEvaluation = shallowRef(null);
   let GraduateProfile = shallowRef(null);
   let CPL = shallowRef(null);
   let EducationalProgramObjectives = shallowRef(null);
   let StudyMaterial = shallowRef(null);
   let CourseInCurriculum = shallowRef(null);
   let MatrixCourseInCurriculumAndStudyMaterial = shallowRef(null);
	let Modal = shallowRef(null);
   const urlPage = ((target) => ((target == 'index') ? ('CurriculumIdentity') : ((target.split('-')).map((word) => word.charAt(0).toUpperCase() + word.slice(1) ).join(''))) );
	const handleSubmit = ((data) => {
		document.getElementById('kt_engage_demos_close').click();
		onOpenModal(data);
	});
	const onHandlePage = ((event) => {
		const prevPage = document.querySelector('button.nav-link.active').dataset.page;

		// eslint-disable-next-line no-undef
		$(`#${ prevPage }`).slideUp(1000);
		setTimeout(() => {
			(Array.from(document.querySelectorAll('button.nav-link'))).map((v) => v.classList.remove('active') );
			const el = event.target;
			el.classList.add('active');
			
			eval(urlPage(prevPage)).value = null;
			import(`./${ urlPage(el.dataset.page) }.vue`).then(module => {
				eval(urlPage(el.dataset.page)).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#${ el.dataset.page }`).slideDown(1000);
			});
		}, 1000);
	});
	const onOpenModal = ((data) => {
		Modal.value = null;
		ModalData.value = data;
		import(`./Modal.vue`).then(module => {
			Modal.value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#Modal-${ data.body }`).fadeIn(1000);
		});
	});
	const onRemoveModal = (({ dismiss, target }) => { eval(dismiss).value = null; router.push(target); });

	onMounted(async () => {
		try {
			data.value.ReviewOfficer = (await request({ url: `/petugas-review/${ urlParams.id }` })).data;
			data.value.Curriculum = (await request({ url: `/kurikulum/${ data.value.ReviewOfficer.kurikulum_id_fk }` })).data;

         import(`./${ urlPage('index') }.vue`).then(module => {
				eval(urlPage('index')).value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#index`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>

<style>
   table#correlation-table thead, table#correlation-table tbody, table#study-material thead, table#study-material tbody { border:1px solid #bcc1c5; }
   table#correlation-table tbody tr:last-child, table#correlation-table tfoot tr:last-child, table#study-material tbody tr:last-child, table#study-material tfoot tr:last-child { border-bottom:1px solid #bcc1c5; }
</style>