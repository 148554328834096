<template>
   <template v-for="petugas in props.ReviewOfficer" :key="petugas.petugas_review_id">
      <div class="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 end-0 transform-90 mt-5 mt-lg-20 gap-2" :style="{ 'top': petugas.petugas_ke == 1 ? '40%' : '60%' }" @click="handleAssesment(petugas.petugas_review_id)">
         <button id="kt_engage_demos_toggle" class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" title="Lihat Penilaian" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click" data-bs-trigger="hover">
            <span id="kt_engage_demos_label">Assessment {{ petugas.petugas_ke }}</span>
         </button>
      </div>
   </template>

   <div id="kt_engage_demos" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="explore" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="1200px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_engage_demos_toggle" data-kt-drawer-close="#kt_engage_demos_close">
      <div class="card shadow-none rounded-0 w-100">
         <div class="card-header" id="kt_engage_demos_header">
            <h3 class="card-title fw-bold text-gray-700">Borang Asesmen Kurikulum - Petugas {{ selectedPetugasID }}</h3>
            <div class="card-toolbar">
               <button type="button" class="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6" id="kt_engage_demos_close">
                  <span class="svg-icon svg-icon-2">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                     </svg>
                  </span>
               </button>
            </div>
         </div>
         <div class="card-body" id="kt_engage_demos_body">
            <div v-if="AssesmentSliderBody">
               <component :is="AssesmentSliderBody" :id="selectedPetugasID" :data="data" :Curriculum="props.Curriculum" @openModal="handleSubmit" />
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, onBeforeUnmount, defineProps, defineEmits, ref, shallowRef } from 'vue';
   import request from '@/utils/request';

   const props = defineProps({ ReviewOfficer: Object, Curriculum: Object });
   const emits = defineEmits(['openModal']);
   const loading = ref(true);

   const data = ref({ Parent: [], Child: [], Point: [] });
   const selectedPetugasID = ref(null);
   let AssesmentSliderBody = shallowRef(null);
   let drawerInstance = ref(null);

   const handleSubmit = ((data) => emits('openModal', data) );
   const handleAssesment = ((petugasID) => {
      selectedPetugasID.value = petugasID;
      AssesmentSliderBody.value = null;

      import(`./AssesmentSliderBody.vue`).then((module) => (AssesmentSliderBody.value = module.default));
      if (drawerInstance.value) drawerInstance.value.show();
   });

	onMounted(async () => {
		try {
         data.value.Parent = ((await request({ url: `/rvwindikatorh?per_page=-1` })).data.data).sort((a, b) => (a.rvw_indikator_h_id - b.rvw_indikator_h_id) );
         data.value.Child = ((await request({ url: `/rvwindikatord?per_page=-1` })).data.data).sort((a, b) => (a.rvw_indikator_d_id - b.rvw_indikator_d_id) );
         data.value.Point = ((await request({ url: `/rubrikpenilaian?per_page=-1` })).data.data).sort((a, b) => (b.rubrik_penilaian_id - a.rubrik_penilaian_id) );

         // const drawerElement = document.getElementById('kt_engage_demos');
         // if (drawerElement) {
         //    drawerInstance.value = window.KTDrawer.getInstance(drawerElement) || window.KTDrawer.createInstances();
         // }
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});

   onBeforeUnmount(() => {
      if (drawerInstance.value) {
         drawerInstance.value.destroy();
      }
   });
</script>