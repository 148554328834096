<template>
	<breadcrumb-content menu="Review Kurikulum" subMenu="Reviewer 2"/>
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<LoadingOverlay :loading="loading" />
			<DataTable :columns="columns" :data="ReviewOfficer" class="table table-row-bordered gy-5 align-middle dataTable no-footer" />
		</div>
	</div>
</template>

<script setup>
	import { ref, onMounted, onBeforeUnmount } from 'vue';
	import { useRouter } from 'vue-router';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import DataTable from 'datatables.net-vue3';
	import request from '@/utils/request';

	const loading = ref(false);
	const router = useRouter();
	const ReviewOfficer = ref([]);

	const columns = [
		{ title: 'No', data: 'rownum', searchable: false, width: '70px', className: 'px-1 text-center' },
		{ title: 'Fakultas', data: 'fakultas_nama_ind' },
		{ title: 'Program Studi', data: 'prodi_nama_ind' },
		{ title: 'Jenjang', data: 'jenjang_nama_ind' },
		{ title: 'Kode Kurikulum', data: 'kurikulum_kd_fk', width: '120px' },
		{ title: 'Nama Kurikulum', data: 'nama_ind' },
		{ title: 'Periode', data: 'tahun_akhir', width: '120px',
			render: function (data, type, row) {
				const tahun_awal = new Date(row.tahun_awal).getFullYear();
				const tahun_akhir = new Date(row.tahun_akhir).getFullYear();
				
				return `${tahun_awal} - ${tahun_akhir}`;
			}	
		},
		{ title: 'Aksi', data: null, searchable: false, width: '70px', className: 'px-1 text-center', render: (data, type, row) => {
            return `<i class="fa fa-search show" data-id="${ row.petugas_review_id }" style="margin:2px; font-size:14px; color:#009ef7; cursor:pointer;"></i>`;
         }
		}
	];

	const handleClick = (event) => {
		const target = event.target;
      const id = target.getAttribute('data-id');
      if (target.classList.contains('show')) handleShow(id);
   };
	const handleShow = async id => router.push({ name: 'ShowViewReviewer2', params: { id } });
	onMounted(async () => {
		try {
			ReviewOfficer.value = (await request({ url: `/petugas-review-datatable?draw=1&start=0&length=-1&petugas_ke=2` })).data.data;

         document.addEventListener('click', handleClick);
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
   onBeforeUnmount(() => { document.removeEventListener('click', handleClick); });
</script>