<template>
	<LoadingOverlay :loading="loading" />
	<breadcrumb-content menu="Review Kurikulum" subMenu="DPASDP" />

	<div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
		<div class="card-body">
			<div class="row">
				<div class="col-md-6 col-12">
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Rumpun Ilmu</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.ri_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Fakultas</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.fakultas_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Departemen</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.departemen_nama_ind" readonly>
					</div>
				</div>
				</div>
				<div class="col-md-6 col-12">
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Program Studi</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.prodi_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Jenjang</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.jenjang_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Kurikulum</label>
					<div class="col-sm-9">
						<textarea type="text" class="form-control form-control-solid-bg form-control-sm" :value="`${dataKurikulum.kurikulum_kd}\n${dataKurikulum.nama_ind}`" readonly></textarea>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
	
	<template v-if="ReviewDPASDP">
		<component :is="ReviewDPASDP" form="ReviewDPASDP" target="PersetujuanDanPengesahan" @PersetujuanDanPengesahan="recieveNewForm"/>
	</template>

	<template v-else-if="PersetujuanDanPengesahan">
		<component :is="PersetujuanDanPengesahan" form="PersetujuanDanPengesahan" @ReviewDPASDP="recieveNewForm"/>
	</template>
</template>

<script setup>
import { onMounted, shallowRef, ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

const loading = ref(true)
const router = useRouter()
const kurikulum_id = router.currentRoute.value.params.id
const dataKurikulum = ref({})

let ReviewDPASDP = shallowRef(null);
let PersetujuanDanPengesahan = shallowRef(null);
const recieveNewForm = (async ({ open, dismiss }) => {
	eval(dismiss).value = null;

	import(`./${ open }.vue`).then(module => {
		eval(open).value = module.default;
	}).then(async () => {
		// eslint-disable-next-line no-undef
		$(`#${ open }`).slideDown(1000);
	});
});

onMounted(async () => { 
	try {
		dataKurikulum.value = (await request(`/kurikulum/${kurikulum_id}?loads=review4`)).data

		import(`${!dataKurikulum.value.review4 ? './ReviewDPASDP.vue' : './PersetujuanDanPengesahan.vue'}`).then(module => { 
			!dataKurikulum.value.review4 ? ReviewDPASDP.value = module.default : PersetujuanDanPengesahan.value = module.default 
		}).then(() => { 
			// eslint-disable-next-line no-undef
			$(`#${!dataKurikulum.value.review4 ? 'ReviewDPASDP' : 'PersetujuanDanPengesahan'}`).slideDown(1000); 
		});
	} catch (error) {
		console.error('Error fetching data: ', error);
	} finally {
		loading.value = false;
	}
})
</script>