<template>
   <breadcrumb-content menu="Master Data" subMenu="Status Akreditasi" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Detail Status Akreditasi</h1>
            <div class="p-5">
               <div class="row mb-5">
                  <label class="form-label">Nama Status Akreditasi</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="level.nama_ind" disabled="disabled" />
                  </div>
                  <div class="col-6">
                     <flag-image bahasa="Inggris" />
                     <input class="form-control form-control-solid-bg" v-model="level.nama_eng" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Dibuat pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="formattedCreatedAt" disabled="disabled" />
                  </div>
               </div>
               <div class="row mb-5">
                  <label class="form-label">Diubah pada</label>
                  <div class="col-6">
                     <flag-image bahasa="Indonesia" />
                     <input class="form-control form-control-solid-bg" v-model="formattedUpdatedAt" disabled="disabled" />
                  </div>
               </div>
               <div class="mt-10">
                  <div class="col-lg-6">
                     <button @click="redirectToPeringkatList" class="btn btn-secondary">Kembali</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, ref, computed } from 'vue';
	import { useRouter } from 'vue-router';
	import { formatReadableDate } from '@/utils/date';
	import request from '@/utils/request';

	const router = useRouter();
	const loading = ref(true);
   const id = router.currentRoute.value.params.id;
	const level = ref({});

	const formattedCreatedAt = computed(() => formatReadableDate(level.value.created_at));
	const formattedUpdatedAt = computed(() => formatReadableDate(level.value.updated_at));

	const redirectToPeringkatList = () => router.push({ name: 'StatusAkreditasi' });

	onMounted(async () => {
		try {
			const { data } = await request(`/peringkat/${id}`);
			level.value = data;
		} catch (error) {
			console.error('Error fetching peringkat data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>