<template>
	<LoadingOverlay :loading="loading" />
	<breadcrumb-content menu="Pembuatan BRP" subMenu="Lampiran"/>
	<div v-if="CourseInCurriculumFilter">
		<component :is="CourseInCurriculumFilter" form="CourseInCurriculumFilter" target="CourseInCurriculum" @CourseInCurriculum="recieveNewForm" />
	</div>
	<div v-else-if="CourseInCurriculum">
		<component :is="CourseInCurriculum" form="CourseInCurriculum" target="CourseInCurriculumFilter" :SearchInfo="SearchInfo" :BRP="BRP" @CourseInCurriculumFilter="recieveNewForm" />
	</div>
</template>

<script setup>
	import { onMounted, shallowRef, ref } from 'vue';
	import LoadingOverlay from '@/components/LoadingOverlay';

	const loading = ref(true);
	const SearchInfo = ref({});
	const BRP = ref({});
	let CourseInCurriculumFilter = shallowRef(null);
	let CourseInCurriculum = shallowRef(null);
	const recieveNewForm = (async ({ open, dismiss, searchInfo, detail }) => {
		eval(dismiss).value = null;
		SearchInfo.value = searchInfo;
		BRP.value = detail;
		import(`./${ open }.vue`).then(module => {
			eval(open).value = module.default;
		}).then(async () => {
			// eslint-disable-next-line no-undef
			$(`#${ open }`).slideDown(1000);
		});
	});

	onMounted(async () => {
		try {
			import(`./CourseInCurriculumFilter.vue`).then(module => {
				CourseInCurriculumFilter.value = module.default;
			}).then(() => {
				// eslint-disable-next-line no-undef
				$(`#CourseInCurriculumFilter`).slideDown(1000);
			});
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>

<style>
	.select2-selection.select2-selection--single.curriculum { height:60px; }
</style>