<template>
	<breadcrumb-content menu="Pembuatan Kurikulum" subMenu="CPL" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah CPL</h1>
				<div class="p-5">
					<Form
						id="form-cpl-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
                        ref="formRef"
					>
						<LoadingOverlay :loading="loading" />

                        <div class="row mb-5">
                            <label class="form-label">
                                Rumpun Ilmu
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg ri" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --">
                                    <option></option>
                                    <option v-for="data in ri_list" :key="data.ri_id" :value="data.ri_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="ri_id" name="ri_id" v-model="selectedRI" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark rumpun_ilmu" readonly placeholder="Select Group of knowledge" v-model="ri_nama_eng">
                            </div>
                        </div>
                        
                        <div class="row mb-5">
                            <label class="form-label">
                                Fakultas
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg fakultas" data-control="select2" data-placeholder="-- Pilih Fakultas --">
                                    <option></option>
                                    <option v-for="data in fakultas_list" :key="data.fakultas_id" :value="data.fakultas_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="fakultas_id" name="fakultas_id" v-model="selectedFakultas" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark fakultas" readonly placeholder="Select Faculty" v-model="fakultas_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Departemen
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg departemen" data-control="select2" data-placeholder="-- Pilih Departemen --">
                                    <option></option>
                                    <option v-for="data in departemen_list" :key="data.departemen_id" :value="data.departemen_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="departemen_id" name="departemen_id" v-model="selectedDepartemen" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark departemen" readonly placeholder="Select Department" v-model="departemen_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Program Studi
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg prodi" data-control="select2" data-placeholder="-- Pilih Program Studi --">
                                    <option></option>
                                    <option v-for="data in prodi_list" :key="data.prodi_id" :value="data.prodi_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="prodi_id" name="prodi_id" v-model="selectedProdi" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark prodi" readonly placeholder="Select Study Program" v-model="prodi_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                Jenjang
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="-- Pilih Level --" v-model="jenjang_nama_ind">
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <input type="text" class="form-control form-control-solid-bg bg-light-dark" readonly placeholder="Select Level" v-model="jenjang_nama_eng">
                            </div>
                        </div>

                        <div class="row mb-5">
                            <label class="form-label">
                                <span style="color: red">*</span>
                                Kurikulum
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg kurikulum" data-control="select2" data-placeholder="-- Pilih Kurikulum --">
                                    <option></option>
                                    <option v-for="data in kurikulum_list" :key="data.kurikulum_id" :value="data.kurikulum_id" :data-nama_eng="data.nama_eng" :data-nama_ind="data.nama_ind" :data-kurikulum_kd="data.kurikulum_kd" :data-is_aktif="data.is_aktif === 1 ? 'Aktif' : 'Tidak Aktif'"> {{ data.nama_ind }} </option>
                                </select>

                                <Field type="hidden" id="kurikulum_id" name="kurikulum_id" v-model="selectedKurikulum" readonly/>
                                <Field type="hidden" id="kurikulum_kd" name="kurikulum_kd" v-model="kurikulumKD" readonly/>
                                <div id="kurikulum_id-error" class="invalid-feedback">Kurikulum harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <div class="form-control form-control-solid-bg bg-light-dark" v-if="getCurriculumEng" v-html="getCurriculumEng"></div>
                                <div class="form-control form-control-solid-bg bg-light-dark text-gray-500" v-else>Select Curriculum</div>
                                <input type="hidden" class="form-control form-control-solid-bg bg-light-dark kurikulum" readonly :value="kurikulumKD" placeholder="Select Curriculum">
                            </div>
                        </div>

                        <FormInput
							labelInd="CPL Kode"
							customName="cpl_kd"
                            customClass="cpl_kd"
							customErrorInd="CPL Kode"
							:isEnglish="false"
						/>
						<FormInput
							labelInd="Nama CPL"
							labelEng="Name Of CPL"
							columnName="nama"
							customErrorInd="Nama CPL"
							customErrorEng="Name Of CPL"
						/>
						<FormInput
							inputType="textarea"
							rows="5"
							labelInd="Deskripsi"
							labelEng="Description"
							columnName="deskripsi"
                            :isRequired="false"
						/>
						<FormInput
							inputType="textarea"
							rows="5"
							labelInd="Sub CPL"
							labelEng="Sub CPL"
							columnName="sub_cpl"
                            :isRequired="false"
                            :ckeditor="true"
						/>

                        <!-- <FormInput
							labelInd="Nilai CPL"
							customName="cpl_nilai"
                            customClass="cpl_nilai"
							:isEnglish="false"
                            :isRequired="false"
						/> -->

                        <div class="row mb-5">
                            <label class="form-label">
                                Taksonomi Bloom
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg taksonomi_bloom" data-control="select2" data-placeholder="-- Pilih Taksonomi Bloom --" multiple>
                                    <option></option>
                                    <option v-for="data in tb_list" :key="data.taksonomi_h_id" :value="data.taksonomi_h_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="taksonomi_h_id" name="taksonomi_h_id" v-model="selectedTB" readonly/>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <textarea class="form-control form-control-solid-bg bg-light-dark taksonomi_bloom" rows="1" readonly placeholder="Select Bloom's Taxonomy" v-model="tb_nama_eng"></textarea>
                            </div>
                        </div>
                        
                        <div class="row mb-5">
                            <label class="form-label">
                                <span style="color: red">*</span>
                                Taksonomi Bloom Detail
                            </label>
                            <div class="col-6">
                                <flag-image bahasa="Indonesia"/>

                                <select class="form-control form-control-solid-bg taksonomi_bloom_detail" data-control="select2" data-placeholder="-- Pilih Taksonomi Bloom Detail --" multiple>
                                    <option></option>
                                    <option v-for="data in tbd_list" :key="data.taksonomi_d_id" :value="data.taksonomi_d_id" :data-nama_eng="data.nama_eng"> {{ data.taksonomi_h_kd + ' - ' + data.nama_ind }} </option>
                                </select>
                                <Field type="hidden" id="taksonomi_d_id" name="taksonomi_d_id" v-model="selectedTBD" readonly/>
                                <div id="taksonomi_d_id-error" class="invalid-feedback">Taksonomi Bloom Detail harus diisi</div>
                            </div>
                            <div class="col-6">
                                <flag-image bahasa="Inggris"/>

                                <textarea class="form-control form-control-solid-bg bg-light-dark taksonomi_bloom_detail" rows="1" readonly placeholder="Select Bloom's Taxonomy Details" v-model="tbd_nama_eng"></textarea>
                            </div>
                        </div>

                        <div class="row mb-5">
                            <div class="col">
                                <button type="button" :class="kurikulumKD ? 'btn btn-success' : 'btn btn-secondary'" :disabled="!kurikulumKD" @click="onShowDataSelect">Tampilkan</button>
                            </div>

                            <div v-if="showDataSelect" class="slide-down">
                                <hr>
                                <div class="col-8">
                                    <div class="row mb-1" v-for="(data, index) in listDataToShow" :key="index">
                                        <label class="col-form-label col-sm-3">{{ data.label }}</label>
                                        <div class="col-sm-9">
                                            <input type="text" :id="index" class="form-control form-control-solid-bg form-control-sm" :value="data.value" readonly :placeholder="`Pilih ${data.label}`">
                                        </div>
                                    </div>
                                </div>

                                <div class="cpl-kk-kompetensi">
                                    <hr />
                                    <div class="alert alert-primary alert-dismissible fade show" role="alert">
                                        <div class="d-flex align-items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                                            </svg>
                                            <h3 class="alert-heading mt-2">INFORMASI!</h3>
                                            <i class="fas fa-close text-primary fs-1 btn-close" data-bs-dismiss="alert"></i>
                                        </div>
                                        <hr>
                                        <p class="fs-5 mb-0">
                                            Perlu diperhatikan ketika <b>menambah data</b>, data yang dimasukkan <b>tidak langsung masuk</b> ke dalam penyimpanan(basis data). Jadi ketika memuat ulang halaman / sesi berakhir, <b>data akan terhapus</b>.
                                        </p>
                                    </div>      

                                    <div class="cpl-kk mb-5">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h3>CPL Terhadap KK</h3>
                                            <div class="text-end">
                                                <div class="btn btn-light-primary btn-sm border border-primary border-1" @click="btnShowFormCPLKkKompetensi('kerangka-kerja')">
                                                    <span class="fa fa-plus"></span>
                                                    Memetakan CPL Terhadap Kerangka Kerja
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive mt-3">
                                            <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                                <thead>
                                                    <tr style="border:1px solid #c5c6cc !important;">
                                                        <th class="text-center" style="width: 5% !important; font-weight:600;">No</th>
                                                        <th class="text-center" style="width: 20% !important; font-weight:600;">Jenis Kerangka Kerja</th>
                                                        <th class="text-center" style="font-weight:600;">Kerangka Kerja</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="border:1px solid #c5c6cc !important;">
                                                    <tr v-for="(dataGroup, index) in groupedCPLKKDetail" :key="index">
                                                        <td class="text-center">{{ index + 1 }}</td>
                                                        <td class="text-center">{{ dataGroup.jenis_kk_nama_ind }}</td>
                                                        <td style="text-align: justify;">
                                                            <ol class="m-1 px-5">
                                                                <li v-for="(data, subIndex) in dataGroup.items" :key="subIndex" class="my-2" style="text-align: justify;">
                                                                    <span class="fw-bold">({{ data.kk_kd }})</span>: <span>{{ data.nama_ind }}</span>
                                                                </li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="listTempCPLKKDetail.length === 0">
                                                        <td class="text-center fw-bold" colspan="3">Belum ada data</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <transition name="slide" @enter="enter" @leave="leave">
                                            <div :class="{'border border-primary border-2 kkListContainer': true, 'mb-5': showFormCPLtoKK}" v-if="showFormCPLtoKK">
                                                <div class="p-5 bg-light-primary d-flex justify-content-between align-items-center">
                                                    <h2 class="flex-grow-1 text-primary text-center mt-1">Memetakan CPL Terhadap Kerangka Kerja</h2>
                                                    <h2 class="text-primary text-end mt-1" style="cursor: pointer;" @click="btnHideFormCPLKkKompetensi('kerangka-kerja')"><i class="fas fa-close text-primary fs-1"></i></h2>
                                                </div>
                                                <hr class="mt-n1 mb-0 opacity-100 border border-primary border-1">
                                                
                                                <div class="p-5">
                                                    <Form ref="formKK" @submit="onSubmitTempCPLtoKK">
                                                        <Field type="hidden" name="cpl_kd_hidden" v-model="hiddenValueCPLKD" readonly />
    
                                                        <div class="row mb-5">
                                                            <label class="form-label">
                                                                Jenis Kerangka Kerja
                                                            </label>
                                                            <div class="col-6">
                                                                <flag-image bahasa="Indonesia"/>
    
                                                                <select class="form-control form-control-solid-bg jenis_kk" data-control="select2" data-placeholder="-- Pilih Jenis Kerangka Kerja --">
                                                                    <option></option>
                                                                    <option v-for="data in jenis_kk_list" :key="data.jenis_kk_id" :value="data.jenis_kk_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                                                </select>
                                                                <Field type="hidden" id="jenis_kk_id" name="jenis_kk_id" v-model="selectedJenisKK" readonly/>
                                                            </div>
                                                            <div class="col-6">
                                                                <flag-image bahasa="Inggris"/>
    
                                                                <input type="text" class="form-control form-control-solid-bg bg-light-dark jenis_kk" readonly placeholder="Select Framework Type" v-model="jenis_kk_nama_eng">
                                                            </div>
                                                        </div>
    
                                                        
                                                        <transition name="fade">
                                                            <div class="row" v-if="showKKList">
                                                                <label class="form-label"> 
                                                                    <span style="color: red">*</span>
                                                                    Kerangka Kerja 
                                                                </label>
    
                                                                <div :class="{'table-responsive px-3': true, 'scrolling kk': selectedJenisKK && kk_list.length > 0}">
                                                                    <table class="table table-row-bordered align-middle gy-3 display">
                                                                        <tr v-for="(data, index) in kk_list" :key="index" class="border border-1" style="border-color: var(--kt-input-border-color) !important;">
                                                                            <td class="text-center px-5">
                                                                                <Field class="form-check-input" type="checkbox" name="kk_id" :id="`kk_id-${data.kk_id}`" :checked="selectedCheckedKK.includes(data.kk_id)" v-model="selectedCheckedKK" :value="data.kk_id" style="cursor: pointer;"/>
                                                                            </td>
                                                                            <td class="p-0">
                                                                                <label class="form-check-label get-data" :for="`kk_id-${data.kk_id}`" style="cursor: pointer; text-align: justify; padding-right: 10px;">
                                                                                    <span class="fw-bold get-code">({{ data.kk_kd }})</span>: <span class="get-name">{{ data.nama_ind }}</span> 
                                                                                    <hr class="m-1"> 
                                                                                    <i>{{ data.nama_eng }}</i> 
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-if="kk_list.length === 0" class="border border-1" style="border-color: var(--kt-input-border-color) !important;">
                                                                            <td class="text-center fw-bold">Tidak ada data!</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </transition>
        
                                                        <hr class="mt-0">
        
                                                        <button type="submit" class="btn btn-primary btn-submit w-100">
                                                            <span class="indicator-label"> Simpan CPL KK </span>
                                                            <span class="indicator-progress">Mohon Tunggu... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </Form>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                    
                                    <div class="cpl-kompetnesi">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h3>CPL Terhadap Kompetensi</h3>
                                            <div class="text-end">
                                                <div class="btn btn-light-info btn-sm border border-info border-1" @click="btnShowFormCPLKkKompetensi('kompetensi')">
                                                    <span class="fa fa-plus"></span>
                                                    Memetakan CPL Terhadap Kompetensi
                                                </div>
                                            </div>
                                        </div>
                                        <div class="table-responsive mt-3">
                                            <table id="table" class="table table-row-bordered align-middle gy-3 display" style="border:1px solid #c5c6cc !important;">
                                                <thead>
                                                    <tr style="border:1px solid #c5c6cc !important;">
                                                        <th class="text-center" style="width: 5% !important; font-weight:600;">No</th>
                                                        <th class="text-center" style="width: 20% !important; font-weight:600;">Jenis Kompetensi</th>
                                                        <th class="text-center" style="font-weight:600;">Kompetensi</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="border:1px solid #c5c6cc !important;">
                                                    <tr v-for="(dataGroup, index) in groupedCPLKompDetail" :key="index">
                                                        <td class="text-center">{{ index + 1 }}</td>
                                                        <td class="text-center">{{ dataGroup.jenis_kompetensi_nama_ind }}</td>
                                                        <td style="text-align: justify;">
                                                            <ol class="m-1 px-5">
                                                                <li v-for="(data, subIndex) in dataGroup.items" :key="subIndex" class="my-2" style="text-align: justify;">
                                                                    <span class="fw-bold">({{ data.kompetensi_kd }})</span>: <span>{{ data.nama_ind }}</span>
                                                                </li>
                                                            </ol>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="listTempCPLKompDetail.length === 0">
                                                        <td class="text-center fw-bold" colspan="3">Belum ada data</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <transition name="slide" @enter="enter" @leave="leave">
                                            <div class="border border-info border-2 kompListContainer" v-if="showFormCPLtoKomp">
                                                <div class="p-5 bg-light-info d-flex justify-content-between align-items-center">
                                                    <h2 class="flex-grow-1 text-info text-center mt-1">Memetakan CPL Terhadap Kompetensi</h2>
                                                    <h2 class="text-info text-end mt-1" style="cursor: pointer;" @click="btnHideFormCPLKkKompetensi('kompetensi')"><i class="fas fa-close text-info fs-1"></i></h2>
                                                </div>
                                                <hr class="mt-n1 mb-0 opacity-100 border border-info border-1">
                                                
                                                <div class="p-5">
                                                    <Form ref="formKomp" @submit="onSubmitTempCPLtoKomp">
                                                        <Field type="hidden" name="cpl_kd_hidden" v-model="hiddenValueCPLKD" readonly />
    
                                                        <div class="row mb-5">
                                                            <label class="form-label">
                                                                Jenis Kompetensi
                                                            </label>
                                                            <div class="col-6">
                                                                <flag-image bahasa="Indonesia"/>
    
                                                                <select class="form-control form-control-solid-bg jenis_kompetensi" data-control="select2" data-placeholder="-- Pilih Jenis Kerangka Kerja --">
                                                                    <option></option>
                                                                    <option v-for="data in jenis_kompetensi_list" :key="data.jenis_kompetensi_id" :value="data.jenis_kompetensi_id" :data-nama_eng="data.nama_eng"> {{ data.nama_ind }} </option>
                                                                </select>
                                                                <Field type="hidden" id="jenis_kompetensi_id" name="jenis_kompetensi_id" v-model="selectedJenisKomp" readonly/>
                                                            </div>
                                                            <div class="col-6">
                                                                <flag-image bahasa="Inggris"/>
    
                                                                <input type="text" class="form-control form-control-solid-bg bg-light-dark jenis_kompetensi" readonly placeholder="Select Framework Type" v-model="jenis_kompetensi_nama_eng">
                                                            </div>
                                                        </div>
    
                                                        
                                                        <transition name="fade">
                                                            <div class="row" v-if="showKompList">
                                                                <label class="form-label"> 
                                                                    <span style="color: red">*</span>
                                                                    Kompetensi
                                                                </label>
    
                                                                <div :class="{'table-responsive px-3': true, 'scrolling komp': selectedJenisKomp && kompetensi_list.length > 0}">
                                                                    <table class="table table-row-bordered align-middle gy-3 display">
                                                                        <tr v-for="(data, index) in kompetensi_list" :key="index" class="border border-1" style="border-color: var(--kt-input-border-color) !important;">
                                                                            <td class="text-center px-5">
                                                                                <Field class="form-check-input" type="checkbox" name="kompetensi_id" :id="`kompetensi_id-${data.kompetensi_id}`" :checked="selectedCheckedKomp.includes(data.kompetensi_id)" v-model="selectedCheckedKomp" :value="data.kompetensi_id" style="cursor: pointer;"/>
                                                                            </td>
                                                                            <td class="p-0">
                                                                                <label class="form-check-label get-data" :for="`kompetensi_id-${data.kompetensi_id}`" style="cursor: pointer; text-align: justify; padding-right: 10px;">
                                                                                    <span class="fw-bold get-code">({{ data.kompetensi_kd }})</span>: <span class="get-name">{{ data.nama_ind }}</span> 
                                                                                    <hr class="m-1"> 
                                                                                    <i>{{ data.nama_eng }}</i> 
                                                                                </label>
                                                                            </td>
                                                                        </tr>
                                                                        <tr v-if="kompetensi_list.length === 0" class="border border-1" style="border-color: var(--kt-input-border-color) !important;">
                                                                            <td class="text-center fw-bold">Tidak ada data!</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </transition>
        
                                                        <hr class="mt-0">
        
                                                        <button type="submit" class="btn btn-info btn-submit w-100">
                                                            <span class="indicator-label"> Simpan CPL Kompetensi </span>
                                                            <span class="indicator-progress">Mohon Tunggu... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </Form>
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import Swal from 'sweetalert2';

import { onMounted, ref, computed, nextTick, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import { sendRequest } from '@/api/common'

import { Field } from 'vee-validate'
import FormInput from '@/components/FormInput.vue'

const formRef = ref(null)
const formKK = ref(null)
const formKomp = ref(null)
const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'CPL' })

const onSubmit = async values => {
    const { cpl_kd, kurikulum_id, nama_ind, nama_eng, deskripsi_ind, deskripsi_eng, sub_cpl_ind, sub_cpl_eng } = values;
    const cpl_kk = [...listTempCPLKK.value].sort((a, b) => a.kk_id - b.kk_id);
    const cpl_kompetensi = [...listTempCPLKomp.value].sort((a, b) => a.kompetensi_id - b.kompetensi_id);
    const cpl_taksonomi = values.taksonomi_d_id.map(id => ({ taksonomid_id: parseInt(id, 10) }));

    const dataToSend = {
		cpl_kd: cpl_kd,
		cpl_kk: cpl_kk,
		cpl_kompetensi: cpl_kompetensi,
		cpl_taksonomi: cpl_taksonomi,
		kurikulum_id_fk: parseInt(kurikulum_id),
		nama_ind: nama_ind, nama_eng: nama_eng,
		deskripsi_ind: deskripsi_ind, deskripsi_eng: deskripsi_eng,
		sub_cpl_ind: sub_cpl_ind, sub_cpl_eng: sub_cpl_eng,
    };

    if (cpl_kd || nama_ind || nama_eng || cpl_taksonomi.length != 0) {
        const { data } = await request({ url:`/cpl-check?kurikulum_kd=${kurikulumKD.value}&cpl_kd=${cpl_kd}&nama_ind=${nama_ind}&nama_eng=${nama_eng}` });
        if (data.cpl_kd && data.nama_ind && data.nama_eng) { 
            if (data.cpl_kd.is_exist) { Swal.fire({ title: `Kode CPL '${cpl_kd}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }
            if (data.nama_ind.is_exist) { Swal.fire({ title: `Nama ind '${nama_ind}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }
            if (data.nama_eng.is_exist) { Swal.fire({ title: `Nama eng '${nama_eng}' sudah ada!`, icon: 'error', confirmButtonText: 'OK' }); return; }
            
            if (listTempCPLKK.value.length == 0) { Swal.fire({ title: 'Harap mengisi Data CPL terhadap KK terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' }); return; }
            // if (listTempCPLKomp.value.length == 0) { Swal.fire({ title: 'Harap mengisi Data CPL terhadap Kompetensi terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' }); return; }

            // if success
            const param = {
                url: '/cpl',
                method: 'POST',
                body: dataToSend,
                loading: loading,
                context: 'CPL'
            }
            return await sendRequest(param)
        } else {
            Swal.fire({ title: `${data.cpl_kd ? '' : 'Kode CPL'} ${data.nama_ind ? '' : 'Nama ind'} ${data.nama_eng ? '' : 'Nama eng'} tidak boleh kosong!`, icon: 'error', confirmButtonText: 'OK' }); 
        }
    } else {
        Swal.fire({ title: `Harap memasukkan Data CPL terlebih dahulu!`, icon: 'error', confirmButtonText: 'OK' }); 
    }
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			cpl_kd: 'Kode CPL',
			nama_ind: 'Nama CPL',
			nama_eng: 'Name Of CPL',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })

const ri_list = ref([])
const fakultas_list = ref([])
const departemen_list = ref([])
const prodi_list = ref([])
const kurikulum_list = ref([])
const tb_list = ref([])
const tbd_list = ref([])
const jenis_kk_list = ref([])
const kk_list = ref([])
const jenis_kompetensi_list = ref([])
const kompetensi_list = ref([])

const ri_nama_eng = ref('')
const fakultas_nama_eng = ref('')
const departemen_nama_eng = ref('')
const prodi_nama_eng = ref('')
const jenjang_nama_ind = ref('')
const jenjang_nama_eng = ref('')
const tb_nama_eng = ref('')
const tbd_nama_eng = ref('')
const jenis_kk_nama_eng = ref('')
const jenis_kompetensi_nama_eng = ref('')

const selectedRI = ref('')
const selectedFakultas = ref('')
const selectedDepartemen = ref('')
const selectedProdi = ref('')
const selectedKurikulum = ref('')
const selectedTB = ref([])
const selectedTBD = ref([])
const selectedJenisKK = ref('')
const selectedCheckedKK = ref([]);
const selectedJenisKomp = ref('')
const selectedCheckedKomp = ref([]);

const hiddenValueCPLKD = ref('')

const kurikulumKD = ref('')
const renderCustomOption = () => {
    const customSelect = (ress) => {
        if (!ress.id) return ress.text;

        // eslint-disable-next-line no-undef
        return $(`<label style="display:flex; flex-direction:column; cursor:pointer;"><span style="font-size:10px;"><b>${ $(ress.element).data('kurikulum_kd') } (${ $(ress.element).data('is_aktif') })</b></span><span style="font-size:14px;">${ $(ress.element).data('nama_ind') }</span></label>`);
    };
    // eslint-disable-next-line no-undef
    $('select.kurikulum').select2({ templateResult: customSelect, templateSelection: customSelect });
}
const getCurriculumEng = computed(() => {
	if (!kurikulumKD.value) return '';

    const selectedData = kurikulum_list.value.find(data => data.kurikulum_kd === kurikulumKD.value);
    if (selectedData) {
        const { kurikulum_kd, is_aktif, nama_eng } = selectedData;
        return `<label style="display:flex; flex-direction:column; cursor:pointer;">
                <span style="font-size:10px;"><b>${kurikulum_kd} (${is_aktif === 1 ? 'Active' : 'Not Active'})</b></span>
                <span style="font-size:14px;">${nama_eng}</span>
                </label>`;
    }
    return '';
})

const isSetTimeOut = ref(false)
async function filteredSelect() {
    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.ri', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/ri/${id}`);
        selectedRI.value = id
		ri_nama_eng.value = data.nama_eng
        
        loading.value = true
		
		selectedFakultas.value = null
		selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null

        fakultas_list.value = data.fakultas
        fakultas_nama_eng.value = null
        departemen_list.value = null
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.fakultas', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/fakultas/${id}`);
		selectedFakultas.value = id
        fakultas_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedDepartemen.value = null
		selectedProdi.value = null
		selectedKurikulum.value = null

        departemen_list.value = data.departemen
        departemen_nama_eng.value = null
        prodi_list.value = null
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.departemen', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/departemen/${id}`);
		selectedDepartemen.value = id
        departemen_nama_eng.value = data.nama_eng
        
        loading.value = true
		
        selectedProdi.value = null
		selectedKurikulum.value = null

        prodi_list.value = data.prodi
        prodi_nama_eng.value = null
        jenjang_nama_ind.value = null
        jenjang_nama_eng.value = null
        kurikulum_list.value = null
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.prodi', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/prodi/${id}`);
		selectedProdi.value = id
        prodi_nama_eng.value = data.nama_eng
        
        loading.value = true
		selectedKurikulum.value = null

        jenjang_nama_ind.value = data.jenjang_nama_ind
        jenjang_nama_eng.value = data.jenjang_nama_eng
        kurikulum_list.value = data.kurikulum
        kurikulumKD.value = null

        loading.value = false
        isSetTimeOut.value = false
    }).on('change', 'select.kurikulum', async (event) => {
        const id = event.target.value;
        const { data } = await request(`/kurikulum/${id}`);
		selectedKurikulum.value = id

        kurikulumKD.value = data.kurikulum_kd

        if (showDataSelect.value) {
            showDataSelect.value = false; 
            showFormCPLtoKK.value = false; showFormCPLtoKomp.value = false; 
            listTempCPLKK.value = []; listTempCPLKKDetail.value = []; listTempCPLKomp.value = []; listTempCPLKompDetail.value = [];
            if (isSetTimeOut.value) setTimeout(() => { showDataSelect.value = true; }, 150);
            
            isSetTimeOut.value = true
        }
    });

    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.taksonomi_bloom', async function() {
        // eslint-disable-next-line no-undef
        const selectedIds = $(this).val() || [];
        selectedTB.value = mergedSelectedIds([], selectedIds.map(Number));

        const { sortedNamaEngList, results } = await updateNamaEngList(selectedTB.value, 'taksonomi-h');
        tb_nama_eng.value = sortedNamaEngList.join(', ');

        tbd_list.value = mergedSelectedIds([], results.map(r => r.taksonomi_d).flat());
        tbd_nama_eng.value = null;
        selectedTBD.value = [];
        // eslint-disable-next-line no-undef
        $('select.taksonomi_bloom_detail').val([]).trigger('change');
        
        // eslint-disable-next-line no-undef
        let heightTB = $("span.select2-selection.taksonomi_bloom").outerHeight();
        document.querySelector("textarea.taksonomi_bloom").style.height = heightTB+'px';
        document.querySelector("textarea.taksonomi_bloom_detail").style.height = '43.5625px';
    }).on('change', 'select.taksonomi_bloom_detail', async function() {
        // eslint-disable-next-line no-undef
        const selectedIds = $(this).val() || [];
        selectedTBD.value = mergedSelectedIds([], selectedIds.map(Number));

        const { sortedNamaEngList } = await updateNamaEngList(selectedTBD.value, 'taksonomi-d');
        tbd_nama_eng.value = sortedNamaEngList.join(', ');

        // eslint-disable-next-line no-undef
        let heightTBD = $("span.select2-selection.taksonomi_bloom_detail").outerHeight();
        document.querySelector("textarea.taksonomi_bloom_detail").style.height = heightTBD+'px';
    })

    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.jenis_kk', async (event) => {
        showKKList.value = false

        const id = event.target.value;
        const { data } = await request(`/jenis-kerangka-kerja/${id}`);
        selectedJenisKK.value = id
		jenis_kk_nama_eng.value = data.nama_eng

        loading.value = true
        setTimeout(() => {
            kk_list.value = data.kerangka_kerja
            showKKList.value = true

            // Checked CPL KK
            selectedCheckedKK.value = listTempCPLKK.value.map(kk => kk.kk_id).sort((a, b) => a - b);

            // Set Height
            const el = document.querySelector("div.kkListContainer");
            if (el && kk_list.value.length > 0) el.style.height = '605px';
            else el.style.height = '360px';
        }, 250);
        loading.value = false

    })

    // eslint-disable-next-line no-undef
    $('body').on('change', 'select.jenis_kompetensi', async (event) => {
        showKompList.value = false

        const id = event.target.value;
        const { data } = await request(`/jenis-kompetensi/${id}`);
        selectedJenisKomp.value = id
		jenis_kompetensi_nama_eng.value = data.nama_eng

        loading.value = true
        setTimeout(() => {
            kompetensi_list.value = data.kompetensi
            showKompList.value = true

            // Checked CPL Kompetensi
            selectedCheckedKomp.value = listTempCPLKomp.value.map(kompetensi => kompetensi.kompetensi_id).sort((a, b) => a - b);

            // Set Height
            const el = document.querySelector("div.kompListContainer");
            if (el && kompetensi_list.value.length > 0) el.style.height = '605px';
            else el.style.height = '360px';
        }, 250);
        loading.value = false

    })

    // Untuk Taksonomi
    const mergedSelectedIds = (existingValues, newValues) => { const combinedSet = new Set([...existingValues, ...newValues]); return Array.from(combinedSet); };
    const updateNamaEngList = async (selectedIds, type) => {
        let namaEngList = new Map();
        const requests = selectedIds.map(async (id) => {
            const response = await request(`/${type}/${id}`);
            if (type === 'taksonomi-h') namaEngList.set(id, response.data.nama_eng + ' (' + response.data.taksonomi_h_kd + ')');
            else namaEngList.set(id, response.data.taksonomi_h_kd_fk + ' - ' + response.data.nama_eng);
            return response.data;
        });
        const results = await Promise.all(requests);
        const sortedNamaEngList = selectedIds.map(id => namaEngList.get(id));
        return { sortedNamaEngList, results };
    };

    renderCustomOption();
}

const showDataSelect = ref(false);
const listDataToShow = ref([]);
const onShowDataSelect = () => { showDataSelect.value = true; }
function generateDataToShow(namaIndValues) {
    return [
        { label: 'Rumpun Ilmu', value: namaIndValues ? namaIndValues[0]: '' },
        { label: 'Fakultas', value: namaIndValues ? namaIndValues[1]: '' },
        { label: 'Departemen', value: namaIndValues ? namaIndValues[2]: '' },
        { label: 'Program Studi', value: namaIndValues ? namaIndValues[3]: '' },
        { label: 'Jenjang', value: jenjang_nama_ind.value },
        { label: 'Kurikulum', value: namaIndValues ? namaIndValues[4]: '' },
    ];
}
listDataToShow.value = generateDataToShow()


// === Start Tabel CPL KK Kompetensi ===
const showFormCPLtoKK = ref(false)
const showFormCPLtoKomp = ref(false)
const btnShowFormCPLKkKompetensi = ((relationTo) => { 
    if (relationTo == 'kerangka-kerja') { showFormCPLtoKK.value = true; selectedCheckedKK.value = []; } 
    if (relationTo == 'kompetensi') { showFormCPLtoKomp.value = true; selectedCheckedKomp.value = []; } 
});
const btnHideFormCPLKkKompetensi = ((relationTo) => {
    Swal.fire({ 
        title: "Apakah anda yakin?", 
        text: "Jika anda menutup form ini, data yang telah terisi akan dihapus!",
        icon: "question",
        showCancelButton: true, 
        confirmButtonColor: relationTo === 'kerangka-kerja' ? "#3085d6" : "#7239ea",
        confirmButtonText: "Ya, tutup", 
        cancelButtonText: "Batal", 
    }).then((result) => { 
        if (result.isConfirmed) {
            if (relationTo === 'kerangka-kerja') {
                showFormCPLtoKK.value = false;
                showKKList.value = false;
                selectedJenisKK.value = null;
                jenis_kk_nama_eng.value = null;
            }
            if (relationTo === 'kompetensi') {
                showFormCPLtoKomp.value = false;
                showKompList.value = false;
                selectedJenisKomp.value = null;
                jenis_kompetensi_nama_eng.value = null;
            }
        } 
    });
});

// Kerangka Kerja
const showKKList = ref(false);
const cpl_kk_list = ref([]);
const listTempCPLKK = ref([]);
const listTempCPLKKDetail = ref([]);
const onSubmitTempCPLtoKK = async values => {
    listTempCPLKK.value = [];
    listTempCPLKKDetail.value = [];

    const jenis_kk_id = values.jenis_kk_id
    const kk_ids = selectedCheckedKK.value;
    // const cpl_kd = values.cpl_kd_hidden

    // const isDuplicateReal = kk_ids.some(kk_id => cpl_kk_list.value.some(dataR => (dataR.kk.some(val => val.kk_id === kk_id)) && dataR.cpl_kd === cpl_kd));
    // const isDuplicate = kk_ids.some(kk_id => listTempCPLKK.value.some(data => data.kk_id === kk_id));
    
    if (!jenis_kk_id || kk_ids.length === 0) {
        Swal.fire({ title: 'Silahkan memilih Jenis KK dan KK terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' });
        return;
    } 
    // if (isDuplicateReal) {
    //     Swal.fire({ title: 'Data yang dipilih sudah ada!', icon: 'warning', confirmButtonText: 'OK' });
    //     return;
    // }
    // if (isDuplicate) {
    //     Swal.fire({ title: 'Data yang dipilih sudah ada dalam list sementara!', icon: 'warning', confirmButtonText: 'OK' });
    //     return;
    // }
    Swal.fire({ title: 'Berhasil menambahkan data ke dalam list sementara', icon: 'success', confirmButtonText: 'OK' }).then((result) => {
        if (result.isConfirmed) {
            kk_ids.forEach(kk_id => { listTempCPLKK.value.push({ kk_id }); });

            showFormCPLtoKK.value = false;
            showKKList.value = false;
            selectedJenisKK.value = null;
            jenis_kk_nama_eng.value = null;
        }
    });

    // Detail KK for Tabel
    const detailData = kk_ids.map(async kk_id => {
        const response = await request(`/kerangka-kerja/${kk_id}`);
        return { kk_id, ...response.data };
    });
    const details = await Promise.all(detailData);
    details.sort((a, b) => a.kk_id - b.kk_id);
    details.forEach(detail => { listTempCPLKKDetail.value.push(detail); });
};
const groupedCPLKKDetail = computed(() => {
    return listTempCPLKKDetail.value.reduce((groups, item) => {
        const group = groups.find(g => g.jenis_kk_nama_ind === item.jenis_kk_nama_ind);
        if (group) {
            group.items.push(item);
        } else {
            groups.push({
                jenis_kk_nama_ind: item.jenis_kk_nama_ind,
                items: [item]
            });
        }
        return groups;
    }, []).sort((a, b) => a.jenis_kk_nama_ind.localeCompare(b.jenis_kk_nama_ind));
});

// Kompetensi
const showKompList = ref(false);
const cpl_kompetensi_list = ref([]);
const listTempCPLKomp = ref([]);
const listTempCPLKompDetail = ref([]);
const onSubmitTempCPLtoKomp = async values => {
    listTempCPLKomp.value = [];
    listTempCPLKompDetail.value = [];

    const jenis_kompetensi_id = values.jenis_kompetensi_id
    const kompetensi_ids = selectedCheckedKomp.value;
    // const cpl_kd = values.cpl_kd_hidden

    // const isDuplicateReal = kompetensi_ids.some(kompetensi_id => cpl_kompetensi_list.value.some(dataR => dataR.kompetensi_id === kompetensi_id && dataR.cpl_kd === cpl_kd));
    // const isDuplicate = kompetensi_ids.some(kompetensi_id => listTempCPLKomp.value.some(data => data.kompetensi_id === kompetensi_id));
    
    if (!jenis_kompetensi_id || kompetensi_ids.length === 0) {
        Swal.fire({ title: 'Silahkan memilih Jenis Kompetensi dan Kompetensi terlebih dahulu!', icon: 'error', confirmButtonText: 'OK' });
        return;
    } 
    // if (isDuplicateReal) {
    //     Swal.fire({ title: 'Data yang dipilih sudah ada!', icon: 'warning', confirmButtonText: 'OK' });
    //     return;
    // }
    // if (isDuplicate) {
    //     Swal.fire({ title: 'Data yang dipilih sudah ada dalam list sementara!', icon: 'warning', confirmButtonText: 'OK' });
    //     return;
    // }
    Swal.fire({ title: 'Berhasil menambahkan data ke dalam list sementara', icon: 'success', confirmButtonText: 'OK' }).then((result) => {
        if (result.isConfirmed) {
            kompetensi_ids.forEach(kompetensi_id => { listTempCPLKomp.value.push({ kompetensi_id }); });

            showFormCPLtoKomp.value = false;
            showKompList.value = false;
            selectedJenisKomp.value = null;
            jenis_kompetensi_nama_eng.value = null;
        }
    });

    // Detail Kompetensi for Tabel
    const detailData = kompetensi_ids.map(async kompetensi_id => {
        const response = await request(`/kompetensi/${kompetensi_id}`);
        return { kompetensi_id, ...response.data };
    });
    const details = await Promise.all(detailData)
    details.sort((a, b) => a.kompetensi_id - b.kompetensi_id);
    details.forEach(detail => { listTempCPLKompDetail.value.push(detail); });
};
const groupedCPLKompDetail = computed(() => {
    return listTempCPLKompDetail.value.reduce((groups, item) => {
        const group = groups.find(g => g.jenis_kompetensi_nama_ind === item.jenis_kompetensi_nama_ind);
        if (group) {
            group.items.push(item);
        } else {
            groups.push({
                jenis_kompetensi_nama_ind: item.jenis_kompetensi_nama_ind,
                items: [item]
            });
        }
        return groups;
    }, []).sort((a, b) => a.jenis_kompetensi_nama_ind.localeCompare(b.jenis_kompetensi_nama_ind));
});
// === End Tabel CPL KK Kompetensi ===

// Transition
const enter = (el) => {
    el.style.height = '0';
    setTimeout(() => { el.style.height = el.scrollHeight + 'px'; });
};
const leave = (el) => {
    el.style.height = el.scrollHeight + 'px';
    setTimeout(() => { el.style.height = '0'; });
};

async function fetchData(relationTo) {
    loading.value = true;

    try {
        if (relationTo === 'kk') {
            const getDataJenisKK = await request(`/jenis-kerangka-kerja`)
            jenis_kk_list.value = getDataJenisKK.data.data.sort((a, b) => a.nama_ind.localeCompare(b.nama_ind));

            const getDataCPLKK = await request('/cpl-kk')
            cpl_kk_list.value = getDataCPLKK.data.data
        } 
        if (relationTo === 'kompetensi') {
            const getDataJenisKomp = await request(`/jenis-kompetensi`)
            jenis_kompetensi_list.value = getDataJenisKomp.data.data.sort((a, b) => a.nama_ind.localeCompare(b.nama_ind));

            const getDataCPLKomp = await request('/cpl-kompetensi')
            cpl_kompetensi_list.value = getDataCPLKomp.data.data
        }
    } catch (error) {
        console.error('Error:', error);
    } finally {
		loading.value = false
	}
}

onMounted(async () => {
    try {
        // eslint-disable-next-line no-undef
        $('body').on('change', 'select.ri, select.fakultas, select.departemen, select.prodi, select.kurikulum', () => {
            // eslint-disable-next-line no-undef
            const namaIndValues = $('select.ri, select.fakultas, select.departemen, select.prodi, select.kurikulum').map(function() {
                // eslint-disable-next-line no-undef
                return $(this).find('option:selected').data('nama_ind');
            }).get();
            listDataToShow.value = generateDataToShow(namaIndValues);
        })

        const response = await request(`/ri`)
		ri_list.value = response.data.data

        const responseTB = await request(`/taksonomi-h?per_page=-1`)
		tb_list.value = responseTB.data.data

        // eslint-disable-next-line no-undef
        $('button[type="submit"]').click(() => {
            const validateSelect = (selector) => {
                // eslint-disable-next-line no-undef
                if ($(`select.${selector}`).val() == '') {
                    // eslint-disable-next-line no-undef
                    $(`select.${selector}.select2-hidden-accessible`).addClass('is-invalid');
                    // eslint-disable-next-line no-undef
                    $('span').find(`.select2-selection.${selector}`).addClass('is-invalid');
                } else {
                    // eslint-disable-next-line no-undef
                    $(`select.${selector}.select2-hidden-accessible`).removeClass('is-invalid');
                    // eslint-disable-next-line no-undef
                    $('span').find(`.select2-selection.${selector}`).removeClass('is-invalid');
                }
            };
            validateSelect('kurikulum');
            validateSelect('taksonomi_bloom_detail');

            const validateInput = (name) => {
                // eslint-disable-next-line no-undef
                if ($(`input[name=${name}]`).val() == '') $(`input[name=${name}]`).addClass('is-invalid');
                // eslint-disable-next-line no-undef
                else $(`input[name=${name}]`).removeClass('is-invalid');
            };
            validateInput('cpl_kd');
            validateInput('nama_ind');
            validateInput('nama_eng');
        });

        // eslint-disable-next-line no-undef
        $('input.cpl_kd').on('input', function () { hiddenValueCPLKD.value = this.value; });
        // eslint-disable-next-line no-undef
        // $('input.cpl_nilai').on('input', function () { this.value = this.value.replace(/[^\d.]/,'') });

        await fetchData('kk');
        await fetchData('kompetensi');

        await nextTick(() => { initializeSelect2(); });
		filteredSelect();
    } catch (error) {
		console.error('Error fetching data:', error)
	}
})

// eslint-disable-next-line no-undef
const initializeSelect2 = () => { $("select").select2(); };

watch(showFormCPLtoKK, (newVal) => { if (newVal) nextTick(() => { initializeSelect2(); renderCustomOption(); }); });
watch(showFormCPLtoKomp, (newVal) => { if (newVal) nextTick(() => { initializeSelect2(); renderCustomOption(); }); });
</script>

<style scoped>
.slide-down { animation: slideDown 5s ease; }
@keyframes slideDown {
    0% { transform: translateY(-5%); opacity: 0; overflow: hidden; }
    5% { transform: translateY(0); opacity: 1; overflow: hidden; }
}

.slide-enter-active, .slide-leave-active { transition: height 0.7s ease; overflow: hidden; }
.slide-enter-from, .slide-leave-to { height: 0; overflow: hidden; }

.fade-enter-active, .fade-leave-active { transition: opacity 0.7s; }
.fade-enter, .fade-leave-to { opacity: 0; }

.btn-close { background: none; width:auto; cursor: pointer; }

.scrolling { height: 300px; overflow-y: auto; }
.scrolling.kk table.table-row-bordered tr:hover { background-color: var(--kt-primary-light); }
.scrolling.komp table.table-row-bordered tr:hover { background-color: var(--kt-info-light); }
</style>