<template>
	<div>
		<breadcrumb-content menu="Master Data" subMenu="Gelar" />
		<div class="card mb-5 mb-xxl-8 placeholder-glow">
			<div class="pb-10">
				<div class="card-body pt-9 pb-0">
					<h1 class="anchor fw-bold mb-5">Detail Gelar</h1>
					<div class="p-5">
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Sebutan Gelar"
							labelEng="Title designation"
							columnName="sebutan"
							:valueInd="dataGelar.sebutan_ind"
							:valueEng="dataGelar.sebutan_eng"
							mode="show"
						/>
						<FormInput
							labelInd="Singkatan Gelar"
							labelEng="Degree Abbreviation"
							columnName="singkatan"
							:valueInd="dataGelar.singkatan_ind"
							:valueEng="dataGelar.singkatan_eng"
							mode="show"
						/>

						<div class="mt-10">
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'

import FormInput from '@/components/FormInput.vue'

const loading = ref(true)
const router = useRouter()
const dataGelar = ref({})
const gelar_id = router.currentRoute.value.params.id

const redirectToDataList = () => router.push({ name: 'Gelar' })

onMounted(async () => {
	try { dataGelar.value = (await request(`/gelar/${gelar_id}`)).data } 
	catch (error) { console.error('Error fetching data:', error) } 
	finally { loading.value = false }
})
</script>