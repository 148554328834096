<template>
	<div class="table-responsive">
		<DataTable
			ref="tableRef"
			id="table"
			:class="`table table-row-bordered gy-5 ${align ? align : ''}`"
			:options="options"
		>
			<thead>
				<tr>
					<th v-for="(column, index) in columns" :key="index" :class="{ 'text-center': column.data === 'actions' }">
						{{ column.title }}
					</th>
				</tr>
			</thead>
		</DataTable>
	</div>
</template>

<script setup>
import { defineProps, ref, onMounted, onBeforeUnmount, defineExpose } from 'vue'
import DataTable from 'datatables.net-vue3'
import request from '@/utils/request'

let dt
const tableRef = ref()

const props = defineProps({
	align: {
		type: String,
		default: 'align-middle'
	},
	columns: {
		type: Array,
		required: true
	},
	url: {
		type: String,
		required: true
	},
	primaryKey: {
		type: String,
		required: true
	},
	show: Function,
	edit: Function,
	delete: Function,

	customBtnAction: {
        type: Function,
        required: false,
        default: null
    },
	dataName: { type: String, required: false },

	filterName: String,
	filterData: [String, Number]
})

const fetchData = async (data, callback) => {
	try {
		// Convert columns array to string
		const columnsParam = data.columns
			.map((column, index) => {
				const columnParams = {
					[`columns[${index}][data]`]: column.data,
					[`columns[${index}][name]`]: column.name,
					[`columns[${index}][searchable]`]: column.searchable,
					[`columns[${index}][orderable]`]: column.orderable,
					[`columns[${index}][search][value]`]: column.search.value,
					[`columns[${index}][search][regex]`]: column.search.regex
				}
				return Object.entries(columnParams)
					.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
					.join('&')
			})
			.join('&')

		// Convert order array to string
		const orderParam = data.order
			.map((order, index) => {
				return `order[${index}][column]=${order.column}&order[${index}][dir]=${order.dir}`
			})
			.join('&')

		// Construct other parameters
		const otherParams = [
			`draw=${data.draw}`,
			`start=${data.start}`,
			`length=${data.length}`,
			`search[value]=${encodeURIComponent(data.search.value)}`,
			`search[regex]=${data.search.regex}`
		].join('&')

		// Combine all parameters into the final URL string
		const params = 
			(props.filterName && props.filterData)
				? `${columnsParam}&${orderParam}&${otherParams}&${props.filterName}=${props.filterData}`
				: `${columnsParam}&${orderParam}&${otherParams}`
		const response = await request(`${props.url}?${params}`)

		callback(response.data)
	} catch (error) {
		console.error('Error fetching data:', error)
	}
}

const options = {
	serverSide: true,
	ajax: fetchData,
	columns: props.columns.map(column => {
		const columnOptions = {
			title: column.title,
			data: column.data,
			searchable: column.searchable,
			render: (data, type, row) => {
				if (props.customBtnAction && column.data === 'actions') {
					if (row[props.dataName]) return props.customBtnAction(row[props.primaryKey], row[props.dataName])
                    else return props.customBtnAction(row[props.primaryKey])
                }
				if (typeof column.render === 'function') {
					return column.render(data, type, row)
				}
				if (column.data === 'actions') {
					let actionBtn = '<div class="actions text-center">';

					if (props.show !== undefined) {
						actionBtn += `<button class="btn btn-light-primary btn-sm btn-show-datatable m-1" data-toggle="tooltip" data-placement="top" title="Detail Data" data-id="${row[props.primaryKey]}">
										<i class="fa fa-search p-0 fs-5"></i>
									</button>`
					}

					if (props.edit !== undefined) {
						actionBtn += `<button class="btn btn-light-primary btn-sm btn-edit-datatable m-1" data-toggle="tooltip" data-placement="top" title="Edit Data" data-id="${row[props.primaryKey]}">
										<i class="fa fa-edit p-0 fs-5"></i>
									</button>`
					}

					if (props.delete !== undefined) {
						actionBtn += `<button class="btn btn-light-primary btn-sm btn-delete-datatable m-1" data-toggle="tooltip" data-placement="top" title="Delete Data" data-id="${row[props.primaryKey]}">
								<i class="fa fa-trash p-0 fs-5"></i>
							</button>`
					}

					actionBtn += '</div>'

					return actionBtn
				} else {
					return data
				}
			}
		}

		if (column.width) {
			columnOptions.width = column.width
		}

		if (column.data === 'actions') {
			columnOptions.width = '20%'
		}

		return columnOptions
	})
}

// Event delegation to handle button clicks
const handleButtonClick = async (event) => {
	const target = event.target
	const button = target.closest('.btn-show-datatable, .btn-edit-datatable, .btn-delete-datatable')

	if (button) {
		event.stopPropagation();
		event.preventDefault();

		if (button.matches('.btn-show-datatable')) {
			props.show(button.dataset.id)
		} else if (button.matches('.btn-edit-datatable')) {
			props.edit(button.dataset.id)
		} else if (button.matches('.btn-delete-datatable')) {
			await props.delete(button.dataset.id).then(() => reload())
		}
	}
}

// reload datatable when vue use component ssdatatable
const reload = () => { dt.ajax.reload() };
defineExpose({ reload });

onMounted(() => {
	dt = tableRef.value.dt
	document.addEventListener('click', handleButtonClick);
});

onBeforeUnmount(() => {
	document.removeEventListener('click', handleButtonClick);
});
</script>

<style>
div.dataTables_wrapper div.dataTables_length { display: flex; padding: 0; }
div.dataTables_wrapper div.dataTables_length label { display: flex; align-items: center; margin: 0 0 20px 0; }
div.dataTables_wrapper div.dataTables_length label select.form-select { margin: 0 5px 0 5px; }

div.dataTables_wrapper div.dataTables_filter { display: flex; padding: 0; justify-content: flex-end; }
div.dataTables_wrapper div.dataTables_filter label { display: flex; align-items: center; margin: 0 0 20px 0; }
div.dataTables_wrapper div.dataTables_filter label input.form-control { margin: 0 0 0 10px; }

div.dataTables_wrapper div.dataTables_info { padding: 25px 0 10px 0; }
div.dataTables_wrapper div.dataTables_paginate { display: flex; justify-content: flex-end; padding: 20px 0 10px 0; }

td.dataTables_empty { text-align: center; }
</style>