<template>
    <LoadingOverlay :loading="loading" :stylePositionFixed="true"/>
    <breadcrumb-content menu="Laporan BRP" subMenu="Cetak BRP" />

    <div class="card mb-5 mb-xxl-8">
        <div class="card-body p-20">
            <div id="contentToExport" class="row" ref="contentToExport">
                <!-- Cover -->
                <div class="col-12 text-center pt-20">
                    <img :src="base64Logo" class="mb-20" :alt="`Logo ${dataPT?.nama_ind}`" width="150">
                    
                    <h1 style="padding-top: 4rem;">BUKU RANCANGAN PENGAJARAN</h1>
                    <h2>{{ dataMKKurikulum?.matakuliah_nama_ind }}</h2>
            
                    <h2 style="padding: 10rem 0;">oleh</h2>
            
                    <!-- Dosen Pengembang BRP -->
                    <template v-for="dosen in dataDosenPengembang" :key="dosen.dosen_pengembang_id">
                        <h2>{{ dosen.display_name }}</h2>
                    </template>
            
                    <!-- Info Umum -->
                    <h2 style="padding-top: 10rem;">
                        Program Studi {{ dataMKKurikulum?.prodi_nama_ind }} <br>
                        {{ dataMKKurikulum?.fakultas_nama_ind }} <br>
                        Universitas Indonesia <br>
                        Depok, {{ formatDate(dataBRP?.created_at) }}
                    </h2>
                </div>
                
                <!-- Informasi Umum -->
                <div class="col-12 mt-20">
                    <table class="table gs-0 border border-dark border-1" id="general-information-table">
                        <!-- <thead>
                            <tr class="border border-dark border-1 bg-secondary">
                                <td :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    <span class="fs-3 fw-bold px-3">1. INFORMASI UMUM</span>
                                </td>
                            </tr>
                        </thead> -->
                        <tbody>
                            <tr class="border border-dark border-1 text-center bg-light">
                                <td class="p-3" style="width: 17%;"> <img :src="base64Logo" :alt="`Logo ${dataPT?.nama_ind}`" width="100"> </td>
                                <td class="p-3" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    <h2 class="m-0 p-0" style="text-transform: uppercase;">
                                        Universitas Indonesia <br>
                                        {{ dataMKKurikulum?.fakultas_nama_ind }} <br>
                                        Program Studi {{ dataMKKurikulum?.prodi_nama_ind }}
                                    </h2>
                                </td>
                            </tr>
                            <tr class="border border-dark border-1 text-center">
                                <td class="p-3" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    Tanggal Penyusunan: {{ formatDate(dataBRP?.waktu_penyusunan, 'with-day') }}
                                </td>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Mata Kuliah (MK)</th>
                                <td class="text-center p-3 w-25">{{ dataMKKurikulum?.matakuliah_nama_ind }}</td>
                                <th class="text-center fw-bold bg-light p-3" :colspan="1 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">MK yang menjadi prasyarat</th>
                                <th class="text-center fw-bold bg-light p-3">Integrasi Antar MK</th>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Kode</th>
                                <td class="text-center p-3">{{ dataMKKurikulum?.mk_kurikulum_kd }}</td>
                                <td class="text-center p-3" rowspan="2" :colspan="1 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    <template v-if="dataMKKurikulum?.mk_prasyarat?.length > 0">
                                        <template v-for="(mkPrasyarat, index) in dataMKKurikulum?.mk_prasyarat" :key="index">
                                            {{ mkPrasyarat?.matakuliah_nama_ind }} <br>
                                        </template>
                                    </template>
                                    <template v-else>
                                        Belum ada data
                                    </template>
                                </td>
                                <td class="text-center p-3" rowspan="2">{{ dataBRP?.integrasi_matakuliah }}</td>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Rumpun MK (RMK)</th>
                                <td class="text-center p-3">{{ dataMKKurikulum?.rumpun_matakuliah_nama_ind }}</td>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Bobot (SKS)</th>
                                <td class="text-center p-3">{{ dataMKKurikulum?.sks_teori }} SKS</td>
                                <th class="text-center fw-bold bg-light p-3" :colspan="(dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">Dosen Pengembang BRP</th>
                                <th class="text-center fw-bold bg-light p-3">Koordinator RMK</th>
                                <th class="text-center fw-bold bg-light p-3">Ketua Prodi</th>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Semester</th>
                                <td class="text-center p-3">{{ dataMKKurikulum?.semester_no }}</td>
                                <template v-if="dataDosenPengembang?.length > 0">
                                    <template v-for="(dosen, index) in dataDosenPengembang" :key="index">
                                        <td class="text-center p-3" rowspan="2">{{ dosen?.display_name }} <br></td>
                                    </template>
                                </template>
                                <template v-else>
                                    <td class="text-center p-3" rowspan="2">Belum Ada Data</td>
                                </template>
                                <td class="text-center p-3" rowspan="2">{{ dataBRP?.koordinator_rmk ? dataBRP?.koordinator_rmk : 'Belum ada data' }}</td>
                                <td class="text-center p-3" rowspan="2">{{ dataBRP?.ketua_prodi ? dataBRP?.ketua_prodi : 'Belum ada data' }}</td>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Dosen Pengampu</th>
                                <td class="text-center p-3">
                                    <template v-if="dataMKKurikulum?.dosen_pengampu?.length > 0">
                                        <template v-for="(dosen, index) in dataMKKurikulum?.dosen_pengampu" :key="index">
                                            {{ dosen?.display_name }} <br>
                                        </template>
                                    </template>
                                    <template v-else>
                                        Belum Ada Data
                                    </template>
                                </td>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Deskripsi Mata Kuliah</th>
                                <td class="p-3" style="text-align: justify;" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    {{ dataBRP?.deskripsi_matakuliah }}
                                </td>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3">Tautan Kelas Daring</th>
                                <td class="p-3" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    {{ dataBRP?.tautan_kls_daring }}
                                </td>
                            </tr>
                            <tr class="text-center">
                                <td class="bg-dark p-5" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)"></td>
                            </tr>
                        </tbody>

                        <!-- CPL-PRODI -->
                        <template v-if="dataMKKurikulum?.mk_cpl?.length > 0">
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3" style="text-align: justify;" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    CPL-PRODI (Capaian Pembelajaran Lulusan Program Studi) yang dibebankan kepada MK
                                </th>
                            </tr>
                            <template v-for="(cpl, index) in dataMKKurikulum?.mk_cpl.slice().sort((a, b) => a.cpl_kd.localeCompare(b.cpl_kd))" :key="index">
                                <tr class="border border-dark border-1">
                                    <th class="p-3">{{ cpl?.cpl_kd }}</th>
                                    <td class="p-3" style="text-align: justify;" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">{{ cpl?.name_ind }} <br> {{ cpl?.deskripsi_ind }}</td>
                                </tr>
                            </template>
                        </template>

                        <!-- CPMK -->
                        <template v-if="dataCPMK?.length > 0">
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3" style="text-align: justify;" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    Capaian Pembelajaran Mata Kuliah (CPMK)
                                </th>
                            </tr>
                            <template v-for="(cpmk, index) in dataCPMK.slice().sort((a, b) => a.no.localeCompare(b.no))" :key="index">
                                <tr class="border border-dark border-1">
                                    <th class="p-3">{{ cpmk?.no }}</th>
                                    <td class="p-3" style="text-align: justify;" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">{{ cpmk?.keterangan_ind }}</td>
                                </tr>
                            </template>
                        </template>
                        
                        <!-- Sub-CPMK -->
                        <template v-if="dataSubCPMK?.length > 0">
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3" style="text-align: justify;" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    Sub-CPMK
                                </th>
                            </tr>
                            <template v-for="(sub_cpmk, index) in dataSubCPMK.slice().sort((a, b) => a.no.localeCompare(b.no))" :key="index">
                                <tr class="border border-dark border-1">
                                    <th class="p-3">{{ sub_cpmk?.no }}</th>
                                    <td class="p-3" style="text-align: justify;" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">{{ sub_cpmk?.keterangan_ind }}</td>
                                </tr>
                            </template>
                        </template>

                        <!-- Korelasi CPMK Terhadap Sub-CPMK -->
                        <template v-if="dataKorelasi?.length > 0">
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3" style="text-align: justify;" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    Korelasi CPMK terhadap Sub-CPMK
                                </th>
                            </tr>
                            <tr class="border border-dark border-1">
                                <th class="text-center p-3"></th>
                                <th v-for="(cpmk, index) in dataSubCPMK" :key="index" class="text-center p-3" :colspan="1 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">{{ cpmk.no }}</th>
                            </tr>
                            <tr class="border border-dark border-1" v-for="(cpmk, index) in dataCPMK" :key="index">
                                <td class="p-3">{{ cpmk.no }}</td>
                                <td v-for="(sub_cpmk, index) in dataSubCPMK" :key="index" :colspan="1 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    <div class="form-check form-check-custom form-check-success form-check-solid form-check-sm d-flex justify-content-center">
                                        <input type="checkbox" class="form-check-input border border-dark border-1" :checked="dataKorelasi.find((korelasi) => ((korelasi.cpmk_id_fk == cpmk.cpmk_id) && (korelasi.sub_cpmk_id_fk == sub_cpmk.sub_cpmk_id)) )?.is_korelasi" disabled>
                                    </div>
                                </td>
                            </tr>
                        </template>

                        <tr class="text-center">
                            <td class="bg-dark p-5" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)"></td>
                        </tr>

                        <template v-if="dataMKKurikulum?.mk_cpl?.length > 0">
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3" style="text-align: justify;" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    Bahan Kajian: Materi pembelajaran
                                </th>
                            </tr>
                            <template v-for="(cpl, index) in dataMKKurikulum?.mk_cpl.slice().sort((a, b) => a.cpl_kd.localeCompare(b.cpl_kd))" :key="index">
                                <template v-for="(bk, bkIdx) in cpl.bahan_kajians" :key="bkIdx">
                                    <tr class="border border-dark border-1">
                                        <td class="p-3">{{ bk?.nama_ind }}</td>
                                        <td class="p-3" style="text-align: justify;" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">{{ bk?.deskripsi_ind }}</td>
                                    </tr>
                                </template>
                            </template>
                        </template>

                        <template v-if="dataTinjauanPustaka?.length > 0">
                            <tr class="border border-dark border-1">
                                <th class="fw-bold bg-light p-3" style="text-align: justify;" :colspan="4 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                    Daftar Pustaka [tautan materi/buku jika tersedia online]
                                </th>
                            </tr>
                            <!-- <template v-for="(tinjauan_pustaka, index) in dataTinjauanPustaka" :key="index">
                                <tr class="border border-dark border-1">
                                    <td class="p-3" style="text-align: justify;">
                                        {{ tinjauan_pustaka?.kat_tinjauan_pustaka_nama_ind }}
                                    </td>
                                    <td class="p-3" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">{{ tinjauan_pustaka?.penulis_ind || '-' }} ({{ tinjauan_pustaka?.tahun_terbit }}). 
                                        <i class="text-dark p-0">{{ tinjauan_pustaka?.judul_ind }}</i>. {{ tinjauan_pustaka?.penerbit_ind }}.
                                    </td>
                                </tr>
                            </template> -->
                            <template v-for="(group, index) in groupedTinjauanPustaka" :key="index">
                                <tr class="border border-dark border-1">
                                    <td class="p-3" style="text-align: justify;">
                                        {{ group.category }}
                                    </td>
                                    <td class="p-3" :colspan="3 + (dataDosenPengembang.length > 0 ? dataDosenPengembang.length : 1)">
                                        <template v-for="(item, idx) in group.items" :key="idx">
                                            {{ idx + 1 }}. {{ item.penulis_ind || '-' }} ({{ item.tahun_terbit }}). 
                                            <i class="text-dark p-0">{{ item.judul_ind }}</i>. {{ item.penerbit_ind }}.
                                            <span v-if="idx < group.items.length - 1"><br> </span>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </table>
                </div>

                <!-- Rencana Pembelajaran -->
                <div class="col-12 mt-10">
                    <table class="table gs-0 table-hover border border-secondary border-1" id="learning-plan-table">
                        <thead>
                            <tr class="border border-dark border-1 bg-secondary">
                                <td colspan="8">
                                    <span class="fs-3 fw-bold px-3">RENCANA PEMBELAJARAN</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="border border-dark border-1">
                            <tr class="text-center fw-bold bg-light px-3" style="vertical-align: middle;">
                                <th class="px-3" rowspan="3" style="padding-left: 10px;">Minggu Ke- / Topik</th>
                                <th class="px-3" rowspan="3">Sub-CPMK</th>
                                <th class="px-3" colspan="2" rowspan="2">Penilaian</th>
                                <th class="px-3" colspan="2">Metode Pembelajaran *; <br /> Pengalaman Belajar dalam modal Asinkron dan Sinkron (O - L - U)**</th>
                                <th class="px-3" rowspan="3">Materi Pembelajaran <br /> [Rujukan]</th>
                                <th class="px-3" rowspan="3">Bobot Penerapan <br /> (%)</th>
                            </tr>
                            <tr class="text-center fw-bold bg-light px-3" style="vertical-align: middle;">
                                <th class="px-3" colspan="2">[Estimasi Waktu]</th>
                            </tr>
                            <tr class="text-center fw-bold bg-light px-3" style="vertical-align: middle;">
                                <th class="px-3">Indikator</th>
                                <th class="px-3">Teknik dan Kriteria</th>
                                <th class="px-3">Daring (Online)</th>
                                <th class="px-3">Luring (Offline)</th>
                            </tr>
    
                            <tr v-for="(rencana_bljr, index) in sortedRencanaBelajar" :key="index" style="word-break: break-all; text-align: justify;">
                                <td class="px-3" style="width: 8%;">{{ rencana_bljr?.minggu_topik_ind }}</td>
                                <td class="px-3" style="width: 10%;">{{ formatedSubCPMK(rencana_bljr?.sub_cpmk) }}</td>
                                <td class="px-3" style="width: 15%;">{{ rencana_bljr?.penilaian_indikator_ind }}</td>
                                <td class="px-3" style="width: 15%;">{{ rencana_bljr?.penilaian_teknik_kriteria_ind }}</td>
                                <td class="px-3" style="width: 15%;">{{ rencana_bljr?.daring_ind }}</td>
                                <td class="px-3" style="width: 15%;">{{ rencana_bljr?.luring_ind }}</td>
                                <td class="px-3" style="width: 14%;">{{ rencana_bljr?.materi_bljr_ind }}</td>
                                <td class="text-center px-3" style="width: 8%;">{{ rencana_bljr?.bobot_penerapan }}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Rancangan Tugas & Latihan -->
                <div class="col-12 mt-10">
                    <table class="table gs-0 table-hover border border-secondary border-1" id="task-plan-table">
                        <thead>
                            <tr class="border border-dark border-1 bg-secondary">
                                <td colspan="8">
                                    <span class="fs-3 fw-bold px-3">RANCANGAN TUGAS & LATIHAN</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="border border-dark border-1">
                            <tr class="text-center fw-bold bg-light px-3" style="vertical-align: middle;">
                                <th class="px-3" style="padding-left: 10px;">Minggu Ke- / Topik</th>
                                <th class="px-3">Nama Tugas</th>
                                <th class="px-3">Sub-CPMK</th>
                                <th class="px-3">Penugasan</th>
                                <th class="px-3">Ruang Lingkup</th>
                                <th class="px-3">Cara Pengerjaan</th>
                                <th class="px-3">Batas Waktu</th>
                                <th class="px-3">Luaran Tugas yang Dihasilkan</th>
                            </tr>
    
                            <tr v-for="(rancangan_tugas, index) in sortedRancanganTugas" :key="index" style="word-break: break-all; text-align: justify;">
                                <td class="px-3" style="width: 8%;">{{ rancangan_tugas?.minggu_topik_ind }}</td>
                                <td class="px-3" style="width: 12%;">{{ rancangan_tugas?.nama_tugas_ind }}</td>
                                <td class="px-3" style="width: 10%;">{{ formatedSubCPMK(rancangan_tugas?.sub_cpmk) }}</td>
                                <td class="px-3" style="width: 14%;">{{ rancangan_tugas?.penugasan_ind }}</td>
                                <td class="px-3" style="width: 14%;">{{ rancangan_tugas?.ruang_lingkup_ind }}</td>
                                <td class="px-3" style="width: 14%;">{{ rancangan_tugas?.cara_pengerjaan_ind }}</td>
                                <td class="px-3" style="width: 14%;">{{ rancangan_tugas?.batas_waktu_ind }}</td>
                                <td class="px-3" style="width: 14%;">{{ rancangan_tugas?.hasil_tugas_ind }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- Kriteria Penilaian (Evaluasi Hasil Pembelajaran) -->
                <div class="col-12 mt-10">
                    <table class="table gs-0 table-hover border border-secondary border-1" id="assessment-criteria-table">
                        <thead>
                            <tr class="border border-dark border-1 bg-secondary">
                                <td colspan="6">
                                    <span class="fs-3 fw-bold px-3">KRITERIA PENILAIAN (EVALUASI HASIL PEMBELAJARAN)</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="border border-dark border-1">
                            <tr class="text-center fw-bold bg-light px-3" style="vertical-align: middle;">
                                <th class="px-3" rowspan="2">Bentuk Evaluasi</th>
                                <th class="px-3" rowspan="2">Sub-CPMK</th>
                                <th class="px-3" colspan="2">Instrumen Penilaian Frekuensi</th>
                                <th class="px-3" rowspan="2">Tagihan (Bukti)</th>
                                <th class="px-3" rowspan="2">Bobot Penilaian <br /> (%)</th>
                            </tr>
                            <tr class="text-center fw-bold bg-light px-3 border border-dark border-1" style="vertical-align: middle;">
                                <th class="px-3">Formatif</th>
                                <th class="px-3">Sumatif</th>
                            </tr>
    
                            <tr v-for="(kriteria_penilaian, index) in sortedKriteriaPenilaian" :key="index" class="border border-dark border-1" style="word-break: break-all; text-align: justify;">
                                <td class="px-3" style="width: 25%;"><strong>{{ kriteria_penilaian?.bentuk_evaluasi_detail?.bentuk_evaluasi_nama_ind }}</strong> - {{ kriteria_penilaian?.bentuk_evaluasi_detail?.deskripsi_ind }}</td>
                                <td class="px-3" style="width: 12%;">{{ formatedSubCPMK(kriteria_penilaian?.kriteriapenilaian_subcpmk, true) }}</td>
                                <td class="px-3" style="width: 18%;">{{ kriteria_penilaian?.formatif_ind }}</td>
                                <td class="px-3" style="width: 18%;">{{ kriteria_penilaian?.sumatif_ind }}</td>
                                <td class="px-3" style="width: 18%;">{{ kriteria_penilaian?.tagihan_ind }}</td>
                                <td class="text-center px-3" style="width: 9%;">{{ kriteria_penilaian?.bobot_penilaian }}%</td>
                            </tr>
                        </tbody>
                        <tfoot class="border border-dark border-1">
                            <tr class="fw-bold bg-light">
                                <td class="px-3" colspan="5"><p class="text-end m-0">TOTAL</p></td>
                                <td class="text-center px-3">{{ totalBobotPenilaian }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <!-- Rubrik Penilaian -->
                <div class="col-12 mt-10">
                    <table class="table gs-0 border border-secondary border-1" id="assessment-rubric-table">
                        <thead>
                            <tr class="border border-dark border-1 bg-secondary">
                                <td>
                                    <span class="fs-3 fw-bold px-3">RUBRIK PENILAIAN</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="border border-dark border-1">
                            <template v-for="(rubrik, index) in sortedRubrik" :key="index">
                                <tr>
                                    <td class="bg-light px-3 fs-5 fw-bold">{{ index + 1 }}. {{ rubrik?.nama }}</td>
                                </tr>
                                <tr>
                                    <td class="p-5 text-center">
                                        <a :href="rubrik?.file_src" class="d-flex justify-content-center" target="_blank">
                                            <div style="border:1px solid #c1c1c1; border-radius:5px; padding:3px; width: 350px; height: auto;">
                                                <img :src="rubrik.base64" width="100%" height="100%" style="border:1px solid #c1c1c1; border-radius:5px;" />
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>

                <!-- Lampiran -->
                <div class="col-12 mt-10">
                    <table class="table gs-0 table-hover border border-secondary border-1" id="attachment-table">
                        <thead>
                            <tr class="border border-dark border-1 bg-secondary">
                                <td>
                                    <span class="fs-3 fw-bold px-3">LAMPIRAN</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody class="border border-dark border-1">
                            <template v-for="(lampiran, index) in sortedLampiran" :key="index">
                                <tr>
                                    <td class="bg-light px-3 fs-5 fw-bold">{{ index + 1 }}. {{ lampiran?.nama }}</td>
                                </tr>
                                <tr>
                                    <td class="p-5 text-center">
                                        <a :href="lampiran?.file_src" class="d-flex justify-content-center" target="_blank">
                                            <div style="border:1px solid #c1c1c1; border-radius:5px; padding:3px; width: 350px; height: auto;">
                                                <img :src="lampiran.base64" width="100%" height="100%" style="border:1px solid #c1c1c1; border-radius:5px;" />
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer" style="padding: 1rem 2.25rem;">
            <button @click="redirectToIndex" class="border border-dark border-1 btn btn-light-dark btn-sm">Kembali Ke Halaman Sebelumnya</button>
        </div>
    </div>

    <!-- -- Show Modal -- -->
    <button class="btn btn-primary app-layout-builder-toggle" title="Cetak BRP" data-bs-toggle="tooltip" data-bs-placement="left" @click="showModal">
        <i class="fa fa-print fs-4 me-1"></i>
        Cetak BRP
    </button>

    <ModalForm id="modal_form" :close_func="closeModal" :custom_classes="['modal-lg']" :modalHidden="state.modalHidden" :modal_center="true">
        <template #title>
            <i class="fas fa-info-circle fs-2 text-muted mx-2"></i>
            <span class="fs-3 fw-bold text-muted">KONFIRMASI CETAK BRP</span>
        </template>

        <template #body>
            <div class="text-center">
                <div class="fs-3 text-dark pb-5">
                    Pilih <a class="fw-bold me-1">Cetak BRP</a> jika anda ingin mencetak laporan brp, pilih <a class="fw-bold me-1">Tidak Sekarang / Tanda Silang</a> jika anda tidak ingin mencetak laporan brp.
                </div>

                <div class="modal-footer d-flex flex-center flex-wrap pt-5 p-0">
                    <button type="button" class="btn btn-outline btn-outline-primary btn-active-primary m-2" data-bs-dismiss="modal">Tidak Sekarang</button>
                    <button type="button" class="btn btn-primary m-2" @click="printBRP">Cetak BRP</button>
                </div>
            </div>
        </template>
    </ModalForm>
    <!-- -- End Show Modal -- -->
</template>

<script setup>
import Swal from 'sweetalert2';

import { ref, onMounted, computed, reactive  } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import request from '@/utils/request'
import ModalForm from "@/components/ModalForm.vue";
import { Modal } from 'bootstrap';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const router = useRouter()
const loading = ref(false)
const brp_id = router.currentRoute.value.params.brp_id

const redirectToIndex = () => router.push({ name: 'CetakBRP' })
const formatDate = (dateString, day = null) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long' };
    
    if (day !== null) options.day = 'numeric';

    return date.toLocaleDateString('id-ID', options);
};

const groupedTinjauanPustaka = computed(() => {
    const grouped = dataTinjauanPustaka.value.reduce((acc, item) => {
        const category = item.kat_tinjauan_pustaka_nama_ind;
        if (!acc[category]) {
            acc[category] = [];
        }
        acc[category].push(item);
        return acc;
    }, {});

    return Object.keys(grouped).map(category => ({
        category,
        items: grouped[category]
    }));
});
const formatedSubCPMK = (subCpmk, is_kriteria = false) => {
    if (is_kriteria) {
        const sortedSubCpmk = subCpmk.slice().sort((a, b) => {
            return (parseInt(a.sub_cpmk.no) || 0) - (parseInt(b.sub_cpmk.no) || 0);
        });
        return sortedSubCpmk.map(item => `${item.sub_cpmk?.no || ''}`).join(', ');
    } else {
        const sortedSubCpmk = subCpmk.slice().sort((a, b) => {
            return (parseInt(a.no) || 0) - (parseInt(b.no) || 0);
        });
        return sortedSubCpmk.map(item => `${item?.no || ''}`).join(', ');
    }
};
const totalBobotPenilaian = computed(() => {
    let total = dataKriteriaPenilaian.value.reduce((accum, current) => {
        return accum + (parseFloat(current.bobot_penilaian) || 0);
    }, 0);
    
    return total.toFixed(2) + '%';
});

const sortedRencanaBelajar = computed(() => { return dataRencanaBelajar.value.slice().sort((a, b) => a.no_urut - b.no_urut); });
const sortedRancanganTugas = computed(() => { return dataRancanganTugas.value.slice().sort((a, b) => a.no_urut - b.no_urut); });
const sortedKriteriaPenilaian = computed(() => { return dataKriteriaPenilaian.value.slice().sort((a, b) => a.no_urut - b.no_urut); });
const sortedRubrik = computed(() => { return dataRubrik.value.slice().sort((a, b) => a.no_urut - b.no_urut); });
const sortedLampiran = computed(() => { return dataLampiran.value.slice().sort((a, b) => a.no_urut - b.no_urut); });

const base64Logo = ref(null);
function convertImageToBase64(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Mengatasi masalah CORS

        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png'); // Ubah sesuai format yang diinginkan
            resolve(dataURL);
        };

        img.onerror = (error) => {
            reject(error);
        };

        img.src = url; // Menetapkan src ke gambar
    });
}

// Convert to Base64
const loadLogoImages = async () => {
    try { base64Logo.value = await convertImageToBase64(dataPT.value.logo); } 
    catch (error) { console.error("Gambar Logo gagal dimuat:", error); }
};
const loadRubrikImages = async () => {
    for (const rubrik of dataRubrik.value) {
        try { rubrik.base64 = await convertImageToBase64(rubrik.file_src); } 
        catch (error) { console.error(`Gambar rubrik gagal dimuat:`, error); }
    }
};
const loadLampiranImages = async () => {
    for (const lampiran of dataLampiran.value) {
        try { lampiran.base64 = await convertImageToBase64(lampiran.file_src); } 
        catch (error) { console.error(`Gambar lampiran gagal dimuat:`, error); }
    }
};
// End Convert to Base64

// Start Export
const contentToExport = ref(null);
function exportPDF() {
    const doc = new jsPDF();
    const img = new Image();

    img.src = base64Logo.value;
    img.onload = function () {
        doc.addImage(img, 'PNG', 80, 20, 50, 50); // Gambar logo pada posisi tertentu

        // Judul Buku
        doc.setFontSize(18);
        doc.text('BUKU RANCANGAN PENGAJARAN', 105, 100, { align: 'center' });
        doc.text(`${dataMKKurikulum.value.matakuliah_nama_ind}`, 105, 110, { align: 'center' });
        
        doc.setFontSize(16);
        doc.text('Oleh', 105, 150, { align: 'center' });
        
        // Dosen Pengembang BRP
        const startY = 180;
        const offsetY = 10;
        dataDosenPengembang.value.forEach((dosen, index) => {
            const yPosition = startY + (index * offsetY); // Hitung posisi Y untuk setiap dosen
            doc.text(dosen.display_name, 105, yPosition, { align: 'center' }); // Tulis nama dosen
        });

        // Prodi - PT
        doc.text(`Program Studi ${dataMKKurikulum.value.prodi_nama_ind}`, 105, 240, { align: 'center' });
        doc.text(`${dataMKKurikulum.value.fakultas_nama_ind}`, 105, 248, { align: 'center' });
        doc.text(`${dataPT.value.nama_ind}`, 105, 256, { align: 'center' });
        doc.text(`Depok, ${formatDate(dataBRP.value.created_at)}`, 105, 264, { align: 'center' });

        doc.autoTable({
            pageBreak: 'always',
            startY: 300,
            html: '#general-information-table',
            theme: 'grid',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
            },
            didDrawCell: (data) => {
                if (data.column.index === 0 && data.row.index === 0) { // Sel di baris 1 dan kolom 0
                    doc.addImage(img, 'PNG', data.cell.x + 6.5, data.cell.y + 0.5, 14, 14); // Ukuran gambar 10x10
                }
            },
        });

        doc.autoTable({
            pageBreak: 'always',
            html: '#learning-plan-table',
            theme: 'grid',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
            },
        });

        doc.autoTable({
            pageBreak: 'always',
            html: '#task-plan-table',
            theme: 'grid',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
            },
        });

        doc.autoTable({
            pageBreak: 'always',
            html: '#assessment-criteria-table',
            theme: 'grid',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
            },
        });

        doc.autoTable({
            pageBreak: 'always',
            rowPageBreak: 'avoid',
            theme: 'striped',
            html: '#assessment-rubric-table',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
            },
            headStyles: {
                fillColor: [24, 184, 152], // Warna biru (RGB)
                textColor: [255, 255, 255], // Warna teks putih (RGB)
                fontStyle: 'bold'
            },
            didDrawCell: function (data) {
                if (data.cell.raw.getElementsByTagName('img').length > 0) {
                    var img = data.cell.raw.getElementsByTagName('img')[0];
                    if (img && data.row.index % 2 === 1) {
                        data.row.height = 120;

                        // Menambahkan gambar
                        var imgX = data.cell.x + 42.5;
                        var imgY = data.cell.y + 5;
                        var imgWidth = 100;
                        var imgHeight = 100;
                        doc.addImage(img.src, 'PNG', imgX, imgY, imgWidth, imgHeight);

                        // Menambahkan border di sekitar gambar
                        doc.setLineWidth(0.2); // Menentukan ketebalan garis border
                        doc.rect(imgX, imgY, imgWidth, imgHeight); // Menggambar persegi panjang di sekitar gambar
                    }
                }
            }
        });

        doc.autoTable({
            pageBreak: 'always',
            rowPageBreak: 'avoid',
            theme: 'striped',
            html: '#attachment-table',
            styles: {
                halign: 'center',
                valign: 'middle',
                fontSize: 10,
            },
            headStyles: {
                fillColor: [24, 184, 152], // Warna biru (RGB)
                textColor: [255, 255, 255], // Warna teks putih (RGB)
                fontStyle: 'bold'
            },
            didDrawCell: function (data) {
                if (data.cell.raw.getElementsByTagName('img').length > 0) {
                    var img = data.cell.raw.getElementsByTagName('img')[0];
                    if (img && data.row.index % 2 === 1) {
                        data.row.height = 120;

                        // Menambahkan gambar
                        var imgX = data.cell.x + 42.5;
                        var imgY = data.cell.y + 5;
                        var imgWidth = 100;
                        var imgHeight = 100;
                        doc.addImage(img.src, 'PNG', imgX, imgY, imgWidth, imgHeight);

                        // Menambahkan border di sekitar gambar
                        doc.setLineWidth(0.2); // Menentukan ketebalan garis border
                        doc.rect(imgX, imgY, imgWidth, imgHeight); // Menggambar persegi panjang di sekitar gambar
                    }
                }
            }
        });

        // Simpan PDF
        doc.save('Buku_Rancangan_Pengajaran.pdf');
    };

    closeModal()
}
// End Export

const state = reactive({ modal_form: null, modalHidden: false })
const showModal = async () => { state.modal_form.show(); state.modalHidden = false };
function closeModal() { state.modal_form.hide(); state.modalHidden = true; }

const printBRP = async () => { 
    if (dataBRP.value.status_brp_id != 6) await onSubmit(brp_id, dataMKKurikulum.value.matakuliah_nama_ind)
    else exportPDF()
};
const onSubmit = async (brp_id, matakuliah) => {
    loading.value = true
    
    try {
        const res = await request({
            url: '/update-status-brp',
            method: 'POST',
            data: { 
                brp_id: parseInt(brp_id),
                status_brp_id: 6 // Status BRP menjadi Cetak
            }
        })

        if (res.status === 'SUCCESS') exportPDF()
        else Swal.fire( 'Gagal!', `BRP dengan matakuliah "${matakuliah}" gagal dicetak.`, 'error' ); 
    } catch (error) {
        const errData = error?.response?.data
        if (errData && errData.code == 400) Swal.fire( 'Gagal!', `BRP dengan matakuliah "${matakuliah}" gagal dicetak.`, 'error' );
    } finally {
        loading.value = false
    }
}

const dataPT = ref(null)
const dataBRP = ref({})
const dataMKKurikulum = ref({})
const dataDosenPengembang = ref([])
const dataCPMK = ref([])
const dataSubCPMK = ref([])
const dataKorelasi = ref({})
const dataTinjauanPustaka = ref({})
const dataRencanaBelajar = ref([])
const dataRancanganTugas = ref([])
const dataKriteriaPenilaian = ref([])
const dataRubrik = ref([])
const dataLampiran = ref([])
async function fetchDataKurikulum() {
	loading.value = true
	try { 
        dataPT.value = (await request(`/pt`)).data;
        dataBRP.value = (await request(`/brp/${brp_id}?loads=mk_kurikulum,dosen_pengembang,cpmk,sub_cpmk,korelasi,tinjauan_pustaka_brp,rencana_belajar,rancangan_tugas,kriteria_penilaian,rubrik,lampiran`)).data 
        dataMKKurikulum.value = dataBRP.value.mk_kurikulum
        dataDosenPengembang.value = dataBRP.value.dosen_pengembang
        dataCPMK.value = dataBRP.value.cpmk
        dataSubCPMK.value = dataBRP.value.sub_cpmk
        dataKorelasi.value = dataBRP.value.korelasi
        dataTinjauanPustaka.value = dataBRP.value.tinjauan_pustaka_brp
        dataRencanaBelajar.value = dataBRP.value.rencana_belajar
        dataRancanganTugas.value = dataBRP.value.rancangan_tugas
        dataKriteriaPenilaian.value = dataBRP.value.kriteria_penilaian
        dataRubrik.value = dataBRP.value.rubrik
        dataLampiran.value = dataBRP.value.lampiran
    } 
    catch (error) { console.error('Error:', error) } 
    finally { loading.value = false }
}

onMounted(async () => {
	await fetchDataKurikulum()
    await loadLogoImages()
    await loadRubrikImages()
    await loadLampiranImages()

    state.modal_form = new Modal('#modal_form', {})
})
</script>