<template>
   <breadcrumb-content menu="Pembuatan BRP" subMenu="Kriteria Penilaian" />

   <LoadingOverlay :loading="loading" />
   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-body">
         <div class="row">
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Rumpun Ilmu</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataMKKurikulum.rumpun_matakuliah_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Fakultas</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataMKKurikulum.fakultas_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Departemen</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataMKKurikulum.departemen_nama_ind" readonly>
                  </div>
               </div>
            </div>
            <div class="col-md-6 col-12">
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Program Studi</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataMKKurikulum.prodi_nama_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Jenjang</label>
                  <div class="col-sm-9">
                     <input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataMKKurikulum.prodi_jenjang_ind" readonly>
                  </div>
               </div>
               <div class="row mb-1">
                  <label class="col-form-label col-sm-3">Kurikulum</label>
                  <div class="col-sm-9">
                     <textarea type="text" class="form-control form-control-solid-bg form-control-sm" :value="`${dataMKKurikulum.kurikulum_kd_fk}\n${dataMKKurikulum.kurikulum_nama_ind}`" readonly></textarea>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
      <div class="card-header" style="min-height: 65px;">
         <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bold fs-3 mb-1">Kriteria Penilaian</span>
            <span class="text-muted mt-1 fw-semibold fs-7">Data Kriteria Penilaian <b>[Matakuliah: {{ dataMKKurikulum.matakuliah_nama_ind }}]</b></span>
         </h3>
         <div class="text-end pt-5">
            <span class="border border-primary border-1 btn btn-light-primary btn-sm p-2 fw-bold" @click="showModal()">
               <i class="fas fa-plus p-0 mt-n1"></i>
               Tambah Kriteria Penilaian
            </span>
         </div>
      </div>
      <div class="card-body pb-3">
         <div class="row">            
            <div class="table-responsive">
               <DataTable ref="tableRef" id="table" class="table gs-0 table-hover border border-secondary border-1" :options="options">
                  <thead class="text-center fw-bold text-muted bg-light px-3" style="vertical-align: middle;">
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Bentuk Evaluasi</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Sub-CPMK</th>
                           <th class="text-center px-3 border border-secondary border-1" colspan="2">Instrumen Penilaian Frekuensi</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Tagihan (Bukti)</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2">Bobot Penilaian <br /> (%)</th>
                           <th class="text-center px-3 border border-secondary border-1" rowspan="2" style="padding-right: 10px;">Actions</th>
                        </tr>
                        <tr>
                           <th class="text-center px-3 border border-secondary border-1">Formatif</th>
                           <th class="text-center px-3 border border-secondary border-1">Sumatif</th>
                        </tr>
                  </thead>
                  <tbody class="border border-secondary border-1" style="word-break: break-all; text-align: justify;"></tbody>
                  <tfoot>
                     <tr class="fw-bold text-muted bg-light">
                        <td colspan="5"><p class="text-end m-0">TOTAL</p></td>
                        <td class="px-3" id="totalBobotPenilaian"></td>
                        <td></td>
                     </tr>
                  </tfoot>
               </DataTable>
            </div>
         </div>
      </div>
      <div class="card-footer text-end" style="padding: 1.5rem 2.25rem;">
         <router-link :to="{ name: 'EditViewRubrikPenilaian', params: { id: brp_id }}" class="border border-primary border-1 btn btn-light-primary btn-sm">
            Lanjut Ke Halaman Rubrik Penilaian
            <i class="fa fa-angle-double-right"></i>
         </router-link>
      </div>
   </div>

   <ModalForm
      id="modal_form"
      :close_func="confirmCloseModal"
      :custom_classes="['modal-xl']"
      :modalHidden="state.modalHidden"
   >
      <template #title>
         <span v-if="state.is_edit && state.dataKriteriaPenilaian">Ubah Kriteria Penilaian</span>
         <span v-else>Tambah Kriteria Penilaian</span>
      </template>

      <template #body>
         <LoadingOverlay :loading="loadingModal" />
         
         <Form :id="`form-${state.is_edit && state.dataKriteriaPenilaian ? 'edit' : 'create'}-kriteria_penilaian`" class="kt-form" method="post" @submit="onSubmit" ref="formRef">
            <FormInput
               inputType="number"
               labelInd="Nomor Urut"
               customName="no_urut"
               :valueInd="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.no_urut : ''"
               :isEnglish="false"
               customErrorInd="Nomor Urut"
            />
            <div class="row mb-5">
               <label class="form-label">Bentuk Evaluasi</label>
               <div class="col-6">
                  <flag-image bahasa="Indonesia"/>
                  <select class="form-control form-control-solid-bg evaluation" data-target="/bentuk-evaluasi/" data-self="evaluation" data-child="evaluation_detail" data-point="bentuk_evaluasi_detail" data-control="select2" data-placeholder="-- Pilih Bentuk Evaluasi --">
                     <option></option>
                     <option v-for="(data, index) in evaluation" :key="index" :value="data.bentuk_evaluasi_id" :data-eng="data.nama_eng" :selected="evaluation_selected.id == data.bentuk_evaluasi_id">{{ data.nama_ind }}</option>
                  </select>
                  <div v-if="!evaluation_selected.eng" id="evaluation-error" class="invalid-feedback">Bentuk Evaluasi harus dipilih</div>
               </div>
               <div class="col-6">
                  <flag-image bahasa="Inggris"/>
                  <textarea id="evaluation" class="form-control form-control-solid-bg bg-light-dark" rows="1" v-model="evaluation_selected.eng" placeholder="Input Evaluation" readonly></textarea>
               </div>
            </div>
            <div class="row mb-5">
               <label class="form-label">Bentuk Evaluasi Detail</label>
               <div class="col-6">
                  <flag-image bahasa="Indonesia"/>
                  <select class="form-control form-control-solid-bg evaluation_detail" data-target="/bentuk-evaluasi-detail/" data-self="evaluation_detail" data-control="select2" data-placeholder="-- Pilih Bentuk Evaluasi Detail --">
                     <option></option>
                     <option v-for="(data, index) in evaluation_detail" :key="index" :value="data.bentuk_evaluasi_detail_id" :data-eng="data.deskripsi_eng" :selected="evaluation_detail_selected.id === data.bentuk_evaluasi_detail_id">{{ data.deskripsi_ind }}</option>
                  </select>
                  <div v-if="!evaluation_detail_selected.eng" id="evaluation_detail-error" class="invalid-feedback">Bentuk Evaluasi Detail harus dipilih</div>
               </div>
               <div class="col-6">
                  <flag-image bahasa="Inggris"/>
                  <textarea id="evaluation_detail" class="form-control form-control-solid-bg bg-light-dark" rows="1" v-model="evaluation_detail_selected.eng" placeholder="Input Evaluation Detail Form" readonly></textarea>
               </div>
            </div>
            <div class="row mb-5">
               <label class="form-label">
                  <span style="color: red">*</span>
                  Sub-CPMK
               </label>
               <div class="col-6">
                  <flag-image bahasa="Indonesia"/>

                  <select class="form-control form-control-solid-bg sub_cpmks" data-self="sub_cpmks" data-control="select2" data-placeholder="-- Pilih Sub-CPMK --" multiple>
                     <option></option>
                     <option v-for="data in sub_cpmks" :key="data.sub_cpmk_id" :value="data.sub_cpmk_id" :data-eng="data.keterangan_eng" :selected="subcpmk_ids_merged.includes(data.sub_cpmk_id)"> {{ data.keterangan_ind }} </option>
                  </select>
                  <div v-if="!sub_cpmks_selected.name_eng" id="sub_cpmks-error" class="invalid-feedback">Sub-CPMK harus dipilih</div>
               </div>
               <div class="col-6">
                  <flag-image bahasa="Inggris"/>

                  <textarea id="sub_cpmks" class="form-control form-control-solid-bg bg-light-dark" rows="1" readonly placeholder="Pilih Sub-CPMK" v-model="sub_cpmks_selected.name_eng"></textarea>
               </div>
            </div>
            <FormInput
               ref="editor1"
               labelInd="Formatif"
               labelEng="Formative"
               columnName="formatif"
               :valueInd="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.formatif_ind : ''"
               :valueEng="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.formatif_eng : ''"
               :ckeditor="true"
               customErrorInd="Formatif"
               customErrorEng="Formative"
            />
            <FormInput
               ref="editor2"
               labelInd="Sumatif"
               labelEng="Summative"
               columnName="sumatif"
               :valueInd="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.sumatif_ind : ''"
               :valueEng="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.sumatif_eng : ''"
               :ckeditor="true"
               customErrorInd="Sumatif"
               customErrorEng="Summative"
            />
            <FormInput
               ref="editor3"
               labelInd="Tagihan"
               labelEng="Bill"
               columnName="tagihan"
               :valueInd="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.tagihan_ind : ''"
               :valueEng="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.tagihan_eng : ''"
               :ckeditor="true"
               customErrorInd="Tagihan"
               customErrorEng="Bill"
            />
            <FormInput
               inputType="number"
               labelInd="Bobot Penilaian (%)"
               customName="bobot_penilaian"
               :valueInd="state.is_edit && state.dataKriteriaPenilaian ? state.dataKriteriaPenilaian.bobot_penilaian : ''"
               :isEnglish="false"
               customErrorInd="Bobot Penilaian"
            />

            <div class="modal-footer pt-5 p-0 text-end">
               <button type="button" class="btn btn-secondary" @click="confirmCloseModal">Batal</button>
               <button type="submit" class="btn btn-primary">
                  <span v-if="state.is_edit && state.dataKriteriaPenilaian">Simpan Perubahan</span>
                  <span v-else>Simpan Data</span>
               </button>
            </div>
         </Form>
      </template>
   </ModalForm>

   <AssesmentSlider :CourseInCurriculum="dataMKKurikulum" :brp="parseInt(brp_id)" v-if="statusBRP === 3" />
</template>

<script setup>
   import { onMounted, onBeforeUnmount, ref, toRaw, reactive, watch, nextTick } from 'vue'
   import { useRouter } from 'vue-router'
   import { Form } from 'vee-validate'
   import AssesmentSlider from './AssesmentSlider.vue';
   import LoadingOverlay from '@/components/LoadingOverlay'
   import request from '@/utils/request'
   import DataTable from 'datatables.net-vue3'
   import DataTablesLib from 'datatables.net'
   import swal from 'sweetalert2'
   import { Modal } from 'bootstrap';

   import FormInput from '@/components/FormInput.vue'
   import ModalForm from "@/components/ModalForm.vue";

   let dt
   const tableRef = ref()

   const formRef = ref(null)
   const loading = ref(true)
   const loadingModal = ref(false)
   const router = useRouter()
   const brp_id = router.currentRoute.value.params.brp_id

   const statusBRP = ref({})
   const dataMKKurikulum = ref({})
   const editor1 = ref(null)
   const editor2 = ref(null)
   const editor3 = ref(null)

   const evaluation = ref({});
   const evaluation_selected = ref({ id: {}, eng: '' });
   const evaluation_detail = ref({});
   const evaluation_detail_selected = ref({ id: {}, eng: '' });

   const sub_cpmks = ref([])
   const sub_cpmks_selected = ref({ sub_cpmk_id: [], name_eng: '' });
   const subcpmk_ids_merged = ref([])

   function validateFields(values, fields, removeClass = false) {
      let isValid = true;

      fields.forEach(field => {
         const value = values[field];
         // eslint-disable-next-line no-undef
         const fieldElement = $(`input[name=${field}]`);

         if (!value) { fieldElement.addClass('is-invalid'); isValid = false; } 
         else { fieldElement.removeClass('is-invalid'); }

         if (removeClass) { fieldElement.removeClass('is-invalid'); }
      });

      // Validate Select2 multi-select
      // eslint-disable-next-line no-undef
      const selects = { subCpmksSelect: $('select.sub_cpmks'), evaluationSelect: $('select.evaluation'), evaluationDetailSelect: $('select.evaluation_detail') };
      function validateSelect(select, isEmpty) {
         if (isEmpty) {
            select.next('.select2').find('.select2-selection').addClass('is-invalid');
            select.addClass('is-invalid');
            return false;
         } else {
            select.next('.select2').find('.select2-selection').removeClass('is-invalid');
            select.removeClass('is-invalid');
            return true;
         }
      }
      const isEvaluationValid = validateSelect(selects.evaluationSelect, !selects.evaluationSelect.val());
      const isEvaluationDetailValid = validateSelect(selects.evaluationDetailSelect, !selects.evaluationDetailSelect.val());
      const isSubCpmksValid = validateSelect(selects.subCpmksSelect, sub_cpmks_selected.value.sub_cpmk_id.length === 0);
      isValid = isEvaluationValid && isEvaluationDetailValid && isSubCpmksValid;

      if (removeClass) {
         Object.values(selects).forEach(select => {
            select.next('.select2').find('.select2-selection').removeClass('is-invalid');
            select.removeClass('is-invalid');
         });
      }

      return isValid;
   }

   const onSubmit = async values => {
      const dataToSend = {
         brp_id_fk: parseInt(brp_id),
         bentuk_evaluasi_detail_id_fk: parseInt(evaluation_detail_selected.value.id),
         formatif_ind: values.formatif_ind, formatif_eng: values.formatif_eng,
         sumatif_ind: values.sumatif_ind, sumatif_eng: values.sumatif_eng,
         tagihan_ind: values.tagihan_ind, tagihan_eng: values.tagihan_eng,
         bobot_penilaian: values.bobot_penilaian,
         no_urut: values.no_urut,
         sub_cpmk: (sub_cpmks_selected.value.sub_cpmk_id).map((v) => { return { sub_cpmk_id: parseInt(v) }; })
      };

      const fieldsToValidate = [
         'no_urut', 
         'bobot_penilaian', 
         'formatif_ind', 'formatif_eng',
         'sumatif_ind', 'sumatif_eng',
         'tagihan_ind', 'tagihan_eng',
      ];
      if (!validateFields(values, fieldsToValidate)) { return swal.fire({ title: `Harap memasukkan semua data yang ada terlebih dahulu!`, icon: 'error', confirmButtonText: 'OK' }); }
      
      try {
         loadingModal.value = true
         if (state.is_edit && state.dataKriteriaPenilaian) { 
            const param = await request({
               url: `/kriteria-penilaian/${state.dataKriteriaPenilaian.kriteria_penilaian_id}`,
               method: 'PUT',
               data: dataToSend,
            });

            handleResponse(param, 'diubah');
         } else {
            const param = await request({
               url: `/kriteria-penilaian`,
               method: 'POST',
               data: dataToSend,
            });

            handleResponse(param, 'ditambah');
         }
      } catch (error) {
         console.error('Error:', error);
      } finally {
         loadingModal.value = false;
      }
   }
   function handleResponse(param, action) {
      if (param.status === 'SUCCESS') {
         swal.fire({ icon: 'success', title: 'Success', text: `Kriteria Penilaian berhasil ${action}` }).then(async (result) => {
            if (result.isConfirmed) { closeModal(); reloadDataTable(); }
         });
      } else {
         swal.fire({ title: `Kriteria Penilaian gagal ${action}`, icon: 'error', confirmButtonText: 'OK' });
      }
   }

   // === Datatable ===
   DataTable.use(DataTablesLib)
   let dtKriteriaPenilaian = ref([]);
   const fetchDataKriteriaPenilaianWithPagination = async (params) => {
      if (dtKriteriaPenilaian.value.length === 0) {
         try { dtKriteriaPenilaian.value = (await request({ url:`/kriteria-penilaian-datatable?draw=1&start=0&length=-1&brp_id=${brp_id}&loads=kriteriapenilaian_subcpmk` })).data.data } 
         catch (error) { return error }
      }

      let resultDatas = dtKriteriaPenilaian.value
      let countFilter = resultDatas.length

      if (params?.search.value) {
         resultDatas = dtKriteriaPenilaian.value.filter(kriteria_penilaian => {
            let str = kriteria_penilaian?.formatif_ind + kriteria_penilaian?.formatif_eng 
                     + kriteria_penilaian?.sumatif_ind + kriteria_penilaian?.sumatif_eng 
                     + kriteria_penilaian?.tagihan_ind + kriteria_penilaian?.tagihan_eng 
                     + kriteria_penilaian?.no_urut 
                     + kriteria_penilaian?.bobot_penilaian
                     + kriteria_penilaian.bentuk_evaluasi_detail.bentuk_evaluasi_nama_ind
            if (kriteria_penilaian.kriteriapenilaian_subcpmk && Array.isArray(kriteria_penilaian.kriteriapenilaian_subcpmk)) { kriteria_penilaian.kriteriapenilaian_subcpmk.forEach(sub => { str += sub.sub_cpmk.keterangan_ind; }); }
            return str.toLowerCase().includes(params?.search.value.toLowerCase())
         })

         countFilter = resultDatas.length
      }

      if (params?.order.length > 0) {
         resultDatas = resultDatas.sort((a, b) => {
            return params?.order[0].dir === 'asc' ? (a['no_urut'] - b['no_urut']) : (b['no_urut'] - a['no_urut']);
         })
      }
      resultDatas = resultDatas.slice(params?.start, params?.start + params?.length)

      return { draw: params?.draw, recordsTotal: dtKriteriaPenilaian.value.length, recordsFiltered: countFilter, data: resultDatas }
   };
   const fetchDataKriteriaPenilaian = async (data, callback) => {
      try {
         const response = await fetchDataKriteriaPenilaianWithPagination(data)
         const plainResponse = {
            ...toRaw(response),
            data: response.data.map(item => toRaw(item))
         }

         callback(plainResponse)
      } catch (error) {
         console.error('Error fetching data kriteria penilaian:', error)
      } finally {
         loading.value = false
      }
   }
   const reloadDataTable = async () => {
      dtKriteriaPenilaian.value = [];
      if (dt && dt.ajax) { dt.ajax.reload(null, false); } 
      else { console.error('DataTable reloaded unsuccessfully.'); }
   };
   const columns = ref([
      { data: 'bentuk_evaluasi_detail', title: 'Bentuk Evaluasi', width: '25%', className: 'text-center px-3',
         render: function (data, type, row) {
            if (type === 'display' && row) {
               const bentukEvaluasi = data.bentuk_evaluasi_nama_ind +' - '+ data.deskripsi_ind;
               return `<td> <span>${bentukEvaluasi}</span> </td>`;
            } else {
               return `<td> <span>-</span> </td>`;
            }
         }
      },
      { data: 'kriteriapenilaian_subcpmk', title: 'Sub-CPMK', width: '10%',
         render: function (data, type, row) {
            if (type === 'display' && row && Array.isArray(data) && data.length > 0) {
               const subCpmks = [...new Set(data.map(item => item.sub_cpmk.keterangan_ind))].join(', ');
               return `<td> <span>${subCpmks}</span> </td>`;
            } else {
               return `<td> <span>-</span> </td>`;
            }
         }
      },
      { data: 'formatif_ind', title: 'Formatif', width: '17%' },
      { data: 'sumatif_ind', title: 'Sumatif', width: '17%' },
      { data: 'tagihan_ind', title: 'Tagihan (Bukti)', width: '17%' },
      { data: 'bobot_penilaian', title: 'Bobot Penilaian (%)', width: '7%', className: 'text-center',
         render: function (data, type, row) {
            if (type === 'display' && row) return row.bobot_penilaian+`%`;
            else return '-';
         }
      },
      { title: 'Actions', orderable: false, searchable: false, width: '7%', className: 'text-center px-3',
         render: function (data, type, row) {
            let actionBtn = `
                              <div class="d-flex justify-content-center">
                                 <button class="border border-secondary border-1 btn btn-icon btn-sm btn-edit-datatable btn-active-color-primary w-30px h-30px mx-1" data-toggle="tooltip" data-placement="top" title="Edit Data" data-id="${row.kriteria_penilaian_id}">
                                    <i class="fa fa-edit p-0 fs-5"></i>
                                 </button>

                                 <button class="border border-secondary border-1 btn btn-icon btn-sm btn-delete-datatable btn-active-color-danger w-30px h-30px mx-1" data-toggle="tooltip" data-placement="top" title="Delete Data" data-id="${row.kriteria_penilaian_id}">
                                    <i class="fa fa-trash p-0 fs-5"></i>
                                 </button>
                              </div>
                           `;
            return actionBtn;
         }
      }
   ]);
   const options = ref({ 
      serverSide: true, 
      ajax: fetchDataKriteriaPenilaian, 
      columns: columns.value,
      footerCallback: function (row, data) {
         let total = data.reduce((accum, current) => {
            return accum + (parseFloat(current.bobot_penilaian) || 0);
         }, 0);

         // eslint-disable-next-line no-undef
         $(row).find('#totalBobotPenilaian').html(total.toFixed(2) + '%');
      }
   })

   const handleButtonClick = async event => {
      const target = event.target
      const button = target.closest('.btn-edit-datatable, .btn-delete-datatable')

      if (button) {
         event.stopPropagation()
         event.preventDefault()

         if (button.matches('.btn-edit-datatable')) {
            showModal(button.dataset.id)
         } else if (button.matches('.btn-delete-datatable')) {
            const result = await swal.fire({
               title: `Apakah Anda yakin untuk menghapus data Kriteria Penilaian ini?`,
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Ya, saya yakin',
               cancelButtonText: 'Batal'
            })
            if (!result.isConfirmed) { return }

            loading.value = true

            try {
               const res = await request({
                  url: `/kriteria-penilaian/${button.dataset.id}`,
                  method: 'DELETE',
               })

               if (res.status === 'SUCCESS') {
                  return await swal.fire({
                     icon: 'success',
                     title: 'Success',
                     text: `Kriteria Penilaian berhasil dihapus`,
                  }).then(async (result) => { 
                     if (result.isConfirmed) { reloadDataTable() } 
                  });
               } 
               else { return await swal.fire({ title: 'Kriteria Penilaian gagal dihapus', icon: 'error', confirmButtonText: 'OK' }) }
            } 
            catch (error) { return await swal.fire({ title: 'Kriteria Penilaian gagal dihapus', icon: 'error', confirmButtonText: 'OK' }) } 
            finally { loading.value = false }
         }
      }
   }
   // === End Datatable ===


   // === Modal Form ===
   const fetchDataFormSelectInModal = async () => {
      loadingModal.value = true

      try { 
         evaluation.value = (await request(`/bentuk-evaluasi?per_page=-1`)).data.data
         sub_cpmks.value = (await request(`/sub-cpmk-datatable?length=-1&brp_id=${brp_id}`)).data.data[0].sub_cpmk
      } 
      catch (error) { console.error('Error fetching data form select in modal:', error) } 
      finally { loadingModal.value = false }
   }
   const state = reactive({
      modal_form: null,
      is_edit: false,
      dataKriteriaPenilaian: null,
      modalHidden: false,
   })

   const showModal = async (kriteria_penilaian_id) => {
      state.modal_form.show()
      await fetchDataFormSelectInModal()

      if (kriteria_penilaian_id) {
         loadingModal.value = true
         state.is_edit = true
         
         try { 
            // get All Data
            state.dataKriteriaPenilaian = (await request({ url:`/kriteria-penilaian/${kriteria_penilaian_id}` })).data 
            
            evaluation_selected.value.id = state.dataKriteriaPenilaian.bentuk_evaluasi_id_fk
            evaluation_selected.value.eng = state.dataKriteriaPenilaian.bentuk_evaluasi_detail.bentuk_evaluasi_nama_eng

            evaluation_detail.value = (await request({ url:`/bentuk-evaluasi/${ evaluation_selected.value.id }` })).data.bentuk_evaluasi_detail;
            evaluation_detail_selected.value.id = state.dataKriteriaPenilaian.BentukEvaluasiDetailIdFk
            evaluation_detail_selected.value.eng = state.dataKriteriaPenilaian.bentuk_evaluasi_detail.deskripsi_eng

            // get Data SubCPMK Selected
            const subcpmkDatas = (await request({ url: `/kriteria-penilaian-subcpmk-datatable?length=-1&brp_id=${brp_id}&kriteria_penilaian_id=${kriteria_penilaian_id}` })).data.data[0].subcpmk;
            sub_cpmks_selected.value.sub_cpmk_id = subcpmkDatas.map(item => item.sub_cpmk_id);
            sub_cpmks_selected.value.name_eng = subcpmkDatas.map(item => item.keterangan_eng).join(', ');

            subcpmk_ids_merged.value = [...new Set([...sub_cpmks_selected.value.sub_cpmk_id, ...subcpmk_ids_merged.value])];
         } 
         catch (error) { return error } 
         finally { loadingModal.value = false; }
      } else {
         document.getElementById('evaluation').style.height = '0px';
         document.getElementById('evaluation_detail').style.height = '0px';
         document.getElementById('sub_cpmks').style.height = '0px';
      }

      state.modalHidden = false
   };
   function clearFormData() {
      if (state.is_edit) { state.is_edit = false; state.dataKriteriaPenilaian = null; } 

      formRef.value.setFieldValue('no_urut', '')
      formRef.value.setFieldValue('bobot_penilaian', '')

      // Reset CKEditor values
      editor1.value.resetValueInd(); editor1.value.resetValueEng();
      editor2.value.resetValueInd(); editor2.value.resetValueEng();
      editor3.value.resetValueInd(); editor3.value.resetValueEng();

      // Select2
      evaluation.value = [];
      evaluation_selected.value.id = []; evaluation_selected.value.eng = '';
      evaluation_detail.value = [];
      evaluation_detail_selected.value.id = []; evaluation_detail_selected.value.eng = '';

      sub_cpmks.value = [];
      sub_cpmks_selected.value.sub_cpmk_id = []; sub_cpmks_selected.value.name_eng = '';
      subcpmk_ids_merged.value = [];
      
      const fieldsToValidate = [
         'no_urut', 
         'bobot_penilaian', 
         'formatif_ind', 'formatif_eng',
         'sumatif_ind', 'sumatif_eng',
         'tagihan_ind', 'tagihan_eng',
      ];

      validateFields(formRef.value, fieldsToValidate, true);
   }
   function closeModal() { state.modal_form.hide(); clearFormData(); state.modalHidden = true; }

   async function confirmCloseModal() {
      const result = await swal.fire({
			title: `Konfirmasi Batal Input`,
			text: `Apakah Anda yakin ingin keluar dari proses inputan ini?, karena data yang telah diinput akan terhapus`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonText: 'Ya, saya yakin',
			cancelButtonText: 'Batal'
		})

		if (!result.isConfirmed) { return } 
      else { closeModal() }
   }
   // === End Modal Form ===

   const handleElSingleSelect = (async (event) => {
      const el = event.target;
      if (el.dataset.child && el.dataset.point) {
         eval(`${ el.dataset.child }`).value = {};
         eval(`${ el.dataset.child }_selected`).value = {};
         eval(el.dataset.child).value = (await request({ url:`${ el.dataset.target }${ el.value }` })).data[el.dataset.point];
      }
      eval(`${ el.dataset.self }_selected`).value.id = (el.options[el.selectedIndex]).value;
      eval(`${ el.dataset.self }_selected`).value.eng = (el.options[el.selectedIndex]).dataset.eng;

      handleTextarea(el.dataset.self);
   });
   const handleElMultiSelect = (async (event) => {
      const el = event.target;
      sub_cpmks_selected.value.sub_cpmk_id = Array.from(el.selectedOptions || []).map(option => option.value);
      sub_cpmks_selected.value.name_eng = (Array.from(el.selectedOptions || []).map(option => option.dataset.eng)).join(', ');
      subcpmk_ids_merged.value = [...new Set([...subcpmk_ids_merged.value, ...sub_cpmks_selected.value.sub_cpmk_id])];

      handleTextarea(el.dataset.self);
   });
   const handleTextarea = ((id) => {
      const textarea = document.getElementById(id);
      setTimeout(() => { textarea.style.height = 'auto'; textarea.style.height = textarea.scrollHeight + 'px'; }, 20);
   });

   // eslint-disable-next-line no-undef
   const initializeSelect2 = () => { $('select.evaluation, select.evaluation_detail').select2().on('change', handleElSingleSelect); $('select.sub_cpmks').select2().on('change', handleElMultiSelect); };
   async function fetchDataBRP() {
      try {
         statusBRP.value = (await request(`/brp/${brp_id}`)).data.status_brp_id; 
         dataMKKurikulum.value = (await request(`/brp/${brp_id}?loads=mk_kurikulum`)).data.mk_kurikulum; 
      } 
      catch (error) { console.error('Error:', error); } 
      finally { loading.value = false }
   }
   onMounted(async () => {
      dt = tableRef.value.dt
      document.addEventListener('click', handleButtonClick)
      
      // eslint-disable-next-line no-undef
      $('div.dataTables_length, div.dataTables_info').parent().removeClass('col-sm-12 col-md-6').closest('.row').removeClass('row').addClass('d-flex justify-content-between');
      // eslint-disable-next-line no-undef
      $('div.dataTables_filter, div.dataTables_paginate').parent().removeClass('col-sm-12 col-md-6');
      
      state.modal_form = new Modal('#modal_form', {})
      // state.modal_form._element.addEventListener('hide.bs.modal', () => { clearFormData(); })

      await nextTick(() => { initializeSelect2(); fetchDataBRP(); });
   })
   onBeforeUnmount(() => { document.removeEventListener('click', handleButtonClick) })
   watch(sub_cpmks_selected.value, async (newVal) => { if (newVal.sub_cpmk_id.length > 0) await nextTick(() => { initializeSelect2(); }) });
</script>