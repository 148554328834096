<template>
	<LoadingOverlay :loading="loading" />
	<breadcrumb-content menu="Perubahan BRP" subMenu="Rubrik Penilaian" />
	<div id="rubrik-penilaian">
		<div v-if="Object.keys(data.SearchInfo).length > 0">
			<SearchInformation :SearchInfo="data.SearchInfo" />
		</div>
      <div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
			<div class="card-header" style="min-height: 65px;">
				<h3 class="card-title align-items-start flex-column">
					<span class="card-label fw-bold fs-3 mb-1">Rubrik Penilaian</span>
					<span class="text-muted mt-1 fw-semibold fs-7">Data Rubrik Penilaian <b>[Matakuliah: {{ data.CourseInCurriculum.matakuliah_nama_ind }}]</b></span>
				</h3>
			</div>
         <div class="card-body pt-10 pb-0">
            <div class="row">
					<div class="col-sm">
						<DataTable :columns="columns" :data="Rubric.length > 0 ? Rubric : []" class="table table-row-bordered gy-5 align-middle dataTable no-footer" />
					</div>
            </div>
         </div>
			<div class="card-footer text-end" style="padding: 1.5rem 2.25rem;">
				<a class="border border-primary border-1 btn btn-light-primary btn-sm" @click="handleNext(data.BRP.brp_id)"> Lanjut Ke Halaman Lampiran <i class="fa fa-angle-double-right"></i></a>
			</div>
      </div>
		<div v-if="Modal">
			<component :is="Modal" :data="ModalData" @removeModal="onRemoveModal" />
		</div>
   </div>
</template>

<script setup>
	import { ref, onMounted, onBeforeUnmount, shallowRef } from 'vue';
   import { useRouter } from 'vue-router';
	import SearchInformation from './SearchInformation.vue';
	import DataTable from 'datatables.net-vue3';
   import request from '@/utils/request';
	import LoadingOverlay from '@/components/LoadingOverlay';

   const loading = ref(true);
   const router = useRouter();
   const id = router.currentRoute.value.params.id;
	const data = ref({ BRP: {}, CourseInCurriculum: {}, SearchInfo: {} });
	const Rubric = ref({});
	const ModalData = ref({});
	let Modal = shallowRef(null);
	const onOpenModal = ((data) => {
		Modal.value = null;
		ModalData.value = data;
		import(`./Modal.vue`).then(module => {
			Modal.value = module.default;
		}).then(() => {
			// eslint-disable-next-line no-undef
			$(`#Modal-${ data.body }`).fadeIn(1000);
		});
	});
	const handleClick = ((event) => {
      const target = event.target;
      if (target.classList.contains('preview')) onOpenModal({ title: 'Pratinjau Gambar Rubrik', body: 'PreviewImage', src: target.getAttribute('data-src'), style: { width: '360px', height: '550px' } });
   });
	const handleNext = ((id) => router.push({ name: 'ShowViewLampiran', params: { id } }) );
	const onRemoveModal = (({ dismiss, data, type }) => {
		eval(dismiss).value = null;
		if (data) {
			if (type) Rubric.value = Rubric.value.map((v) => ((v.id === data.rubrik_id) ? ({ ...v, no: data.no_urut, name: data.nama, file: data.file_name, src: data.file_src }) : (v)) );
			else Rubric.value.push({ id: data.rubrik_id, brp: data.brp_id_fk, no: data.no_urut, name: data.nama, file: data.file_name, src: data.file_src });

			(Rubric.value).sort((a, b) => a.no - b.no );
		}
	});
	const columns = [
		{ title: 'Rubrik', data: 'name', width: '250px', orderable: false },
		{ title: 'File', data: null, orderable: false, render: (data, type, row) => (`<span class="preview" data-toggle="tooltip" data-placement="top" title="Pratinjau Gambar" data-src="${ row.src }" style="color:#89a6cf; cursor:pointer;">${ row.file }</span>`) }
	];

	onMounted(async () => {
		try {
			data.value.BRP = (await request({ url: `/brp/${ id }?loads=mk_kurikulum,rubrik` })).data;
			data.value.CourseInCurriculum = data.value.BRP.mk_kurikulum;
			data.value.SearchInfo = { group: data.value.CourseInCurriculum.rumpun_ilmu_nama_ind, faculty: data.value.CourseInCurriculum.fakultas_nama_ind, department: data.value.CourseInCurriculum.departemen_nama_ind, program: data.value.CourseInCurriculum.prodi_nama_ind, grade: data.value.CourseInCurriculum.prodi_jenjang_ind, curriculum: { code: data.value.CourseInCurriculum.kurikulum_kd_fk, name: data.value.CourseInCurriculum.kurikulum_nama_ind } };
			Rubric.value = data.value.BRP.rubrik.map((v) => ({ id: v.rubrik_id, brp: v.brp_id_fk, no: v.no_urut, name: v.nama, file: v.file_name, src: v.file_src }) ).sort((a, b) => a.no - b.no );

			document.addEventListener('click', handleClick);
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});

	onBeforeUnmount(() => { document.removeEventListener('click', handleClick); });
</script>