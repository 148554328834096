<template>
	<LoadingOverlay :loading="loading" />
	<breadcrumb-content menu="Laporan Kurikulum" subMenu="Monitoring Kurikulum"/>
	
	<div class="card mb-5 mb-xxl-8">
		<div class="card-body pt-9 pb-0">
			<div class="table-responsive">
				<DataTable ref="tableRef" id="table" class="table table-row-bordered table-hover gy-5 align-middle" :options="options">
					<thead class="text-center fw-bold text-muted" style="vertical-align: middle;">
						<tr>
							<th class="text-center" rowspan="2">No</th>
							<th class="text-center" rowspan="2">Nama Kurikulum</th>
							<th class="text-center" colspan="3">Fakultas</th>
							<th class="text-center" colspan="5">DPASDP</th>
						</tr>
						<tr>
							<th class="text-center">UPMA</th>
							<th class="text-center">SAF</th>
							<th class="text-center">Fakultas</th>

							<th class="text-center">Review DPASDP</th>
							<th class="text-center">Petugas Review 1</th>
							<th class="text-center">Petugas Review 2</th>
							<th class="text-center">Persetujuan DPASDP</th>
							<th class="text-center">Pengesahan Rektor</th>
						</tr>
					</thead>
					<tbody style="word-break: break-all; text-align: justify;"></tbody>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, toRaw } from 'vue';
	import { useRouter } from 'vue-router';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import request from '@/utils/request'
	import DataTable from 'datatables.net-vue3'
	import DataTablesLib from 'datatables.net'

	const loading = ref(false);
	const router = useRouter();

	// === Datatable ===
	DataTable.use(DataTablesLib)
	let dtKurikulum = ref([]);
	const fetchDataKurikulumWithPagination = async (params) => {
		if (dtKurikulum.value.length === 0) {
			try { dtKurikulum.value = (await request({ url:`/laporan-status-monitoring?draw=1&start=0&length=-1&status=2,3,4,5,6` })).data.data } 
			catch (error) { return error }
		}

		let resultDatas = dtKurikulum.value
		let countFilter = resultDatas.length

		if (params?.search.value) {
			resultDatas = dtKurikulum.value.filter(kurikulum => {
				let str = kurikulum?.kurikulum_kd + kurikulum?.nama_ind 
				return str.toLowerCase().includes(params?.search.value.toLowerCase())
			})

			countFilter = resultDatas.length
		}

		if (params?.order.length > 0) {
			resultDatas = resultDatas.sort((a, b) => {
				return params?.order[0].dir === 'asc' ? (a['nama_ind'] - b['nama_ind']) : (b['nama_ind'] - a['nama_ind']);
			})
		}
		resultDatas = resultDatas.slice(params?.start, params?.start + params?.length)

		return { draw: params?.draw, recordsTotal: dtKurikulum.value.length, recordsFiltered: countFilter, data: resultDatas }
	};
	const fetchDataKurikulum = async (data, callback) => {
		try {
			const response = await fetchDataKurikulumWithPagination(data)
			const plainResponse = {
				...toRaw(response),
				data: response.data.map(item => toRaw(item))
			}

			callback(plainResponse)
		} catch (error) {
			console.error('Error fetching data kurikulum:', error)
		} finally {
			loading.value = false
		}
	}
	const columns = ref([
		{ data: 'rownum', title: 'No', width: '8%', className: 'text-center', searchable: false, orderable: false },
		{ data: 'nama_ind', title: 'Nama Kurikulum', width: '30%', orderable: false,
			render: function (data, type, row) {
				if (type === 'display' && row) {
					const date = new Date(row.created_at);
					const options = { year: 'numeric', month: 'long' };
					const formattedDate = date.toLocaleDateString('id-ID', options);
	
					return `
							<a class="link-kurikulum" data-toggle="tooltip" data-placement="top" title="Lihat Detail" onclick="navigateToDetailPage(${row.kurikulum_id})">
								<p class="m-0">${row.kurikulum_kd}</p>
								<p class="m-0">${row.nama_ind}</p>
								<p class="m-0 mt-3">Dibuat sejak: ${formattedDate}</p>
							</a>`;
				} else { 
					return '-'; 
				}
			}
		},
		{ data: 'status_monitoring_id', title: 'UPMA', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'upma')
		},
		{ data: 'status_monitoring_id', title: 'SAF', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'saf')
		},
		{ data: 'status_monitoring_id', title: 'Fakultas', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'fakultas')
		},
		{ data: 'status_monitoring_id', title: 'Review DPASDP', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'review_dpasdp')
		},
		{ data: 'status_monitoring_id', title: 'Petugas Review 1', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'petugas_review_1')
		},
		{ data: 'status_monitoring_id', title: 'Petugas Review 2', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'petugas_review_2')
		},
		{ data: 'status_monitoring_id', title: 'Persetujuan DPASDP', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'persetujuan_dpasdp')
		},
		{ data: 'status_monitoring_id', title: 'Pengesahan Rektor', className: 'text-center', searchable: false, orderable: false,
			render: (data, type, row) => renderCheckboxes(data, type, row, 'pengesahan_rektor')
		}
	]);

	const options = ref({ 
		serverSide: true, 
		ajax: fetchDataKurikulum, 
		columns: columns.value
	})

	window.navigateToDetailPage = function (kurikulum_id) { 
		const url = router.resolve({ name: 'ShowViewMonitoringKurikulum', params: { kurikulum_id: kurikulum_id } }).href;
		window.open(url, '_blank');
	};
	function renderCheckboxes(data, type, row, field) {
		if (type === 'display' && row) {
			let checkStatus = false;

			if (field === 'pengesahan_rektor') {
				checkStatus = (data == 6 && row.rektor);
			} else if (field === 'persetujuan_dpasdp') {
				checkStatus = (data == 5 && row.dpasdp.persetujuan && !row.dpasdp.rektor);
			} else if (field === 'petugas_review_2') {
				checkStatus = (data == 5 && row.dpasdp.petugas2 && !row.dpasdp.rektor && !row.dpasdp.persetujuan);
			} else if (field === 'petugas_review_1') {
				checkStatus = (data == 5 && row.dpasdp.petugas1 && !row.dpasdp.rektor && !row.dpasdp.persetujuan);
			} else if (field === 'review_dpasdp') {
				checkStatus = (data == 5 && row.dpasdp.review && !row.dpasdp.rektor && !row.dpasdp.persetujuan && !row.dpasdp.petugas2 && !row.dpasdp.petugas1);
			} else if (field === 'fakultas') {
				checkStatus = (row.fakultas.fakultas && !row.dpasdp.rektor && !row.dpasdp.persetujuan && !row.dpasdp.petugas2 && !row.dpasdp.petugas1 && !row.dpasdp.review);
			} else if (field === 'saf') {
				checkStatus = (row.fakultas.saf && !row.dpasdp.rektor && !row.dpasdp.persetujuan && !row.dpasdp.petugas2 && !row.dpasdp.petugas1 && !row.dpasdp.review && !row.fakultas.fakultas);
			} else if (field === 'upma') {
				checkStatus = (row.fakultas.upma && !row.dpasdp.rektor && !row.dpasdp.persetujuan && !row.dpasdp.petugas2 && !row.dpasdp.petugas1 && !row.dpasdp.review && !row.fakultas.fakultas && !row.fakultas.saf);
			}

			return `
				<div class="form-check form-check-custom form-check-success form-check-solid form-check-sm d-flex justify-content-center">
					<input class="form-check-input" type="checkbox" ${checkStatus ? 'checked' : ''} disabled />
				</div>`;
		} else { 
			return '-'; 
		}
	}
	// === End Datatable ===
</script>

<style>
	.link-kurikulum {
		color: var(--kt-dark); 
		text-decoration: none; 
		cursor: pointer;
	}

	.link-kurikulum:hover {
		color: var(--kt-primary-active);
		font-weight: 500;
	}
</style>