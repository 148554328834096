<template>
	<div :id="props.id" :class="final_classes" tabindex="-1" :aria-labelledby="props.id + '_label'" v-show="!modalHidden" aria-modal="true" data-bs-backdrop="static" data-bs-keyboard="false" @keydown.esc="props.close_func">
		<div :class="{'modal-dialog modal-dialog-scrollable' : true, 'modal-dialog-centered' : props.modal_center}">
			<div class="modal-content">
				<div class="modal-header py-5">
					<h2 class="modal-title" :id="props.id + '_label'">
						<slot name="title"></slot>
					</h2>
					<button type="button" class="btn-close" @click="props.close_func"></button>
				</div>
				<div class="modal-body">
					<slot name="body"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, defineProps } from 'vue';

const props = defineProps({
	id: String,
	close_func: Function,
	custom_classes: Array,
	modalHidden: Boolean,
	modal_center: {
		type: Boolean,
		default: false
	}
});

const base_classes = ['modal', 'fade']
const final_classes = computed(() => {
	return [...props.custom_classes, ...base_classes]
})

onMounted(async () => {
	// 
})
</script>