import { useAccessStore } from '@/stores/accessStore'
import { useAuthStore } from '@/stores/authStore'
import { checkHasPermission } from '@/utils/permission'
import { createRouter, createWebHistory } from 'vue-router'
// import keycloakService from '@/utils/keycloak';

import MainLayout from '../layouts/MainLayout.vue'
import DashboardView from '../views/DashboardView.vue'
import LandingView from '../views/LandingView.vue'

import IndexViewPerguruanTinggi from '../views/Profil/PerguruanTinggi/IndexView.vue'

import CreateViewRumpunIlmu from '../views/Profil/RumpunIlmu/CreateView.vue'
import EditViewRumpunIlmu from '../views/Profil/RumpunIlmu/EditView.vue'
import IndexViewRumpunIlmu from '../views/Profil/RumpunIlmu/IndexView.vue'
import ShowViewRumpunIlmu from '../views/Profil/RumpunIlmu/ShowView.vue'

import CreateViewFakultas from '../views/Profil/Fakultas/CreateView.vue'
import EditViewFakultas from '../views/Profil/Fakultas/EditView.vue'
import IndexViewFakultas from '../views/Profil/Fakultas/IndexView.vue'
import ShowViewFakultas from '../views/Profil/Fakultas/ShowView.vue'

import CreateViewDepartemen from '../views/Profil/Departemen/CreateView.vue'
import EditViewDepartemen from '../views/Profil/Departemen/EditView.vue'
import IndexViewDepartemen from '../views/Profil/Departemen/IndexView.vue'
import ShowViewDepartemen from '../views/Profil/Departemen/ShowView.vue'

import CreateViewProgramStudi from '../views/Profil/ProgramStudi/CreateView.vue'
import EditViewProgramStudi from '../views/Profil/ProgramStudi/EditView.vue'
import IndexViewProgramStudi from '../views/Profil/ProgramStudi/IndexView.vue'
import ShowViewProgramStudi from '../views/Profil/ProgramStudi/ShowView.vue'

import CreateViewJenjang from '../views/Profil/Jenjang/CreateView.vue'
import EditViewJenjang from '../views/Profil/Jenjang/EditView.vue'
import IndexViewJenjang from '../views/Profil/Jenjang/IndexView.vue'
import ShowViewJenjang from '../views/Profil/Jenjang/ShowView.vue'

import CreateViewKelas from '../views/Profil/Kelas/CreateView.vue'
import EditViewKelas from '../views/Profil/Kelas/EditView.vue'
import IndexViewKelas from '../views/Profil/Kelas/IndexView.vue'
import ShowViewKelas from '../views/Profil/Kelas/ShowView.vue'

import CreateViewJenisSemester from '../views/Profil/JenisSemester/CreateView.vue'
import EditViewJenisSemester from '../views/Profil/JenisSemester/EditView.vue'
import IndexViewJenisSemester from '../views/Profil/JenisSemester/IndexView.vue'
import ShowViewJenisSemester from '../views/Profil/JenisSemester/ShowView.vue'

import CreateViewJenisKompetensi from '../views/MasterData/JenisKompetensi/CreateView.vue'
import EditViewJenisKompetensi from '../views/MasterData/JenisKompetensi/EditView.vue'
import IndexViewJenisKompetensi from '../views/MasterData/JenisKompetensi/IndexView.vue'
import ShowViewJenisKompetensi from '../views/MasterData/JenisKompetensi/ShowView.vue'

import CreateViewKompetensi from '../views/MasterData/Kompetensi/CreateView.vue'
import EditViewKompetensi from '../views/MasterData/Kompetensi/EditView.vue'
import IndexViewKompetensi from '../views/MasterData/Kompetensi/IndexView.vue'
import ShowViewKompetensi from '../views/MasterData/Kompetensi/ShowView.vue'

import CreateViewBentukEvaluasi from '../views/MasterData/BentukEvaluasi/CreateView.vue'
import EditViewBentukEvaluasi from '../views/MasterData/BentukEvaluasi/EditView.vue'
import IndexViewBentukEvaluasi from '../views/MasterData/BentukEvaluasi/IndexView.vue'
import ShowViewBentukEvaluasi from '../views/MasterData/BentukEvaluasi/ShowView.vue'

import CreateViewBentukEvaluasiDetail from '../views/MasterData/BentukEvaluasiDetail/CreateView.vue'
import EditViewBentukEvaluasiDetail from '../views/MasterData/BentukEvaluasiDetail/EditView.vue'
import IndexViewBentukEvaluasiDetail from '../views/MasterData/BentukEvaluasiDetail/IndexView.vue'
import ShowViewBentukEvaluasiDetail from '../views/MasterData/BentukEvaluasiDetail/ShowView.vue'

// import IndexViewKategoriBahanKajian from '../views/MasterData/KategoriBahanKajian/IndexView.vue'
// import CreateViewKategoriBahanKajian from '../views/MasterData/KategoriBahanKajian/CreateView.vue'
// import ShowViewKategoriBahanKajian from '../views/MasterData/KategoriBahanKajian/ShowView.vue'
// import EditViewKategoriBahanKajian from '../views/MasterData/KategoriBahanKajian/EditView.vue'

import CreateViewJenisKerangkaKerja from '../views/MasterData/JenisKerangkaKerja/CreateView.vue'
import EditViewJenisKerangkaKerja from '../views/MasterData/JenisKerangkaKerja/EditView.vue'
import IndexViewJenisKerangkaKerja from '../views/MasterData/JenisKerangkaKerja/IndexView.vue'
import ShowViewJenisKerangkaKerja from '../views/MasterData/JenisKerangkaKerja/ShowView.vue'

import CreateViewKerangkaKerja from '../views/MasterData/KerangkaKerja/CreateView.vue'
import EditViewKerangkaKerja from '../views/MasterData/KerangkaKerja/EditView.vue'
import IndexViewKerangkaKerja from '../views/MasterData/KerangkaKerja/IndexView.vue'
import ShowViewKerangkaKerja from '../views/MasterData/KerangkaKerja/ShowView.vue'

import CreateViewKategoriTinjauanPustaka from '../views/MasterData/KategoriTinjauanPustaka/CreateView.vue'
import EditViewKategoriTinjauanPustaka from '../views/MasterData/KategoriTinjauanPustaka/EditView.vue'
import IndexViewKategoriTinjauanPustaka from '../views/MasterData/KategoriTinjauanPustaka/IndexView.vue'
import ShowViewKategoriTinjauanPustaka from '../views/MasterData/KategoriTinjauanPustaka/ShowView.vue'

import CreateViewTinjauanPustaka from '../views/MasterData/TinjauanPustaka/CreateView.vue'
import EditViewTinjauanPustaka from '../views/MasterData/TinjauanPustaka/EditView.vue'
import IndexViewTinjauanPustaka from '../views/MasterData/TinjauanPustaka/IndexView.vue'
import ShowViewTinjauanPustaka from '../views/MasterData/TinjauanPustaka/ShowView.vue'

import CreateViewAktivitasPembelajaran from '../views/MasterData/AktivitasPembelajaran/CreateView.vue'
import EditViewAktivitasPembelajaran from '../views/MasterData/AktivitasPembelajaran/EditView.vue'
import IndexViewAktivitasPembelajaran from '../views/MasterData/AktivitasPembelajaran/IndexView.vue'
import ShowViewAktivitasPembelajaran from '../views/MasterData/AktivitasPembelajaran/ShowView.vue'

import CreateViewMediaTeknologi from '../views/MasterData/MediaTeknologi/CreateView.vue'
import EditViewMediaTeknologi from '../views/MasterData/MediaTeknologi/EditView.vue'
import IndexViewMediaTeknologi from '../views/MasterData/MediaTeknologi/IndexView.vue'
import ShowViewMediaTeknologi from '../views/MasterData/MediaTeknologi/ShowView.vue'

import CreateViewKategoriJenisMatakuliah from '../views/MasterData/KategoriJenisMatakuliah/CreateView.vue'
import EditViewKategoriJenisMatakuliah from '../views/MasterData/KategoriJenisMatakuliah/EditView.vue'
import IndexViewKategoriJenisMatakuliah from '../views/MasterData/KategoriJenisMatakuliah/IndexView.vue'
import ShowViewKategoriJenisMatakuliah from '../views/MasterData/KategoriJenisMatakuliah/ShowView.vue'

import CreateViewJenisMatakuliah from '../views/MasterData/JenisMatakuliah/CreateView.vue'
import EditViewJenisMatakuliah from '../views/MasterData/JenisMatakuliah/EditView.vue'
import IndexViewJenisMatakuliah from '../views/MasterData/JenisMatakuliah/IndexView.vue'
import ShowViewJenisMatakuliah from '../views/MasterData/JenisMatakuliah/ShowView.vue'

import CreateViewRumpunMatakuliah from '../views/MasterData/RumpunMatakuliah/CreateView.vue'
import EditViewRumpunMatakuliah from '../views/MasterData/RumpunMatakuliah/EditView.vue'
import IndexViewRumpunMatakuliah from '../views/MasterData/RumpunMatakuliah/IndexView.vue'
import ShowViewRumpunMatakuliah from '../views/MasterData/RumpunMatakuliah/ShowView.vue'

import CreateViewMatakuliah from '../views/MasterData/Matakuliah/CreateView.vue'
import EditViewMatakuliah from '../views/MasterData/Matakuliah/EditView.vue'
import IndexViewMatakuliah from '../views/MasterData/Matakuliah/IndexView.vue'
import ShowViewMatakuliah from '../views/MasterData/Matakuliah/ShowView.vue'

import CreateViewIndikator from '../views/MasterData/Indikator/CreateView.vue'
import EditViewIndikator from '../views/MasterData/Indikator/EditView.vue'
import IndexViewIndikator from '../views/MasterData/Indikator/IndexView.vue'
import ShowViewIndikator from '../views/MasterData/Indikator/ShowView.vue'

import IndexViewDosen from '../views/MasterData/Dosen/IndexView.vue'
import ShowViewDosen from '../views/MasterData/Dosen/ShowView.vue'

import CreateViewTaksonomiBloom from '../views/MasterData/TaksonomiBloom/CreateView.vue'
import EditViewTaksonomiBloom from '../views/MasterData/TaksonomiBloom/EditView.vue'
import IndexViewTaksonomiBloom from '../views/MasterData/TaksonomiBloom/IndexView.vue'
import ShowViewTaksonomiBloom from '../views/MasterData/TaksonomiBloom/ShowView.vue'

import CreateViewTaksonomiBloomDetail from '../views/MasterData/TaksonomiBloomDetail/CreateView.vue'
import EditViewTaksonomiBloomDetail from '../views/MasterData/TaksonomiBloomDetail/EditView.vue'
import IndexViewTaksonomiBloomDetail from '../views/MasterData/TaksonomiBloomDetail/IndexView.vue'
import ShowViewTaksonomiBloomDetail from '../views/MasterData/TaksonomiBloomDetail/ShowView.vue'

import CreateViewBahasaPengantar from '../views/MasterData/BahasaPengantar/CreateView.vue'
import EditViewBahasaPengantar from '../views/MasterData/BahasaPengantar/EditView.vue'
import IndexViewBahasaPengantar from '../views/MasterData/BahasaPengantar/IndexView.vue'
import ShowViewBahasaPengantar from '../views/MasterData/BahasaPengantar/ShowView.vue'

import CreateViewSkemaBelajar from '../views/MasterData/SkemaBelajar/CreateView.vue'
import EditViewSkemaBelajar from '../views/MasterData/SkemaBelajar/EditView.vue'
import IndexViewSkemaBelajar from '../views/MasterData/SkemaBelajar/IndexView.vue'
import ShowViewSkemaBelajar from '../views/MasterData/SkemaBelajar/ShowView.vue'

import CreateViewStatusMonitoring from '../views/MasterData/StatusMonitoring/CreateView.vue'
import EditViewStatusMonitoring from '../views/MasterData/StatusMonitoring/EditView.vue'
import IndexViewStatusMonitoring from '../views/MasterData/StatusMonitoring/IndexView.vue'
import ShowViewStatusMonitoring from '../views/MasterData/StatusMonitoring/ShowView.vue'

import CreateViewStatusAkreditasi from '../views/MasterData/StatusAkreditasi/CreateView.vue'
import EditViewStatusAkreditasi from '../views/MasterData/StatusAkreditasi/EditView.vue'
import IndexViewStatusAkreditasi from '../views/MasterData/StatusAkreditasi/IndexView.vue'
import ShowViewStatusAkreditasi from '../views/MasterData/StatusAkreditasi/ShowView.vue'

import CreateViewLembagaNasional from '../views/MasterData/LembagaNasional/CreateView.vue'
import EditViewLembagaNasional from '../views/MasterData/LembagaNasional/EditView.vue'
import IndexViewLembagaNasional from '../views/MasterData/LembagaNasional/IndexView.vue'
import ShowViewLembagaNasional from '../views/MasterData/LembagaNasional/ShowView.vue'

import CreateViewLembagaInternasional from '../views/MasterData/LembagaInternasional/CreateView.vue'
import EditViewLembagaInternasional from '../views/MasterData/LembagaInternasional/EditView.vue'
import IndexViewLembagaInternasional from '../views/MasterData/LembagaInternasional/IndexView.vue'
import ShowViewLembagaInternasional from '../views/MasterData/LembagaInternasional/ShowView.vue'

import CreateViewTahunKe from '../views/MasterData/TahunKe/CreateView.vue'
import EditViewTahunKe from '../views/MasterData/TahunKe/EditView.vue'
import IndexViewTahunKe from '../views/MasterData/TahunKe/IndexView.vue'
import ShowViewTahunKe from '../views/MasterData/TahunKe/ShowView.vue'

import CreateViewSemester from '../views/MasterData/Semester/CreateView.vue'
import EditViewSemester from '../views/MasterData/Semester/EditView.vue'
import IndexViewSemester from '../views/MasterData/Semester/IndexView.vue'
import ShowViewSemester from '../views/MasterData/Semester/ShowView.vue'

import CreateViewPengelompokanKemampuan from '../views/MasterData/PengelompokanKemampuan/CreateView.vue'
import EditViewPengelompokanKemampuan from '../views/MasterData/PengelompokanKemampuan/EditView.vue'
import IndexViewPengelompokanKemampuan from '../views/MasterData/PengelompokanKemampuan/IndexView.vue'
import ShowViewPengelompokanKemampuan from '../views/MasterData/PengelompokanKemampuan/ShowView.vue'

import CreateViewMetodePembelajaran from '../views/MasterData/MetodePembelajaran/CreateView.vue'
import EditViewMetodePembelajaran from '../views/MasterData/MetodePembelajaran/EditView.vue'
import IndexViewMetodePembelajaran from '../views/MasterData/MetodePembelajaran/IndexView.vue'
import ShowViewMetodePembelajaran from '../views/MasterData/MetodePembelajaran/ShowView.vue'

import CreateViewGelar from '../views/MasterData/Gelar/CreateView.vue'
import EditViewGelar from '../views/MasterData/Gelar/EditView.vue'
import IndexViewGelar from '../views/MasterData/Gelar/IndexView.vue'
import ShowViewGelar from '../views/MasterData/Gelar/ShowView.vue'

import CreateViewIdentitasKurikulum from '../views/PembuatanKurikulum/IdentitasKurikulum/CreateView.vue'
import EditViewIdentitasKurikulum from '../views/PembuatanKurikulum/IdentitasKurikulum/EditView.vue'
import IndexViewIdentitasKurikulum from '../views/PembuatanKurikulum/IdentitasKurikulum/IndexView.vue'
import ShowViewIdentitasKurikulum from '../views/PembuatanKurikulum/IdentitasKurikulum/ShowView.vue'

import EditViewEvaluasiKurikulum from '../views/PembuatanKurikulum/EvaluasiKurikulum/EditView.vue'
import IndexViewEvaluasiKurikulum from '../views/PembuatanKurikulum/EvaluasiKurikulum/IndexView.vue'
import ShowViewEvaluasiKurikulum from '../views/PembuatanKurikulum/EvaluasiKurikulum/ShowView.vue'

import CreateViewProfilLulusan from '../views/PembuatanKurikulum/ProfilLulusan/CreateView.vue'
import EditViewProfilLulusan from '../views/PembuatanKurikulum/ProfilLulusan/EditView.vue'
import IndexViewProfilLulusan from '../views/PembuatanKurikulum/ProfilLulusan/IndexView.vue'
import ShowViewProfilLulusan from '../views/PembuatanKurikulum/ProfilLulusan/ShowView.vue'

import CreateViewTPP from '../views/PembuatanKurikulum/TPP/CreateView.vue'
import EditViewTPP from '../views/PembuatanKurikulum/TPP/EditView.vue'
import IndexViewTPP from '../views/PembuatanKurikulum/TPP/IndexView.vue'
import ShowViewTPP from '../views/PembuatanKurikulum/TPP/ShowView.vue'

import CreateViewCPL from '../views/PembuatanKurikulum/CPL/CreateView.vue'
import EditViewCPL from '../views/PembuatanKurikulum/CPL/EditView.vue'
import IndexViewCPL from '../views/PembuatanKurikulum/CPL/IndexView.vue'
import ShowViewCPL from '../views/PembuatanKurikulum/CPL/ShowView.vue'

import IndexViewMatriksCPLdanKK from '../views/PembuatanKurikulum/MatriksCPLdanKK/IndexView.vue'

import EditViewMatriksCPLdanTPP from '../views/PembuatanKurikulum/MatriksCPLdanTPP/EditView.vue'
import IndexViewMatriksCPLdanTPP from '../views/PembuatanKurikulum/MatriksCPLdanTPP/IndexView.vue'
import ShowViewMatriksCPLdanTPP from '../views/PembuatanKurikulum/MatriksCPLdanTPP/ShowView.vue'

import CreateViewBahanKajian from '../views/PembuatanKurikulum/BahanKajian/CreateView.vue'
import EditViewBahanKajian from '../views/PembuatanKurikulum/BahanKajian/EditView.vue'
import IndexViewBahanKajian from '../views/PembuatanKurikulum/BahanKajian/IndexView.vue'
import ShowViewBahanKajian from '../views/PembuatanKurikulum/BahanKajian/ShowView.vue'

import CreateViewMatakuliahKurikulum from '../views/PembuatanKurikulum/MatakuliahKurikulum/CreateView.vue'
import IndexViewMatakuliahKurikulum from '../views/PembuatanKurikulum/MatakuliahKurikulum/IndexView.vue'
import ShowViewMatakuliahKurikulum from '../views/PembuatanKurikulum/MatakuliahKurikulum/ShowView.vue'

import IndexViewMKKCPL from '../views/PembuatanKurikulum/MatrikMKKdanCPL/IndexView.vue'
// import CreateViewMKKCPL from '../views/PembuatanKurikulum/MatrikMKKdanCPL/CreateView.vue'
// import ShowViewMKKCPL from '../views/PembuatanKurikulum/MatrikMKKdanCPL/ShowView.vue'
// import EditViewMKKCPL from '../views/PembuatanKurikulum/MatrikMKKdanCPL/EditView.vue'

import IndexViewDiagramAlir from '../views/PembuatanKurikulum/DiagramAlir/IndexView.vue'

import IndexViewMatriksMKKdanBahanKajian from '../views/PembuatanKurikulum/MatriksMKKdanBahanKajian/IndexView.vue'

import IndexViewKirimKeReviewerKurikulum from '../views/PembuatanKurikulum/KirimKeReviewer/IndexView.vue'

import IndexViewInformasiUmum from '../views/PembuatanBRP/InformasiUmum/IndexView.vue'
import CreateViewInformasiUmum from '../views/PembuatanBRP/InformasiUmum/CreateView.vue'
import EditViewInformasiUmum from '../views/PembuatanBRP/InformasiUmum/EditView.vue'

import IndexViewRencanaPembelajaran from '../views/PembuatanBRP/RencanaPembelajaran/IndexView.vue'
import ShowViewRencanaPembelajaran from '../views/PembuatanBRP/RencanaPembelajaran/ShowView.vue'
import EditViewRencanaPembelajaran from '../views/PembuatanBRP/RencanaPembelajaran/EditView.vue'

import IndexViewRancanganTugas from '../views/PembuatanBRP/RancanganTugas/IndexView.vue'
import ShowViewRancanganTugas from '../views/PembuatanBRP/RancanganTugas/ShowView.vue'
import EditViewRancanganTugas from '../views/PembuatanBRP/RancanganTugas/EditView.vue'

import IndexViewKriteriaPenilaian from '../views/PembuatanBRP/KriteriaPenilaian/IndexView.vue'
import ShowViewKriteriaPenilaian from '../views/PembuatanBRP/KriteriaPenilaian/ShowView.vue'
import EditViewKriteriaPenilaian from '../views/PembuatanBRP/KriteriaPenilaian/EditView.vue'

import IndexViewRubrikPenilaian from '../views/PembuatanBRP/RubrikPenilaian/IndexView.vue'
import ShowViewRubrikPenilaian from '../views/PembuatanBRP/RubrikPenilaian/ShowView.vue'
import EditViewRubrikPenilaian from '../views/PembuatanBRP/RubrikPenilaian/EditView.vue'

import IndexViewLampiran from '../views/PembuatanBRP/Lampiran/IndexView.vue'
import ShowViewLampiran from '../views/PembuatanBRP/Lampiran/ShowView.vue'
import EditViewLampiran from '../views/PembuatanBRP/Lampiran/EditView.vue'

import IndexViewKirimKeReviewerBRP from '../views/PembuatanBRP/KirimKeReviewer/IndexView.vue'

import IndexViewUPMA from '../views/ReviewerKurikulum/UPMA/IndexView.vue'
import ShowViewUPMA from '../views/ReviewerKurikulum/UPMA/ShowView.vue'


import IndexViewReviewBRP from '../views/ReviewBRP/ReviewBRP/IndexView.vue'
import ShowViewReviewBRP from '../views/ReviewBRP/ReviewBRP/ShowView.vue'

import IndexViewSAF from '../views/ReviewerKurikulum/SAF/IndexView.vue'
import ShowViewSAF from '../views/ReviewerKurikulum/SAF/ShowView.vue'

import IndexViewRFakultas from '../views/ReviewerKurikulum/Fakultas/IndexView.vue'
import ShowViewRFakultas from '../views/ReviewerKurikulum/Fakultas/ShowView.vue'

import IndexViewDPASDP from '../views/ReviewerKurikulum/DPASDP/IndexView.vue'
import ShowViewDPASDP from '../views/ReviewerKurikulum/DPASDP/ShowView.vue'

import IndexViewReviewer1 from '../views/ReviewerKurikulum/Reviewer1/IndexView.vue'
import ShowViewReviewer1 from '../views/ReviewerKurikulum/Reviewer1/ShowView.vue'

import IndexViewReviewer2 from '../views/ReviewerKurikulum/Reviewer2/IndexView.vue'
import ShowViewReviewer2 from '../views/ReviewerKurikulum/Reviewer2/ShowView.vue'

import IndexViewMonitoringKurikulum from '../views/LaporanKurikulum/MonitoringKurikulum/IndexView.vue'
import ShowViewMonitoringKurikulum from '../views/LaporanKurikulum/MonitoringKurikulum/ShowView.vue'

import IndexViewPersetujuanBRP from '../views/ReviewBRP/PersetujuanBRP/IndexView.vue'

import IndexViewMonitoringBRP from '../views/LaporanBRP/MonitoringBRP/IndexView.vue'
import ShowViewMonitoringBRP from '../views/LaporanBRP/MonitoringBRP/ShowView.vue'

import IndexViewCetakBRP from '../views/LaporanBRP/CetakBRP/IndexView.vue'
import ShowViewCetakBRP from '../views/LaporanBRP/CetakBRP/ShowView.vue'

import IndexViewLapKriteriaPenilaian from '../views/LaporanBRP/KriteriaPenilaian/IndexView.vue'

import CreateViewRole from '../views/RoleManagement/Role/CreateView.vue'
import EditViewRole from '../views/RoleManagement/Role/EditView.vue'
import IndexViewRole from '../views/RoleManagement/Role/IndexView.vue'
import ShowViewRole from '../views/RoleManagement/Role/ShowView.vue'

import EditViewRoleMenu from '../views/RoleManagement/RoleMenu/EditView.vue'
import IndexViewRoleMenu from '../views/RoleManagement/RoleMenu/IndexView.vue'
import ShowViewRoleMenu from '../views/RoleManagement/RoleMenu/ShowView.vue'

import EditViewMenuPermission from '../views/RoleManagement/MenuPermission/EditView.vue'
import IndexViewMenuPermission from '../views/RoleManagement/MenuPermission/IndexView.vue'
import ShowViewMenuPermission from '../views/RoleManagement/MenuPermission/ShowView.vue'

import ForbiddenView from '../views/ForbiddenView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import UnauthorizedView from '../views/UnauthorizedView.vue'

const routes = [
	{ path: '/', name: 'Landing', component: LandingView },
	{
		path: '/',
		component: MainLayout,
		children: [
			{ path: '', name: 'Dashboard', component: DashboardView },
			{
				path: '/profil/perguruan-tinggi',
				name: 'PerguruanTinggi',
				component: IndexViewPerguruanTinggi
			},
			{
				path: '/profil/rumpun-ilmu',
				name: 'RumpunIlmu',
				component: IndexViewRumpunIlmu
			},
			{
				path: '/profil/rumpun-ilmu/create',
				name: 'CreateViewRumpunIlmu',
				component: CreateViewRumpunIlmu
			},
			{
				path: '/profil/rumpun-ilmu/show/:id',
				name: 'ShowViewRumpunIlmu',
				component: ShowViewRumpunIlmu
			},
			{
				path: '/profil/rumpun-ilmu/edit/:id',
				name: 'EditViewRumpunIlmu',
				component: EditViewRumpunIlmu
			},

			{
				path: '/profil/fakultas',
				name: 'Fakultas',
				component: IndexViewFakultas
			},
			{
				path: '/profil/fakultas/create',
				name: 'CreateViewFakultas',
				component: CreateViewFakultas
			},
			{
				path: '/profil/fakultas/show/:id',
				name: 'ShowViewFakultas',
				component: ShowViewFakultas
			},
			{
				path: '/profil/fakultas/edit/:id',
				name: 'EditViewFakultas',
				component: EditViewFakultas
			},

			{
				path: '/profil/departemen',
				name: 'Departemen',
				component: IndexViewDepartemen
			},
			{
				path: '/profil/departemen/create',
				name: 'CreateViewDepartemen',
				component: CreateViewDepartemen
			},
			{
				path: '/profil/departemen/show/:id',
				name: 'ShowViewDepartemen',
				component: ShowViewDepartemen
			},
			{
				path: '/profil/departemen/edit/:id',
				name: 'EditViewDepartemen',
				component: EditViewDepartemen
			},

			{
				path: '/profil/program-studi',
				name: 'ProgramStudi',
				component: IndexViewProgramStudi
			},
			{
				path: '/profil/program-studi/create',
				name: 'CreateViewProgramStudi',
				component: CreateViewProgramStudi
			},
			{
				path: '/profil/program-studi/show/:id',
				name: 'ShowViewProgramStudi',
				component: ShowViewProgramStudi
			},
			{
				path: '/profil/program-studi/edit/:id',
				name: 'EditViewProgramStudi',
				component: EditViewProgramStudi
			},

			{ path: '/profil/jenjang', name: 'Jenjang', component: IndexViewJenjang },
			{
				path: '/profil/jenjang/create',
				name: 'CreateViewJenjang',
				component: CreateViewJenjang
			},
			{
				path: '/profil/jenjang/show/:id',
				name: 'ShowViewJenjang',
				component: ShowViewJenjang
			},
			{
				path: '/profil/jenjang/edit/:id',
				name: 'EditViewJenjang',
				component: EditViewJenjang
			},

			{ path: '/profil/kelas', name: 'Kelas', component: IndexViewKelas },
			{
				path: '/profil/kelas/create',
				name: 'CreateViewKelas',
				component: CreateViewKelas
			},
			{
				path: '/profil/kelas/show/:id',
				name: 'ShowViewKelas',
				component: ShowViewKelas
			},
			{
				path: '/profil/kelas/edit/:id',
				name: 'EditViewKelas',
				component: EditViewKelas
			},

			{
				path: '/profil/jenis-semester',
				name: 'JenisSemester',
				component: IndexViewJenisSemester
			},
			{
				path: '/profil/jenis-semester/create',
				name: 'CreateViewJenisSemester',
				component: CreateViewJenisSemester
			},
			{
				path: '/profil/jenis-semester/show/:id',
				name: 'ShowViewJenisSemester',
				component: ShowViewJenisSemester
			},
			{
				path: '/profil/jenis-semester/edit/:id',
				name: 'EditViewJenisSemester',
				component: EditViewJenisSemester
			},

			// {
			// 	path: '/master-data/kategori-bahan-kajian',
			// 	name: 'KategoriBahanKajian',
			// 	component: IndexViewKategoriBahanKajian
			// },
			// {
			// 	path: '/master-data/kategori-bahan-kajian/create',
			// 	name: 'KategoriBahanKajianCreate',
			// 	component: CreateViewKategoriBahanKajian
			// },
			// {
			// 	path: '/master-data/kategori-bahan-kajian/show/:id',
			// 	name: 'KategoriBahanKajianShow',
			// 	component: ShowViewKategoriBahanKajian
			// },
			// {
			// 	path: '/master-data/kategori-bahan-kajian/edit/:id',
			// 	name: 'KategoriBahanKajianEdit',
			// 	component: EditViewKategoriBahanKajian
			// },

			{
				path: '/master-data/jenis-kompetensi',
				name: 'JenisKompetensi',
				component: IndexViewJenisKompetensi,
				meta: { title: 'Jenis Kompetensi' }
			},
			{
				path: '/master-data/jenis-kompetensi/create',
				name: 'CreateViewJenisKompetensi',
				component: CreateViewJenisKompetensi,
				meta: { title: 'Jenis Kompetensi' }
			},
			{
				path: '/master-data/jenis-kompetensi/show/:id',
				name: 'ShowViewJenisKompetensi',
				component: ShowViewJenisKompetensi,
				meta: { title: 'Jenis Kompetensi' }
			},
			{
				path: '/master-data/jenis-kompetensi/edit/:id',
				name: 'EditViewJenisKompetensi',
				component: EditViewJenisKompetensi,
				meta: { title: 'Jenis Kompetensi' }
			},

			{
				path: '/master-data/kompetensi',
				name: 'Kompetensi',
				component: IndexViewKompetensi,
				meta: { title: 'Kompetensi' }
			},
			{
				path: '/master-data/kompetensi/create',
				name: 'CreateViewKompetensi',
				component: CreateViewKompetensi,
				meta: { title: 'Kompetensi' }
			},
			{
				path: '/master-data/kompetensi/show/:id',
				name: 'ShowViewKompetensi',
				component: ShowViewKompetensi,
				meta: { title: 'Kompetensi' }
			},
			{
				path: '/master-data/kompetensi/edit/:id',
				name: 'EditViewKompetensi',
				component: EditViewKompetensi,
				meta: { title: 'Kompetensi' }
			},

			{
				path: '/master-data/bentuk-evaluasi',
				name: 'BentukEvaluasi',
				component: IndexViewBentukEvaluasi
			},
			{
				path: '/master-data/bentuk-evaluasi/create',
				name: 'CreateViewBentukEvaluasi',
				component: CreateViewBentukEvaluasi
			},
			{
				path: '/master-data/bentuk-evaluasi/show/:id',
				name: 'ShowViewBentukEvaluasi',
				component: ShowViewBentukEvaluasi
			},
			{
				path: '/master-data/bentuk-evaluasi/edit/:id',
				name: 'EditViewBentukEvaluasi',
				component: EditViewBentukEvaluasi
			},

			{
				path: '/master-data/bentuk-evaluasi-detail',
				name: 'BentukEvaluasiDetail',
				component: IndexViewBentukEvaluasiDetail
			},
			{
				path: '/master-data/bentuk-evaluasi-detail/create',
				name: 'CreateViewBentukEvaluasiDetail',
				component: CreateViewBentukEvaluasiDetail
			},
			{
				path: '/master-data/bentuk-evaluasi-detail/show/:id',
				name: 'ShowViewBentukEvaluasiDetail',
				component: ShowViewBentukEvaluasiDetail
			},
			{
				path: '/master-data/bentuk-evaluasi-detail/edit/:id',
				name: 'EditViewBentukEvaluasiDetail',
				component: EditViewBentukEvaluasiDetail
			},

			{
				path: '/master-data/jenis-kerangka-kerja',
				name: 'JenisKerangkaKerja',
				component: IndexViewJenisKerangkaKerja,
				meta: { title: 'Jenis Kerangka Kerja' }
			},
			{
				path: '/master-data/jenis-kerangka-kerja/create',
				name: 'CreateViewJenisKerangkaKerja',
				component: CreateViewJenisKerangkaKerja,
				meta: { title: 'Jenis Kerangka Kerja' }
			},
			{
				path: '/master-data/jenis-kerangka-kerja/show/:id',
				name: 'ShowViewJenisKerangkaKerja',
				component: ShowViewJenisKerangkaKerja,
				meta: { title: 'Jenis Kerangka Kerja' }
			},
			{
				path: '/master-data/jenis-kerangka-kerja/edit/:id',
				name: 'EditViewJenisKerangkaKerja',
				component: EditViewJenisKerangkaKerja,
				meta: { title: 'Jenis Kerangka Kerja' }
			},

			{
				path: '/master-data/kerangka-kerja',
				name: 'KerangkaKerja',
				component: IndexViewKerangkaKerja,
				meta: { title: 'Kerangka Kerja' }
			},
			{
				path: '/master-data/kerangka-kerja/create',
				name: 'CreateViewKerangkaKerja',
				component: CreateViewKerangkaKerja,
				meta: { title: 'Kerangka Kerja' }
			},
			{
				path: '/master-data/kerangka-kerja/show/:id',
				name: 'ShowViewKerangkaKerja',
				component: ShowViewKerangkaKerja,
				meta: { title: 'Kerangka Kerja' }
			},
			{
				path: '/master-data/kerangka-kerja/edit/:id',
				name: 'EditViewKerangkaKerja',
				component: EditViewKerangkaKerja,
				meta: { title: 'Kerangka Kerja' }
			},

			{
				path: '/master-data/kategori-tinjauan-pustaka',
				name: 'KategoriTinjauanPustaka',
				component: IndexViewKategoriTinjauanPustaka,
				meta: { title: 'Kategori Tinjauan Pustaka' }
			},
			{
				path: '/master-data/kategori-tinjauan-pustaka/create',
				name: 'CreateViewKategoriTinjauanPustaka',
				component: CreateViewKategoriTinjauanPustaka,
				meta: { title: 'Kategori Tinjauan Pustaka' }
			},
			{
				path: '/master-data/kategori-tinjauan-pustaka/show/:id',
				name: 'ShowViewKategoriTinjauanPustaka',
				component: ShowViewKategoriTinjauanPustaka,
				meta: { title: 'Kategori Tinjauan Pustaka' }
			},
			{
				path: '/master-data/kategori-tinjauan-pustaka/edit/:id',
				name: 'EditViewKategoriTinjauanPustaka',
				component: EditViewKategoriTinjauanPustaka,
				meta: { title: 'Kategori Tinjauan Pustaka' }
			},

			{
				path: '/master-data/tinjauan-pustaka',
				name: 'TinjauanPustaka',
				component: IndexViewTinjauanPustaka,
				meta: { title: 'Tinjauan Pustaka' }
			},
			{
				path: '/master-data/tinjauan-pustaka/create',
				name: 'CreateViewTinjauanPustaka',
				component: CreateViewTinjauanPustaka,
				meta: { title: 'Tinjauan Pustaka' }
			},
			{
				path: '/master-data/tinjauan-pustaka/show/:id',
				name: 'ShowViewTinjauanPustaka',
				component: ShowViewTinjauanPustaka,
				meta: { title: 'Tinjauan Pustaka' }
			},
			{
				path: '/master-data/tinjauan-pustaka/edit/:id',
				name: 'EditViewTinjauanPustaka',
				component: EditViewTinjauanPustaka,
				meta: { title: 'Tinjauan Pustaka' }
			},

			{
				path: '/master-data/aktivitas-pembelajaran',
				name: 'AktivitasPembelajaran',
				component: IndexViewAktivitasPembelajaran,
				meta: { title: 'Aktivitas Pembelajaran' }
			},
			{
				path: '/master-data/aktivitas-pembelajaran/create',
				name: 'CreateViewAktivitasPembelajaran',
				component: CreateViewAktivitasPembelajaran,
				meta: { title: 'Aktivitas Pembelajaran' }
			},
			{
				path: '/master-data/aktivitas-pembelajaran/show/:id',
				name: 'ShowViewAktivitasPembelajaran',
				component: ShowViewAktivitasPembelajaran,
				meta: { title: 'Aktivitas Pembelajaran' }
			},
			{
				path: '/master-data/aktivitas-pembelajaran/edit/:id',
				name: 'EditViewAktivitasPembelajaran',
				component: EditViewAktivitasPembelajaran,
				meta: { title: 'Aktivitas Pembelajaran' }
			},

			{
				path: '/master-data/media-teknologi',
				name: 'MediaTeknologi',
				component: IndexViewMediaTeknologi
			},
			{
				path: '/master-data/media-teknologi/create',
				name: 'CreateViewMediaTeknologi',
				component: CreateViewMediaTeknologi
			},
			{
				path: '/master-data/media-teknologi/show/:id',
				name: 'ShowViewMediaTeknologi',
				component: ShowViewMediaTeknologi
			},
			{
				path: '/master-data/media-teknologi/edit/:id',
				name: 'EditViewMediaTeknologi',
				component: EditViewMediaTeknologi
			},

			{
				path: '/master-data/kategori-jenis-matakuliah',
				name: 'KategoriJenisMatakuliah',
				component: IndexViewKategoriJenisMatakuliah,
				meta: { title: 'Kategori Jenis Matakuliah' }
			},
			{
				path: '/master-data/kategori-jenis-matakuliah/create',
				name: 'CreateViewKategoriJenisMatakuliah',
				component: CreateViewKategoriJenisMatakuliah,
				meta: { title: 'Kategori Jenis Matakuliah' }
			},
			{
				path: '/master-data/kategori-jenis-matakuliah/show/:id',
				name: 'ShowViewKategoriJenisMatakuliah',
				component: ShowViewKategoriJenisMatakuliah,
				meta: { title: 'Kategori Jenis Matakuliah' }
			},
			{
				path: '/master-data/kategori-jenis-matakuliah/edit/:id',
				name: 'EditViewKategoriJenisMatakuliah',
				component: EditViewKategoriJenisMatakuliah,
				meta: { title: 'Kategori Jenis Matakuliah' }
			},

			{
				path: '/master-data/jenis-matakuliah',
				name: 'JenisMatakuliah',
				component: IndexViewJenisMatakuliah,
				meta: { title: 'Jenis Matakuliah' }
			},
			{
				path: '/master-data/jenis-matakuliah/create',
				name: 'CreateViewJenisMatakuliah',
				component: CreateViewJenisMatakuliah,
				meta: { title: 'Jenis Matakuliah' }
			},
			{
				path: '/master-data/jenis-matakuliah/show/:id',
				name: 'ShowViewJenisMatakuliah',
				component: ShowViewJenisMatakuliah,
				meta: { title: 'Jenis Matakuliah' }
			},
			{
				path: '/master-data/jenis-matakuliah/edit/:id',
				name: 'EditViewJenisMatakuliah',
				component: EditViewJenisMatakuliah,
				meta: { title: 'Jenis Matakuliah' }
			},

			{
				path: '/master-data/rumpun-matakuliah',
				name: 'RumpunMatakuliah',
				component: IndexViewRumpunMatakuliah
			},
			{
				path: '/master-data/rumpun-matakuliah/create',
				name: 'CreateViewRumpunMatakuliah',
				component: CreateViewRumpunMatakuliah
			},
			{
				path: '/master-data/rumpun-matakuliah/show/:id',
				name: 'ShowViewRumpunMatakuliah',
				component: ShowViewRumpunMatakuliah
			},
			{
				path: '/master-data/rumpun-matakuliah/edit/:id',
				name: 'EditViewRumpunMatakuliah',
				component: EditViewRumpunMatakuliah
			},

			{
				path: '/master-data/matakuliah',
				name: 'Matakuliah',
				component: IndexViewMatakuliah
			},
			{
				path: '/master-data/matakuliah/create',
				name: 'CreateViewMatakuliah',
				component: CreateViewMatakuliah
			},
			{
				path: '/master-data/matakuliah/show/:id',
				name: 'ShowViewMatakuliah',
				component: ShowViewMatakuliah
			},
			{
				path: '/master-data/matakuliah/edit/:id',
				name: 'EditViewMatakuliah',
				component: EditViewMatakuliah
			},

			{
				path: '/master-data/indikator',
				name: 'Indikator',
				component: IndexViewIndikator
			},
			{
				path: '/master-data/indikator/create',
				name: 'CreateViewIndikator',
				component: CreateViewIndikator
			},
			{
				path: '/master-data/indikator/show/:id',
				name: 'ShowViewIndikator',
				component: ShowViewIndikator
			},
			{
				path: '/master-data/indikator/edit/:id',
				name: 'EditViewIndikator',
				component: EditViewIndikator
			},

			{ path: '/master-data/dosen', name: 'Dosen', component: IndexViewDosen },
			{
				path: '/master-data/dosen/show/:id',
				name: 'ShowViewDosen',
				component: ShowViewDosen
			},

			{
				path: '/master-data/taksonomi-bloom',
				name: 'TaksonomiBloom',
				component: IndexViewTaksonomiBloom
			},
			{
				path: '/master-data/taksonomi-bloom/create',
				name: 'CreateViewTaksonomiBloom',
				component: CreateViewTaksonomiBloom
			},
			{
				path: '/master-data/taksonomi-bloom/show/:id',
				name: 'ShowViewTaksonomiBloom',
				component: ShowViewTaksonomiBloom
			},
			{
				path: '/master-data/taksonomi-bloom/edit/:id',
				name: 'EditViewTaksonomiBloom',
				component: EditViewTaksonomiBloom
			},

			{
				path: '/master-data/taksonomi-bloom-detail',
				name: 'TaksonomiBloomDetail',
				component: IndexViewTaksonomiBloomDetail
			},
			{
				path: '/master-data/taksonomi-bloom-detail/create',
				name: 'CreateViewTaksonomiBloomDetail',
				component: CreateViewTaksonomiBloomDetail
			},
			{
				path: '/master-data/taksonomi-bloom-detail/show/:id',
				name: 'ShowViewTaksonomiBloomDetail',
				component: ShowViewTaksonomiBloomDetail
			},
			{
				path: '/master-data/taksonomi-bloom-detail/edit/:id',
				name: 'EditViewTaksonomiBloomDetail',
				component: EditViewTaksonomiBloomDetail
			},

			{
				path: '/master-data/bahasa-pengantar',
				name: 'BahasaPengantar',
				component: IndexViewBahasaPengantar,
				meta: { title: 'Bahasa Pengantar' }
			},
			{
				path: '/master-data/bahasa-pengantar/create',
				name: 'CreateViewBahasaPengantar',
				component: CreateViewBahasaPengantar,
				meta: { title: 'Bahasa Pengantar' }
			},
			{
				path: '/master-data/bahasa-pengantar/show/:id',
				name: 'ShowViewBahasaPengantar',
				component: ShowViewBahasaPengantar,
				meta: { title: 'Bahasa Pengantar' }
			},
			{
				path: '/master-data/bahasa-pengantar/edit/:id',
				name: 'EditViewBahasaPengantar',
				component: EditViewBahasaPengantar,
				meta: { title: 'Bahasa Pengantar' }
			},

			{
				path: '/master-data/skema-belajar',
				name: 'SkemaBelajar',
				component: IndexViewSkemaBelajar,
				meta: { title: 'Skema Belajar' }
			},
			{
				path: '/master-data/skema-belajar/create',
				name: 'CreateViewSkemaBelajar',
				component: CreateViewSkemaBelajar,
				meta: { title: 'Skema Belajar' }
			},
			{
				path: '/master-data/skema-belajar/show/:id',
				name: 'ShowViewSkemaBelajar',
				component: ShowViewSkemaBelajar,
				meta: { title: 'Skema Belajar' }
			},
			{
				path: '/master-data/skema-belajar/edit/:id',
				name: 'EditViewSkemaBelajar',
				component: EditViewSkemaBelajar,
				meta: { title: 'Skema Belajar' }
			},

			{
				path: '/master-data/status-monitoring',
				name: 'StatusMonitoring',
				component: IndexViewStatusMonitoring,
				meta: { title: 'Status Monitoring' }
			},
			{
				path: '/master-data/status-monitoring/create',
				name: 'CreateViewStatusMonitoring',
				component: CreateViewStatusMonitoring,
				meta: { title: 'Status Monitoring' }
			},
			{
				path: '/master-data/status-monitoring/show/:id',
				name: 'ShowViewStatusMonitoring',
				component: ShowViewStatusMonitoring,
				meta: { title: 'Status Monitoring' }
			},
			{
				path: '/master-data/status-monitoring/edit/:id',
				name: 'EditViewStatusMonitoring',
				component: EditViewStatusMonitoring,
				meta: { title: 'Status Monitoring' }
			},

			{
				path: '/master-data/status-akreditasi',
				name: 'StatusAkreditasi',
				component: IndexViewStatusAkreditasi
			},
			{
				path: '/master-data/status-akreditasi/create',
				name: 'CreateViewStatusAkreditasi',
				component: CreateViewStatusAkreditasi
			},
			{
				path: '/master-data/status-akreditasi/show/:id',
				name: 'ShowViewStatusAkreditasi',
				component: ShowViewStatusAkreditasi
			},
			{
				path: '/master-data/status-akreditasi/edit/:id',
				name: 'EditViewStatusAkreditasi',
				component: EditViewStatusAkreditasi
			},

			{
				path: '/master-data/lembaga-nasional',
				name: 'LembagaNasional',
				component: IndexViewLembagaNasional
			},
			{
				path: '/master-data/lembaga-nasional/create',
				name: 'CreateViewLembagaNasional',
				component: CreateViewLembagaNasional
			},
			{
				path: '/master-data/lembaga-nasional/show/:id',
				name: 'ShowViewLembagaNasional',
				component: ShowViewLembagaNasional
			},
			{
				path: '/master-data/lembaga-nasional/edit/:id',
				name: 'EditViewLembagaNasional',
				component: EditViewLembagaNasional
			},

			{
				path: '/master-data/lembaga-internasional',
				name: 'LembagaInternasional',
				component: IndexViewLembagaInternasional
			},
			{
				path: '/master-data/lembaga-internasional/create',
				name: 'CreateViewLembagaInternasional',
				component: CreateViewLembagaInternasional
			},
			{
				path: '/master-data/lembaga-internasional/show/:id',
				name: 'ShowViewLembagaInternasional',
				component: ShowViewLembagaInternasional
			},
			{
				path: '/master-data/lembaga-internasional/edit/:id',
				name: 'EditViewLembagaInternasional',
				component: EditViewLembagaInternasional
			},

			{
				path: '/master-data/tahun-ke',
				name: 'TahunKe',
				component: IndexViewTahunKe,
				meta: { title: 'Tahun Ke' }
			},
			{
				path: '/master-data/tahun-ke/create',
				name: 'CreateViewTahunKe',
				component: CreateViewTahunKe,
				meta: { title: 'Tahun Ke' }
			},
			{
				path: '/master-data/tahun-ke/show/:id',
				name: 'ShowViewTahunKe',
				component: ShowViewTahunKe,
				meta: { title: 'Tahun Ke' }
			},
			{
				path: '/master-data/tahun-ke/edit/:id',
				name: 'EditViewTahunKe',
				component: EditViewTahunKe,
				meta: { title: 'Tahun Ke' }
			},

			{
				path: '/master-data/semester',
				name: 'Semester',
				component: IndexViewSemester,
				meta: { title: 'Semester' }
			},
			{
				path: '/master-data/semester/create',
				name: 'CreateViewSemester',
				component: CreateViewSemester,
				meta: { title: 'Semester' }
			},
			{
				path: '/master-data/semester/show/:id',
				name: 'ShowViewSemester',
				component: ShowViewSemester,
				meta: { title: 'Semester' }
			},
			{
				path: '/master-data/semester/edit/:id',
				name: 'EditViewSemester',
				component: EditViewSemester,
				meta: { title: 'Semester' }
			},

			{
				path: '/master-data/pengelompokan-kemampuan',
				name: 'PengelompokanKemampuan',
				component: IndexViewPengelompokanKemampuan,
				meta: { title: 'Pengelompokan Kemampuan' }
			},
			{
				path: '/master-data/pengelompokan-kemampuan/create',
				name: 'CreateViewPengelompokanKemampuan',
				component: CreateViewPengelompokanKemampuan,
				meta: { title: 'Pengelompokan Kemampuan' }
			},
			{
				path: '/master-data/pengelompokan-kemampuan/show/:id',
				name: 'ShowViewPengelompokanKemampuan',
				component: ShowViewPengelompokanKemampuan,
				meta: { title: 'Pengelompokan Kemampuan' }
			},
			{
				path: '/master-data/pengelompokan-kemampuan/edit/:id',
				name: 'EditViewPengelompokanKemampuan',
				component: EditViewPengelompokanKemampuan,
				meta: { title: 'Pengelompokan Kemampuan' }
			},

			{
				path: '/master-data/metode-pembelajaran',
				name: 'MetodePembelajaran',
				component: IndexViewMetodePembelajaran,
				meta: { title: 'Metode Pembelajaran' }
			},
			{
				path: '/master-data/metode-pembelajaran/create',
				name: 'CreateViewMetodePembelajaran',
				component: CreateViewMetodePembelajaran,
				meta: { title: 'Metode Pembelajaran' }
			},
			{
				path: '/master-data/metode-pembelajaran/show/:id',
				name: 'ShowViewMetodePembelajaran',
				component: ShowViewMetodePembelajaran,
				meta: { title: 'Metode Pembelajaran' }
			},
			{
				path: '/master-data/metode-pembelajaran/edit/:id',
				name: 'EditViewMetodePembelajaran',
				component: EditViewMetodePembelajaran,
				meta: { title: 'Metode Pembelajaran' }
			},

			{
				path: '/master-data/gelar',
				name: 'Gelar',
				component: IndexViewGelar,
				meta: { title: 'Gelar' }
			},
			{
				path: '/master-data/gelar/create',
				name: 'CreateViewGelar',
				component: CreateViewGelar,
				meta: { title: 'Gelar' }
			},
			{
				path: '/master-data/gelar/show/:id',
				name: 'ShowViewGelar',
				component: ShowViewGelar,
				meta: { title: 'Gelar' }
			},
			{
				path: '/master-data/gelar/edit/:id',
				name: 'EditViewGelar',
				component: EditViewGelar,
				meta: { title: 'Gelar' }
			},

			{
				path: '/pembuatan-kurikulum/identitas-kurikulum',
				name: 'IdentitasKurikulum',
				component: IndexViewIdentitasKurikulum
			},
			{
				path: '/pembuatan-kurikulum/identitas-kurikulum/create',
				name: 'CreateViewIdentitasKurikulum',
				component: CreateViewIdentitasKurikulum
			},
			{
				path: '/pembuatan-kurikulum/identitas-kurikulum/show/:id',
				name: 'ShowViewIdentitasKurikulum',
				component: ShowViewIdentitasKurikulum
			},
			{
				path: '/pembuatan-kurikulum/identitas-kurikulum/edit/:id',
				name: 'EditViewIdentitasKurikulum',
				component: EditViewIdentitasKurikulum
			},

			{
				path: '/pembuatan-kurikulum/evaluasi-kurikulum',
				name: 'EvaluasiKurikulum',
				component: IndexViewEvaluasiKurikulum,
				meta: { title: 'Evaluasi Kurikulum' }
			},
			{
				path: '/pembuatan-kurikulum/evaluasi-kurikulum/show/:id',
				name: 'ShowViewEvaluasiKurikulum',
				component: ShowViewEvaluasiKurikulum,
				meta: { title: 'Evaluasi Kurikulum' }
			},
			{
				path: '/pembuatan-kurikulum/evaluasi-kurikulum/edit/:id',
				name: 'EditViewEvaluasiKurikulum',
				component: EditViewEvaluasiKurikulum,
				meta: { title: 'Evaluasi Kurikulum' }
			},

			{
				path: '/pembuatan-kurikulum/profil-lulusan',
				name: 'ProfilLulusan',
				component: IndexViewProfilLulusan,
				meta: { title: 'Profil Lulusan' }
			},
			{
				path: '/pembuatan-kurikulum/profil-lulusan/create',
				name: 'CreateViewProfilLulusan',
				component: CreateViewProfilLulusan,
				meta: { title: 'Profil Lulusan' }
			},
			{
				path: '/pembuatan-kurikulum/profil-lulusan/show/:id',
				name: 'ShowViewProfilLulusan',
				component: ShowViewProfilLulusan,
				meta: { title: 'Profil Lulusan' }
			},
			{
				path: '/pembuatan-kurikulum/profil-lulusan/edit/:id',
				name: 'EditViewProfilLulusan',
				component: EditViewProfilLulusan,
				meta: { title: 'Profil Lulusan' }
			},

			{
				path: '/pembuatan-kurikulum/tujuan-program-pendidikan',
				name: 'TPP',
				component: IndexViewTPP,
				meta: { title: 'Tujuan Program Pendidikan' }
			},
			{
				path: '/pembuatan-kurikulum/tujuan-program-pendidikan/create',
				name: 'CreateViewTPP',
				component: CreateViewTPP,
				meta: { title: 'Tujuan Program Pendidikan' }
			},
			{
				path: '/pembuatan-kurikulum/tujuan-program-pendidikan/show/:id',
				name: 'ShowViewTPP',
				component: ShowViewTPP,
				meta: { title: 'Tujuan Program Pendidikan' }
			},
			{
				path: '/pembuatan-kurikulum/tujuan-program-pendidikan/edit/:id',
				name: 'EditViewTPP',
				component: EditViewTPP,
				meta: { title: 'Tujuan Program Pendidikan' }
			},

			{
				path: '/pembuatan-kurikulum/capaian-pembelajaran-lulusan',
				name: 'CPL',
				component: IndexViewCPL,
				meta: { title: 'Capaian Pembelajaran Lulusan' }
			},
			{
				path: '/pembuatan-kurikulum/capaian-pembelajaran-lulusan/create',
				name: 'CreateViewCPL',
				component: CreateViewCPL,
				meta: { title: 'Capaian Pembelajaran Lulusan' }
			},
			{
				path: '/pembuatan-kurikulum/capaian-pembelajaran-lulusan/show/:id',
				name: 'ShowViewCPL',
				component: ShowViewCPL,
				meta: { title: 'Capaian Pembelajaran Lulusan' }
			},
			{
				path: '/pembuatan-kurikulum/capaian-pembelajaran-lulusan/edit/:id',
				name: 'EditViewCPL',
				component: EditViewCPL,
				meta: { title: 'Capaian Pembelajaran Lulusan' }
			},

			{
				path: '/pembuatan-kurikulum/matriks-cpl-dan-kk',
				name: 'MatriksCPLdanKK',
				component: IndexViewMatriksCPLdanKK,
				meta: { title: 'Matriks CPL & KK' }
			},

			{
				path: '/pembuatan-kurikulum/matriks-cpl-dan-tpp',
				name: 'MatriksCPLdanTPP',
				component: IndexViewMatriksCPLdanTPP,
				meta: { title: 'Matriks CPL & TPP' }
			},
			{
				path: '/pembuatan-kurikulum/matriks-cpl-dan-tpp/show/:kurikulum_id',
				name: 'ShowViewMatriksCPLdanTPP',
				component: ShowViewMatriksCPLdanTPP,
				meta: { title: 'Matriks CPL & TPP' }
			},
			{
				path: '/pembuatan-kurikulum/matriks-cpl-dan-tpp/edit/:kurikulum_id',
				name: 'EditViewMatriksCPLdanTPP',
				component: EditViewMatriksCPLdanTPP,
				meta: { title: 'Matriks CPL & TPP' }
			},

			{
				path: '/pembuatan-kurikulum/bahan-kajian',
				name: 'BahanKajian',
				component: IndexViewBahanKajian,
				meta: { title: 'Bahan Kajian' }
			},
			{
				path: '/pembuatan-kurikulum/bahan-kajian/create',
				name: 'CreateViewBahanKajian',
				component: CreateViewBahanKajian,
				meta: { title: 'Bahan Kajian' }
			},
			{
				path: '/pembuatan-kurikulum/bahan-kajian/show/:id',
				name: 'ShowViewBahanKajian',
				component: ShowViewBahanKajian,
				meta: { title: 'Bahan Kajian' }
			},
			{
				path: '/pembuatan-kurikulum/bahan-kajian/edit/:id',
				name: 'EditViewBahanKajian',
				component: EditViewBahanKajian,
				meta: { title: 'Bahan Kajian' }
			},

			{
				path: '/pembuatan-kurikulum/matakuliah-kurikulum',
				name: 'MatakuliahKurikulum',
				component: IndexViewMatakuliahKurikulum
			},
			{
				path: '/pembuatan-kurikulum/matakuliah-kurikulum/create',
				name: 'CreateViewMatakuliahKurikulum',
				component: CreateViewMatakuliahKurikulum
			},
			{
				path: '/pembuatan-kurikulum/matakuliah-kurikulum/show/:id',
				name: 'ShowViewMatakuliahKurikulum',
				component: ShowViewMatakuliahKurikulum
			},

			{
				path: '/pembuatan-kurikulum/matrik-mkk-dan-cpl',
				name: 'MatriksMKKdanCPL',
				component: IndexViewMKKCPL
			},
			// { path: '/pembuatan-kurikulum/matrik-mkk-dan-cpl/create', name: 'MKKCPLCreate', component: CreateViewMKKCPL },
			// { path: '/pembuatan-kurikulum/matrik-mkk-dan-cpl/show/:id', name: 'MKKCPLShow', component: ShowViewMKKCPL },
			// { path: '/pembuatan-kurikulum/matrik-mkk-dan-cpl/edit/:id', name: 'MKKCPLEdit', component: EditViewMKKCPL },

			{
				path: '/pembuatan-kurikulum/diagram-alir',
				name: 'DiagramAlir',
				component: IndexViewDiagramAlir
			},

			{
				path: '/pembuatan-kurikulum/matriks-mkk-dan-bahan-kajian',
				name: 'MatriksMKKdanBahanKajian',
				component: IndexViewMatriksMKKdanBahanKajian,
				meta: { title: 'Matriks MKK & Bahan Kajian' }
			},

			{
				path: '/pembuatan-kurikulum/kirim-ke-reviewer-kurikulum',
				name: 'KirimKeReviewerKurikulum',
				component: IndexViewKirimKeReviewerKurikulum,
				meta: { title: 'Kirim Ke Reviewer Kurikulum' }
			},

			{
				path: '/pembuatan-brp/informasi-umum',
				name: 'InformasiUmum',
				component: IndexViewInformasiUmum,
				meta: { title: 'BRP - Informasi Umum' }
			},

			{
				path: '/pembuatan-brp/informasi-umum/create/:id/:target',
				name: 'CreateViewInformasiUmum',
				component: CreateViewInformasiUmum
			},

			{
				path: '/pembuatan-brp/informasi-umum/edit/:id/:target',
				name: 'EditViewInformasiUmum',
				component: EditViewInformasiUmum,
				meta: { title: 'BRP - Informasi Umum - Edit' }
			},

			{
				path: '/pembuatan-brp/rencana-pembelajaran',
				name: 'RencanaPembelajaran',
				component: IndexViewRencanaPembelajaran,
				meta: { title: 'BRP - Rencana Pembelajaran' }
			},

			{
				path: '/pembuatan-brp/rencana-pembelajaran/show/:brp_id',
				name: 'ShowViewRencanaPembelajaran',
				component: ShowViewRencanaPembelajaran,
				meta: { title: 'BRP - Rencana Pembelajaran' }
			},

			{
				path: '/pembuatan-brp/rencana-pembelajaran/edit/:brp_id',
				name: 'EditViewRencanaPembelajaran',
				component: EditViewRencanaPembelajaran,
				meta: { title: 'BRP - Rencana Pembelajaran' }
			},
			
			{
				path: '/pembuatan-brp/rancangan-tugas',
				name: 'RancanganTugas',
				component: IndexViewRancanganTugas,
				meta: { title: 'BRP - Rancangan Tugas' }
			},

			{
				path: '/pembuatan-brp/rancangan-tugas/show/:brp_id',
				name: 'ShowViewRancanganTugas',
				component: ShowViewRancanganTugas,
				meta: { title: 'BRP - Rancangan Tugas' }
			},

			{
				path: '/pembuatan-brp/rancangan-tugas/edit/:brp_id',
				name: 'EditViewRancanganTugas',
				component: EditViewRancanganTugas,
				meta: { title: 'BRP - Rancangan Tugas' }
			},

			{
				path: '/pembuatan-brp/kriteria-penilaian',
				name: 'KriteriaPenilaian',
				component: IndexViewKriteriaPenilaian,
				meta: { title: 'BRP - Kriteria Penilaian' }
			},

			{
				path: '/pembuatan-brp/kriteria-penilaian/show/:brp_id',
				name: 'ShowViewKriteriaPenilaian',
				component: ShowViewKriteriaPenilaian,
				meta: { title: 'BRP - Kriteria Penilaian' }
			},

			{
				path: '/pembuatan-brp/kriteria-penilaian/edit/:brp_id',
				name: 'EditViewKriteriaPenilaian',
				component: EditViewKriteriaPenilaian,
				meta: { title: 'BRP - Kriteria Penilaian' }
			},

			{
				path: '/pembuatan-brp/rubrik-penilaian',
				name: 'RubrikPenilaian',
				component: IndexViewRubrikPenilaian,
				meta: { title: 'BRP - Rubrik Penilaian' }
			},

			{
				path: '/pembuatan-brp/rubrik-penilaian/show/:id',
				name: 'ShowViewRubrikPenilaian',
				component: ShowViewRubrikPenilaian,
				meta: { title: 'BRP - Rubrik Penilaian' }
			},

			{
				path: '/pembuatan-brp/rubrik-penilaian/edit/:id',
				name: 'EditViewRubrikPenilaian',
				component: EditViewRubrikPenilaian,
				meta: { title: 'BRP - Rubrik Penilaian' }
			},

			{
				path: '/pembuatan-brp/lampiran',
				name: 'Lampiran',
				component: IndexViewLampiran,
				meta: { title: 'BRP - Lampiran' }
			},

			{
				path: '/pembuatan-brp/lampiran/show/:id',
				name: 'ShowViewLampiran',
				component: ShowViewLampiran,
				meta: { title: 'BRP - Lampiran' }
			},

			{
				path: '/pembuatan-brp/lampiran/edit/:id',
				name: 'EditViewLampiran',
				component: EditViewLampiran,
				meta: { title: 'BRP - Lampiran' }
			},

			{
				path: '/pembuatan-brp/kirim-ke-reviewer-brp',
				name: 'KirimKeReviewerBRP',
				component: IndexViewKirimKeReviewerBRP,
				meta: { title: 'Kirim Ke Reviewer BRP' }
			},

			{
				path: '/review-kurikulum/upma',
				name: 'UPMA',
				component: IndexViewUPMA,
				meta: { title: 'Review UPMA' }
			},

			{
				path: '/review-kurikulum/upma/show/:id',
				name: 'ShowViewUPMA',
				component: ShowViewUPMA,
				meta: { title: 'Review UPMA' }
			},

			{
				path: '/review-kurikulum/saf',
				name: 'SAF',
				component: IndexViewSAF,
				meta: { title: 'Review SAF' }
			},
			{
				path: '/review-kurikulum/saf/show/:id',
				name: 'ShowViewSAF',
				component: ShowViewSAF,
				meta: { title: 'Review SAF' }
			},

			{
				path: '/review-kurikulum/fakultas',
				name: 'RFakultas',
				component: IndexViewRFakultas,
				meta: { title: 'Review Fakultas' }
			},
			{
				path: '/review-kurikulum/fakultas/show/:id',
				name: 'ShowViewRFakultas',
				component: ShowViewRFakultas,
				meta: { title: 'Review Fakultas' }
			},

			{
				path: '/review-kurikulum/dpasdp',
				name: 'DPASDP',
				component: IndexViewDPASDP,
				meta: { title: 'Review DPASDP' }
			},
			{
				path: '/review-kurikulum/dpasdp/show/:id',
				name: 'ShowViewDPASDP',
				component: ShowViewDPASDP,
				meta: { title: 'Review DPASDP' }
			},

			{
				path: '/review-kurikulum/reviewer-1',
				name: 'Reviewer1',
				component: IndexViewReviewer1,
				meta: { title: 'Review - Review 1' }
			},
			{
				path: '/review-kurikulum/reviewer-1/show/:id',
				name: 'ShowViewReviewer1',
				component: ShowViewReviewer1,
				meta: { title: 'Review - Review 1' }
			},

			{
				path: '/review-kurikulum/reviewer-2',
				name: 'Reviewer2',
				component: IndexViewReviewer2,
				meta: { title: 'Review - Review 2' }
			},
			{
				path: '/review-kurikulum/reviewer-2/show/:id',
				name: 'ShowViewReviewer2',
				component: ShowViewReviewer2,
				meta: { title: 'Review - Review 2' }
			},
			{
				path: '/laporan-kurikulum/monitoring-kurikulum',
				name: 'MonitoringKurikulum',
				component: IndexViewMonitoringKurikulum,
				meta: { title: 'Monitoring Kurikulum' }
			},
			{
				path: '/laporan-kurikulum/monitoring-kurikulum/show/:kurikulum_id',
				name: 'ShowViewMonitoringKurikulum',
				component: ShowViewMonitoringKurikulum,
				meta: { title: 'Detail Monitoring Kurikulum' }
			},
			{
				path: '/review-brp/persetujuan-brp',
				name: 'PersetujuanBRP',
				component: IndexViewPersetujuanBRP,
				meta: { title: 'Persetujuan BRP' }
			},

			{
				path: '/review-brp/review-brp',
				name: 'ReviewBRP',
				component: IndexViewReviewBRP,
				meta: { title: 'BRP - Review BRP' }
			},

			{
				path: '/laporan-brp/monitoring-brp',
				name: 'MonitoringBRP',
				component: IndexViewMonitoringBRP,
				meta: { title: 'Monitoring BRP' }
			},
			{
				path: '/laporan-brp/monitoring-brp/show/:brp_id',
				name: 'ShowViewMonitoringBRP',
				component: ShowViewMonitoringBRP,
				meta: { title: 'Detail Monitoring BRP' }
			},
			{
				path: '/laporan-brp/cetak-brp',
				name: 'CetakBRP',
				component: IndexViewCetakBRP,
				meta: { title: 'Cetak BRP' }
			},
			{
				path: '/laporan-brp/cetak-brp/show/:brp_id',
				name: 'ShowViewCetakBRP',
				component: ShowViewCetakBRP,
				meta: { title: 'Detail Cetak BRP' }
			},

			{
				path: '/laporan-brp/kriteria-penilaian',
				name: 'LapKriteriaPenilaian',
				component: IndexViewLapKriteriaPenilaian,
				meta: { title: 'Laporan Kriteria Penilaian' }
			},

			{
				path: '/review-brp/review-brp/show/:id',
				name: 'ShowViewReviewBRP',
				component: ShowViewReviewBRP,
				meta: { title: 'BRP - Review BRP' }
			},

			{
				path: '/role-management/role',
				name: 'Role',
				component: IndexViewRole,
				meta: {
					title: 'Role'
				}
			},
			{
				path: '/role-management/role/create',
				name: 'CreateViewRole',
				component: CreateViewRole,
				meta: {
					title: 'Create Role'
				}
			},
			{
				path: '/role-management/role/show/:id',
				name: 'ShowViewRole',
				component: ShowViewRole,
				meta: {
					title: 'Detail Role'
				}
			},
			{
				path: '/role-management/role/edit/:id',
				name: 'EditViewRole',
				component: EditViewRole,
				meta: {
					title: 'Edit Role'
				}
			},

			{
				path: '/role-management/role-menu',
				name: 'RoleMenu',
				component: IndexViewRoleMenu,
				meta: {
					title: 'Role Menu'
				}
			},
			{
				path: '/role-management/role-menu/show/:id',
				name: 'ShowViewRoleMenu',
				component: ShowViewRoleMenu,
				meta: {
					title: 'Detail Role Menu'
				}
			},
			{
				path: '/role-management/role-menu/edit/:id',
				name: 'EditViewRoleMenu',
				component: EditViewRoleMenu,
				meta: {
					title: 'Edit Role Menu'
				}
			},

			{
				path: '/role-management/menu-permission',
				name: 'MenuPermission',
				component: IndexViewMenuPermission,
				meta: {
					title: 'Menu Permission'
				}
			},
			{
				path: '/role-management/menu-permission/show/:id',
				name: 'ShowViewMenuPermission',
				component: ShowViewMenuPermission,
				meta: {
					title: 'Detail Menu Permission'
				}
			},
			{
				path: '/role-management/menu-permission/edit/:id',
				name: 'EditViewMenuPermission',
				component: EditViewMenuPermission,
				meta: {
					title: 'Edit Menu Permission'
				}
			},

			{
				path: '/forbidden',
				name: 'Forbidden',
				component: ForbiddenView,
				meta: {
					title: 'Forbidden'
				}
			},

			{
				path: '/unauthorized',
				name: 'Unauthorized',
				component: UnauthorizedView,
				meta: {
					title: 'Unauthorized'
				}
			},

			{
				path: '/:pathMatch(.*)*',
				component: NotFoundView,
				meta: {
					title: 'Not Found'
				}
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.VUE_APP_BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {

	const authStore = useAuthStore()
	const accessStore = useAccessStore()
	const appName = process.env.VUE_APP_TITLE
	document.title = `${appName ? (to.meta.title ? appName + ' | ' + to.meta.title : appName) : 'SLCM - UI'}`
	const authenticatedPage = to.name !== 'Forbidden' && to.name !== 'Unauthorized' && to.name !== 'Landing'

	if (Object.keys(to.query).length > 0 || to.hash) {
		next({
			path: to.path,
			replace: true
		})
		return
	}

	// check permission
	if (authStore.authenticated && accessStore.menu.length > 0 && authenticatedPage) {
		const hasPermission = checkHasPermission(to.name)
		if (!hasPermission) {
			next({ name: 'Forbidden' })
			return
		}
	}
	if (to.name !== 'Landing' && !authStore.authenticated) {
		next({ name: 'Landing' })
		return
	}

	if (to.name === 'Landing' && authStore.authenticated) {
		next({ name: 'Dashboard' })
		return
	}

	next()
})

export default router
