<template>
	<breadcrumb-content menu="Master Data" subMenu="Gelar" />

	<div class="card mb-5 mb-xxl-8">
		<div class="pb-10">
			<div class="card-body pt-9 pb-0">
				<h1 class="anchor fw-bold mb-5">Tambah Gelar</h1>
				<div class="p-5">
					<Form
						id="form-gelar-create"
						class="kt-form"
						method="post"
						@submit="onSubmit"
						:validation-schema="schema"
						v-slot="{ errors }"
					>
						<LoadingOverlay :loading="loading" />

						<FormInput
							labelInd="Sebutan Gelar"
							labelEng="Title designation"
							columnName="sebutan"
							:errorInd="errors.sebutan_ind"
							:errorEng="errors.sebutan_eng"
						/>
						<FormInput
							labelInd="Singkatan Gelar"
							labelEng="Degree Abbreviation"
							columnName="singkatan"
							:errorInd="errors.singkatan_ind"
							:errorEng="errors.singkatan_eng"
						/>

						<div class="mt-10">
							<button type="submit" class="btn btn-primary">Simpan</button>
							&nbsp;
							<button @click="redirectToDataList" class="btn btn-secondary">Kembali</button>
						</div>
					</Form>
				</div>
			</div>
		</div>
	</div>
</template>


<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { Form, configure } from 'vee-validate'
import LoadingOverlay from '@/components/LoadingOverlay'
import { sendRequest } from '@/api/common'

import FormInput from '@/components/FormInput.vue'

const schema = {
	sebutan_ind: 'required',
	sebutan_eng: 'required',
	singkatan_ind: 'required',
	singkatan_eng: 'required',
}

const loading = ref(false)
const router = useRouter()

const redirectToDataList = () => router.push({ name: 'Gelar' })

const onSubmit = async values => {
	const param = {
		url: '/gelar',
		method: 'POST',
		body: values,
		loading: loading,
		context: 'Gelar'
	}

	return await sendRequest(param)
}

// custom message error
import { localize } from '@vee-validate/i18n'
const validationConfig = {
	id: {
		names: {
			sebutan_ind: 'Sebutan Gelar',
			sebutan_eng: 'Title Designation',
			singkatan_ind: 'Singkatan Gelar',
			singkatan_eng: 'Degree Abbreviation',
		}
	}
}
configure({ generateMessage: localize(validationConfig) })
</script>