<template>
   <breadcrumb-content menu="Profil" subMenu="Departemen" />
   <div class="card mb-5 mb-xxl-8">
      <div class="pb-10">
         <div class="card-body pt-9 pb-0">
            <h1 class="anchor fw-bold mb-5">Ubah Departemen</h1>
            <div class="p-5">
               <Form id="form-departemen-update" class="kt-form" method="post" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <LoadingOverlay :loading="loading" />
                  <div class="row mb-5">
                     <label class="form-label">Perguruan Tinggi</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg university" data-control="select2" data-placeholder="-- Pilih Perguruan Tinggi --" v-model="universityDetail.pt_id">
                           <option></option>
                           <option :key="university.pt_id" :value="university.pt_id">{{ university.nama_ind }}</option>
                        </select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="universityDetail.nama_eng" placeholder="Input University" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Rumpun Ilmu</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg group" data-control="select2" data-placeholder="-- Pilih Rumpun Ilmu --" v-model="groupDetail.ri_id">
									<option></option>
									<option v-for="(row, index) in group" :key="index" :value="row.ri_id">{{ row.nama_ind }}</option>
								</select>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="groupDetail.nama_eng" placeholder="Input Group of Knowledge" readonly />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Fakultas</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <select class="form-control form-control-solid-bg faculty" data-control="select2" data-placeholder="-- Pilih Fakultas --" v-model="facultyDetail.fakultas_id">
									<option></option>
									<option v-for="(row, index) in faculty" :key="index" :value="row.fakultas_id">{{ row.nama_ind }}</option>facultyDetail
								</select>
                        <Field type="hidden" name="fakultas_id_fk" class="form-control form-control-solid-bg" v-model="facultyDetail.fakultas_id" readonly />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <input type="text" class="form-control form-control-solid-bg bg-light-dark" v-model="facultyDetail.nama_eng" placeholder="Input Faculty" readonly>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label"><span style="color:red">*</span> Kode Departemen</label>
                     <div class="col-6">
                        <Field type="text" name="departemen_kd" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.departemen_kd }" v-model="department.departemen_kd" placeholder="Masukkan Kode Departemen" />
                        <div id="departemen_kd-error" class="invalid-feedback">{{ errors.departemen_kd }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">
                        <span style="color: red">*</span> Nama Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field type="text" name="nama_ind" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_ind }" v-model="department.nama_ind" placeholder="Masukkan Nama Departemen" />
                        <div id="nama_ind-error" class="invalid-feedback">{{ errors.nama_ind }}</div>
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field type="text" name="nama_eng" class="form-control form-control-solid-bg" :class="{ 'is-invalid': errors.nama_eng }" v-model="department.nama_eng" placeholder="Input Department Name" />
                        <div id="nama_eng-error" class="invalid-feedback">{{ errors.nama_eng }}</div>
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Deskripsi Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="deskripsi_ind" class="form-control form-control-solid-bg" rows="3" v-model="department.deskripsi_ind" placeholder="Masukkan Deskripsi Departemen" />
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="deskripsi_eng" class="form-control form-control-solid-bg" rows="3" v-model="department.deskripsi_eng" placeholder="Input Department Description" />
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Visi Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="visi_ind" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.visi_ind }" v-model="department.visi_ind" placeholder="Masukkan Visi Departemen" />
                        <!-- <div id="visi_ind-error" class="invalid-feedback">{{ errors.visi_ind }}</div> -->
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="visi_eng" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.visi_eng }" v-model="department.visi_eng" placeholder="Input Department Vision" />
                        <!-- <div id="visi_eng-error" class="invalid-feedback">{{ errors.visi_eng }}</div> -->
                     </div>
                  </div>
                  <div class="row mb-5">
                     <label class="form-label">Misi Departemen</label>
                     <div class="col-6">
                        <flag-image bahasa="Indonesia" />
                        <Field as="textarea" name="misi_ind" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.misi_ind }" v-model="department.misi_ind" placeholder="Masukkan Misi Departemen" />
                        <!-- <div id="misi_ind-error" class="invalid-feedback">{{ errors.misi_ind }}</div> -->
                     </div>
                     <div class="col-6">
                        <flag-image bahasa="Inggris" />
                        <Field as="textarea" name="misi_eng" class="form-control form-control-solid-bg" rows="3" :class="{ 'is-invalid': errors.misi_eng }" v-model="department.misi_eng" placeholder="Input Department Mission" />
                        <!-- <div id="misi_eng-error" class="invalid-feedback">{{ errors.misi_eng }}</div> -->
                     </div>
                  </div>
                  <div class="mt-10">
                     <div class="col-lg-6">
                        <button type="submit" class="btn btn-primary">Simpan</button> &nbsp; <button @click="redirectToDepartemenList" class="btn btn-secondary">Kembali</button>
                     </div>
                  </div>
               </Form>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, ref } from 'vue';
	import { useRouter } from 'vue-router';
	import { Form, Field } from 'vee-validate';
	import LoadingOverlay from '@/components/LoadingOverlay';
	import { sendRequest } from '@/api/common';
	import request from '@/utils/request';

	const schema = { departemen_kd: 'required', nama_ind: 'required|max:200', nama_eng: 'required|max:200' };
	const loading = ref(true);
	const router = useRouter();
	const id = router.currentRoute.value.params.id;
	const department = ref({});
	const faculty = ref({});
	const facultyDetail = ref({});
	const group = ref({});
	const groupDetail = ref({});
	const university = ref({});
	const universityDetail = ref({});
	const redirectToDepartemenList = () => router.push({ name: 'Departemen' });
	const handleElSelect = (async ({ target, self, child, point, handleSelectObject }, event) => {
		const el = event.target;
		handleSelect(handleSelectObject);
		handleSelectedOption({ url: ((target == '/pt') ? (target) : (`${ target }${ el.value }`)), self, child, point });
	});
	const handleSelectedOption = (async ({ url, self, child, point }) => {
		eval(`${ self }Detail`).value = (await request({ url })).data;
      if (child && point) eval(child).value = eval(`${ self }Detail`).value[point];
	});
	const handleSelect = (({ universitySelect = false, groupSelect = false, facultySelect = false }) => {
		if (!universitySelect) { university.value = {}; universityDetail.value = {}; }
		if (!groupSelect) { group.value = {}; groupDetail.value = {}; }
		if (!facultySelect) { faculty.value = {}; facultyDetail.value = {}; }
	});
	const onSubmit = (async (values) => await sendRequest({ url: `/departemen/${ id }`, method: 'PUT', body: values, loading: loading, context: 'Departemen' }) );

	onMounted(async () => {
		try {
			university.value = universityDetail.value = (await request(`/pt`)).data;
			department.value = (await request(`/departemen/${ id }`)).data;
			facultyDetail.value = (await request(`/fakultas/${ department.value.fakultas_id_fk }`)).data;
			groupDetail.value = (await request(`/ri/${ facultyDetail.value.ri_id_fk }`)).data;
			group.value = university.value.rumpun_ilmu;
			faculty.value = groupDetail.value.fakultas;

			// eslint-disable-next-line no-undef
         $('select.university').select2().on('change', (event) => { handleElSelect({ target: '/pt', self: 'university', child: 'group', point: 'rumpun_ilmu', handleSelectObject: { universitySelect: true } }, event); });

         // eslint-disable-next-line no-undef
         $('select.group').select2().on('change', (event) => { handleElSelect({ target: '/ri/', self: 'group', child: 'faculty', point: 'fakultas', handleSelectObject: { universitySelect: true, groupSelect: true } }, event); });

         // eslint-disable-next-line no-undef
         $('select.faculty').select2().on('change', (event) => { handleElSelect({ target: '/fakultas/', self: 'faculty', handleSelectObject: { universitySelect: true, groupSelect: true, facultySelect: true } }, event); });
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});
</script>