<template>
   <div class="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-5 mt-lg-20 gap-2" @click="handleReview">
      <button id="kt_engage_demos_toggle" class="engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0" title="Berikan Penilaian" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-dismiss="click" data-bs-trigger="hover">
         <span id="kt_engage_demos_label">Form Review SAF</span>
      </button>
   </div>
   <div id="kt_engage_demos" class="bg-body" data-kt-drawer="true" data-kt-drawer-name="explore" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-width="1000px" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_engage_demos_toggle" data-kt-drawer-close="#kt_engage_demos_close">
      <div class="card shadow-none rounded-0 w-100">
         <div class="card-header" id="kt_engage_demos_header">
            <h3 class="card-title fw-bold text-gray-700"></h3>
            <div class="card-toolbar">
               <button type="button" class="btn btn-sm btn-icon btn-active-color-primary h-40px w-40px me-n6" id="kt_engage_demos_close">
                  <span class="svg-icon svg-icon-2">
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                     </svg>
                  </span>
               </button>
            </div>
         </div>
         <div class="card-body" id="kt_engage_demos_body">
            <div v-if="DrawerSliderBody">
               <component :is="DrawerSliderBody" />
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
	import { onMounted, onBeforeUnmount, ref, shallowRef } from 'vue';
   import request from '@/utils/request';

   const loading = ref(true);
   const data = ref({ Parent: [], Child: [], Point: [] });
   let DrawerSliderBody = shallowRef(null);
   let drawerInstance = ref(null);
   const handleReview = (() => {
      DrawerSliderBody.value = null;
      import(`./DrawerSliderBody.vue`).then((module) => (DrawerSliderBody.value = module.default) );
   });

	onMounted(async () => {
		try {
         data.value.Parent = ((await request({ url: `/rvwindikatorh?per_page=-1` })).data.data).sort((a, b) => (a.rvw_indikator_h_id - b.rvw_indikator_h_id) );
         data.value.Child = ((await request({ url: `/rvwindikatord?per_page=-1` })).data.data).sort((a, b) => (a.rvw_indikator_d_id - b.rvw_indikator_d_id) );
         data.value.Point = ((await request({ url: `/rubrikpenilaian?per_page=-1` })).data.data).sort((a, b) => (b.rubrik_penilaian_id - a.rubrik_penilaian_id) );

         const drawerElement = document.getElementById('kt_engage_demos');
         if (drawerElement) {
            drawerInstance.value = window.KTDrawer.getInstance(drawerElement) || window.KTDrawer.createInstances();
         }
		} catch (error) {
			console.error('Error fetching data: ', error);
		} finally {
			loading.value = false;
		}
	});

   onBeforeUnmount(() => {
      if (drawerInstance.value) {
         drawerInstance.value.destroy();
      }
   });
</script>