<template>
	<LoadingOverlay :loading="loading" :stylePositionFixed="true" />
	<breadcrumb-content menu="Review Kurikulum" subMenu="Fakultas" />

	<div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
		<div class="card-body">
			<div class="row">
				<div class="col-md-6 col-12">
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Rumpun Ilmu</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.ri_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Fakultas</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.fakultas_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Departemen</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.departemen_nama_ind" readonly>
					</div>
				</div>
				</div>
				<div class="col-md-6 col-12">
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Program Studi</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.prodi_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Jenjang</label>
					<div class="col-sm-9">
						<input type="text" class="form-control form-control-solid-bg form-control-sm" :value="dataKurikulum.jenjang_nama_ind" readonly>
					</div>
				</div>
				<div class="row mb-1">
					<label class="col-form-label col-sm-3">Kurikulum</label>
					<div class="col-sm-9">
						<textarea type="text" class="form-control form-control-solid-bg form-control-sm" :value="`${dataKurikulum.kurikulum_kd}\n${dataKurikulum.nama_ind}`" readonly></textarea>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>

	<div class="card mb-5 mb-xxl-8 border-top border-primary border-2">
		<Form id="form-review3" class="kt-form" method="post" @submit="onSubmit" ref="formRef">
			<div class="card-header" style="min-height: 55px;">
				<span class="card-title fw-bold fs-3">
					Form Review Fakultas
				</span>
			</div>
			<div class="card-body">
				<div class="row mb-5">
					<label class="col-form-label col-2">Tanggal</label>
					<div class="col-form-label col-1 text-end">:</div>
					<div class="col-6">
						<input type="text" class="form-control bg bg-light-dark" :value="tgl_review" readonly>
					</div>
				</div>
				<div class="row mb-5 offset-3">
					<div class="col-form-label col-md-2 col-4">
						<label class="form-check-label" for="p-true" style="cursor: pointer;">
							<div class="form-check">
								<input class="form-check-input" type="radio" name="is_disetujui" v-model="isDisetujui" :value="true" id="p-true" style="cursor: pointer;">
								Disetujui
							</div>
						</label>
					</div>
					<div class="col-form-label col-md-3 col-5">
						<label class="form-check-label" for="p-false" style="cursor: pointer;">
							<div class="form-check">
								<input class="form-check-input" type="radio" name="is_disetujui" v-model="isDisetujui" :value="false" id="p-false" style="cursor: pointer;">
								Tidak Disetujui
							</div>
						</label>
					</div>
				</div>
				<div class="row mb-5">
					<label class="col-form-label col-2">Catatan</label>
					<div class="col-form-label col-1 text-end">:</div>
					<div class="col-9">
						<Field as="textarea" rows="3" name="catatan" type="text" class="form-control form-control-solid-bg" placeholder="Masukkan catatan" v-model="catatanReview"/>
					</div>
				</div>
				<div class="row mb-5">
					<label class="col-form-label col-2">Upload Dokumen</label>
					<div class="col-form-label col-1 text-end">:</div>
					<div class="col-9">
						<Field name="doc_upload" type="file" class="form-control bg bg-light-dark" accept="image/*,.doc,.docx,.pdf"  @change="handleUploadFile"/>
						<div v-if="errorValidate.file" id="doc_upload-error" class="invalid-feedback" style="display:block;">{{ errorValidate.file }}</div>
						<div v-if="errorValidate.type" id="errorValidate-error" class="invalid-feedback" style="display:block;">{{ errorValidate.type }}</div>
						<div v-if="errorValidate.size" id="errorValidate-error" class="invalid-feedback" style="display:block;">{{ errorValidate.size }}</div>
					</div>

					<div class="offset-3" v-if="dataKurikulum.review3?.doc_upload">
						<a :href="dataKurikulum.review3?.doc_upload" target="_blank">Lihat Dokumen Sebelumnya</a>
					</div>

					<div class="offset-3 col-md-4 col-sm-4 mt-3" v-if="uploadFile.preview">
						<div style="border:1px solid #c1c1c1; border-radius:5px; padding:1px; width:180px; height:auto;">
							<img :src="uploadFile.preview" width="100%" height="auto" style="border:1px solid #c1c1c1; border-radius:5px;" />
						</div>
					</div>
				</div>
			</div>
			<div class="card-footer text-end" style="padding: 1rem 2.25rem;">
				<button @click="redirectToIndex" class="border border-dark border-1 btn btn-light-dark btn-sm">Kembali</button>
				&nbsp;
				<button type="submit" class="border border-primary border-1 btn btn-light-primary btn-sm">
					<span class="indicator-label"> Simpan Form & Kirim Ke Halaman DPASDP <i class="fa fa-angle-double-right"></i></span>
					<span class="indicator-progress">Mohon Tunggu... <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
				</button>
			</div>
		</Form>
	</div>
</template>

<script setup>
import swal from "sweetalert2";

import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import LoadingOverlay from '@/components/LoadingOverlay'
import { sendRequest } from '@/api/common'
import request from '@/utils/request'
import { Form, Field } from 'vee-validate'

const formRef = ref(null)
const loading = ref(true)
const router = useRouter()
const kurikulum_id = router.currentRoute.value.params.id

const dataKurikulum = ref({})
const isDisetujui = ref(null); 
const redirectToIndex = () => router.push({ name: 'RFakultas' })

const uploadFile = ref({ file: null, preview: null });
const errorValidate = ref({ attachment: null, type: null, size: null });
const handleUploadFile = ((event) => {
	uploadFile.value.file = event.target.files[0];
	
	// Check if file is uploaded
	if (!uploadFile.value.file) { errorValidate.value.attachment = 'Harus mengupload dokumen'; return; }
	// Clear file error if valid
	errorValidate.value.attachment = null;

	uploadFile.value.preview = null;
	if (uploadFile.value.file) {
		const allowedTypes = [
			'image/jpeg', 'image/jpg', 'image/png',   									// Image types
			'application/pdf',                       									// PDF type
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 	// DOCX
			'application/msword',                    									// DOC
			// 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 		// XLSX
			// 'application/vnd.ms-excel'               									// XLS
		];
		const maxImageSize = 5 * 1024 * 1024; // 5MB
		const maxDocumentSize = 10 * 1024 * 1024; // 10MB

		// Check file type
		if (!allowedTypes.includes(uploadFile.value.file.type)) { errorValidate.value.type = 'File harus berformat jpeg, jpg, png, pdf, doc, atau docx'; return; }
		// Clear type error if valid
		errorValidate.value.type = null;

		// Generate file preview if it's an image
		if (uploadFile.value.file.type.startsWith('image/')) {
			uploadFile.value.preview = URL.createObjectURL(uploadFile.value.file);

			// Check file size (limit: 10MB)
			if (uploadFile.value.file && uploadFile.value.file.size > maxImageSize) { errorValidate.value.size = 'Ukuran file harus di bawah 5MB'; return; }
		} else {
			// Check file size (limit: 10MB)
			if (uploadFile.value.file && uploadFile.value.file.size > maxDocumentSize) { errorValidate.value.size = 'Ukuran file harus di bawah 10MB'; return; }
		}
		// Clear size error if valid
		errorValidate.value.size = null;
	} else {
		// Reset errors if no file is selected
		errorValidate.value = { attachment: null, type: null, size: null };
	}
});

const onSubmit = async values => {
	if (isDisetujui.value == undefined) { swal.fire('Gagal menyimpan form!', `Persetujuan form belum dipilih.`, 'error'); return; }
	// if (!uploadFile.value.file) { swal.fire('Gagal menyimpan form!', `Anda harus mengupload dokumen terlebih dahulu.`, 'error'); return; }
	try {
		loading.value = true

		const result = await swal.fire({
			title: 'Apakah Anda yakin?',
			text: 'Anda akan menyimpan data Form Review dan meneruskan untuk direview oleh DPASDP.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Ya, saya yakin',
			cancelButtonText: 'Tidak, batalkan',
			allowOutsideClick: false
		})
		
		if (result.isConfirmed) {
			const formData = new FormData();
			const currentDate = new Date();

			formData.append('review2_id_fk', parseInt(dataKurikulum.value.review2.review2_id));
			formData.append('organisasi', 'FAKULTAS');
			formData.append('catatan', values.catatan ?? null);
			formData.append('doc_upload', uploadFile.value.file);
			formData.append('tgl_review', currentDate.toISOString());
			formData.append('is_disetujui', isDisetujui.value); 

			request.defaults.headers.post["Content-Type"] = "multipart/form-data";
			const param = {
				url: `${dataKurikulum.value.review3 ? '/review-3/'+dataKurikulum.value.review3.review3_id : '/review-3'}`,
				method: `${dataKurikulum.value.review3 ? 'PUT' : 'POST'}`,
				body: formData,
				loading: loading,
				context: 'RFakultas',
			}
		
			return await sendRequest(param)
		}	
	} 
	catch (error) { swal.fire({ title: `Form Gagal Disimpan`, text: error.response.data.message, icon: 'error', confirmButtonText: 'OK' }); } 
	finally { loading.value = false; }
}

const catatanReview = computed({
    get() { return dataKurikulum.value.review3?.catatan || ''; },
    set(value) { if (dataKurikulum.value.review3) { dataKurikulum.value.review3.catatan = value; } },
});

const tgl_review = ref('');
onMounted(async () => {
	fetchData();

	const currentDate = new Date();
	const day = String(currentDate.getDate()).padStart(2, '0');
	const month = String(currentDate.getMonth() + 1).padStart(2, '0');
	const year = currentDate.getFullYear();
	tgl_review.value = `${day}-${month}-${year}`;
})

const fetchData = async () => {
	loading.value = true

	try {				
		dataKurikulum.value = (await request(`/kurikulum/${kurikulum_id}?loads=review2,review3`)).data; 
		isDisetujui.value = dataKurikulum.value.review3?.is_disetujui
	} 
	catch (error) { console.error('Error fetching data:', error) } 
	finally { loading.value = false }
}
</script>